import { Component } from "react";
import AuthController from "../../controllers/AuthController";
import DataController from "../../controllers/DataController";

import {
    IonButton,
    isPlatform, withIonLifeCycle
  } from '@ionic/react';

import { Plugins } from '@capacitor/core';
import { withRouter } from "react-router";

import { format } from "date-fns";

const { Device } = Plugins;

type Props = {
    products: any
}

type State = {


}

export class Contact extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    getReceipt() {



    }

    render() {

        return (
            <div></div>
        )

    }

}

export default withRouter(withIonLifeCycle(Contact));