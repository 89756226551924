import { Component } from "react";
import './subscriptions.css';
import { IonBackdrop, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonModal, IonPage, IonRippleEffect, IonSpinner, IonTitle, IonToast, IonToolbar, isPlatform, withIonLifeCycle } from '@ionic/react';
import { withRouter } from "react-router";

import AuthController from "../../controllers/AuthController";
import DataController from "../../controllers/DataController";

import upChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Up.svg'
import leftChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Back.svg'
import downChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Down.svg'

import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/';
import { Plugins } from '@capacitor/core';
import { differenceInDays, format, isBefore } from "date-fns";

import hymnsLogo from '../../theme/assets/Logo_HymnsAM@3x.png';


const store = InAppPurchase2;
const { Device } = Plugins;

type Props = {
    airTableData: any,
    setTabs: any,
    history: any,
    setLoginStatus: any,
    setValidSubscriptionStatus: any,
    validSubscriptionStatus: any,
    latestSubscription: any,
    validateUserAccess: any,
    validateSubscriptionFromServer: any,
    products: any,
    sendSupportEmail: any,
    setHasCheckedServerAlready: any,
    closeSubscriptionModal: any,
    signInDropdownIsOpen: any,
    subscriptionOptionsIsOpen: any,
    subscriptionInfoIsOpen: any,
    isEligbleForFreeTrial: any,
    setTrialEligibility: any
}

type State = {
    email: string,
    password: string,
    isLoggedIn: boolean,
    userDetails: any,
    signInBtnEnabled: boolean,
    showToast: boolean,
    toastColor: string,
    toastMessage: string,
    showWarningMessage: boolean,
    warningMessage: string,
    platform: any,
    subscription: any,
    subscriptionMessage: any,
    signInDropdownIsOpen: any,
    subscriptionOptionsIsOpen: any,
    subscriptionInfoIsOpen: any,
    restoringExistingPurchases: any,
    showManageAccountModal: any

}

class Subscriptionsv2 extends Component<Props, State> {

    oneMonthIsBeingPurchased = false;
    twelveMonthIsBeingPurchased = false;
    isLoggedIn = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isLoggedIn: false,
            userDetails: null,
            signInBtnEnabled: false,
            showToast: false,
            toastColor: '',
            toastMessage: '',
            showWarningMessage: false,
            warningMessage: '',
            platform: '',
            subscription: null,
            subscriptionMessage: '',
            signInDropdownIsOpen: isPlatform('desktop') ? true : false,
            subscriptionOptionsIsOpen: true,
            subscriptionInfoIsOpen: true,
            restoringExistingPurchases: false,
            showManageAccountModal: false
        }

    }

    async componentDidMount() {

        // console.log('Setting tabs in subscriptions...');
        // this.props.setTabs('home', true);

        await Device.getInfo().then(async (info: any) => {

            this.setState({
                platform: info.platform
            })

        });

        // Check login and trial status at the same time to prevent having more setStates than needed
        this.isLoggedIn = await AuthController.isLoggedIn();
        if (this.isLoggedIn) {

            const userDetails = await AuthController.getUser();
            this.setState({
                userDetails: userDetails,
                isLoggedIn: true,
                signInDropdownIsOpen: this.props.signInDropdownIsOpen,
                subscriptionOptionsIsOpen: this.props.subscriptionOptionsIsOpen,
                subscriptionInfoIsOpen: this.props.subscriptionInfoIsOpen
            });

            // Validate subscription from server to ensure we're working with the latest ending subscription
            await this.props.validateSubscriptionFromServer();

        } else {
            this.setState({
                userDetails: null,
                isLoggedIn: false,
                signInDropdownIsOpen: this.props.signInDropdownIsOpen,
                subscriptionOptionsIsOpen: this.props.subscriptionOptionsIsOpen,
                subscriptionInfoIsOpen: this.props.subscriptionInfoIsOpen
            })
        }

        if (this.props.latestSubscription) {

            if (!this.props.validSubscriptionStatus) {

                const expiryMessage = this.getExpiryMessage();

                // Simply just output the date it expired, no need to worry about how long ago it was in days or hours/minutes
                this.setState({
                    subscriptionMessage: expiryMessage
                })
            }
        }

    }

    getExpiryMessage() {
        const expiryDate: any = new Date(this.props.latestSubscription['expiryDate']);
        return `Your subscription expired on the ${format(expiryDate, 'do MMMM yyyy')}. Please resubscribe to access the app.`
    }

    ionViewDidEnter() {

        this.setState({
            signInBtnEnabled: this.isValidated()
        });

    }

    isValidated() {

        if (this.state.email.length > 0 && this.state.password.length > 0) {
            return true;
        } else {
            return false;
        }

    }

    emailChanged(email: string) {

        let signInBtnEnabled = false;

        if (email.length > 0 && this.state.password.length > 0) {
            signInBtnEnabled = true;
        } else {
        }

        this.setState({
            email: email,
            signInBtnEnabled: signInBtnEnabled
        })

    }

    passwordChanged(password: string) {

        let signInBtnEnabled = false;

        if (this.state.email.length > 0 && password.length > 0) {
            signInBtnEnabled = true;
        } else {

        }

        this.setState({
            password: password,
            signInBtnEnabled: signInBtnEnabled
        })

    }

    async onSubmit() {

        if (this.state.signInBtnEnabled) {

            const isAuthenticated = await AuthController.login(this.state.email, this.state.password);
            if (isAuthenticated) {

                const user = await DataController.getValue('user');
                // console.log('user:', user);

                await this.props.validateSubscriptionFromServer();
                await this.props.setLoginStatus(true);

                // Ensure the trial eligibility state is up-to-date
                await this.props.setTrialEligibility();

                // If the user is eligible for a free trial and does NOT currently have an active subscription
                if (this.props.isEligbleForFreeTrial && !this.props.validSubscriptionStatus) {

                    await DataController.startTrial('hymns');
                    await this.props.setTrialEligibility();

                    this.setState({
                        isLoggedIn: true,
                        userDetails: user,
                        subscriptionOptionsIsOpen: true,
                        subscriptionInfoIsOpen: true
                    })

                    await this.props.validateUserAccess();

                } else {

                    // If user has a valid subscription
                    if (this.props.validSubscriptionStatus) {

                        this.setState({
                            isLoggedIn: true,
                            userDetails: user,
                            subscriptionOptionsIsOpen: true,
                            subscriptionInfoIsOpen: true
                        })

                    } else {

                        const expiryMessage = this.getExpiryMessage();

                        this.setState({
                            subscriptionMessage: expiryMessage,
                            isLoggedIn: true,
                            userDetails: user,
                            subscriptionOptionsIsOpen: true,
                            subscriptionInfoIsOpen: true

                        })

                    }

                }

            } else {
                this.setState({ showToast: true, toastColor: 'danger', toastMessage: 'Incorrect username or password, please try again', password: '' });
            }

        }

    }

    async logout() {

        // Calling setLoginStatus(false) will wipe the subscription from storage if it is directly from the server
        // (we don't want users to keep the subscription once they're logged out), therefore we have to save whether or not
        // the subscription is directly from the server before calling setLoginStatus(false) otherwise we wouldn't be able to know.
        const subscriptionIsDirectlyFromServer = await AuthController.subscriptionIsDirectlyFromServer();
        await this.props.setLoginStatus(false);
        await this.props.setTrialEligibility();

        // await AuthController.logout();
        // await this.props.validateUserAccess();

        // If the subscription is directly from the server, refresh the store so
        // the app will fallback on any iOS/Android subscriptions which may be valid
        if (subscriptionIsDirectlyFromServer) {

            this.props.setHasCheckedServerAlready(false);

            try {
                store.refresh().completed(() => {

                    // the .completed method doesn't seem to actually fire upon completion of the validation process, therefore
                    setTimeout(async () => {

                        if (this.props.validSubscriptionStatus) {
                            this.setState({
                                showToast: true,
                                toastColor: 'success',
                                toastMessage: `Restored previously purchased subscription`
                            });
                        }

                        // In the validateWithApple and validateWithGoogle methods in App.tsx, once the receipt has been sent to the server,
                        // it'll set a 'checked' value to true to prevent the receipt getting sent for every transaction. However, once the refresh
                        // process is completed, we need to set this 'checked' value back to false again so the next time the validate methods are called, they'll know
                        // that the server hasn't been checked. We should already accomplish this in the 'setHasCheckedServerAlready' call above, but this is just to make
                        // sure. 
                        this.props.setHasCheckedServerAlready(false);
                    }, 2000)
                })
            } catch (error) {
                console.log('store.refresh() not run');
            }

        } else {
        }

        this.setState({
            showManageAccountModal: !this.state.showManageAccountModal,
            email: '',
            password: '',
            isLoggedIn: false
        })

        this.forceUpdate();

    }

    subscribe(productKey: any) {

        try {
            this.props.setHasCheckedServerAlready(false); // MAKE SURE TO ALWAYS SET TO FALSE
            store.order(this.props.products[productKey]);
        } catch (error: any) {
            console.log('store.order error', error.message);
        }

    }

    restorePurchases() {

        this.setState({ restoringExistingPurchases: true });

        this.props.setHasCheckedServerAlready(false);

        // console.log('Restore purchases starting...')
        store.refresh().completed(() => {

            setTimeout(() => {

                if (this.props.validSubscriptionStatus) {

                    this.setState({
                        restoringExistingPurchases: false,
                        showToast: true,
                        toastColor: 'success',
                        toastMessage: 'Restore was successful, you have an active subscription'
                    });

                } else {

                    this.setState({
                        restoringExistingPurchases: false,
                        showToast: true,
                        toastColor: 'danger',
                        toastMessage: `Restore was unsuccessful, either a previous subscription doesn't exist, or it has expired`
                    });

                }

                this.props.setHasCheckedServerAlready(false);

            }, 2000);


        });
    }

    managePurchases() {
        store.manageSubscriptions();
    }

    render() {

        let isTrial = false;
        let daysUntilSubscriptionExpires = -1;
        let subscriptionLengthText = 'Awaiting subscription info';

        if (this.props.latestSubscription != null) {
            isTrial = this.props.latestSubscription['isTrial'];
            daysUntilSubscriptionExpires = differenceInDays(new Date(this.props.latestSubscription['expiryDate']), new Date()) + 1;

            if (this.props.latestSubscription['productId'] != null) {

                const productId = this.props.latestSubscription['productId'];

                switch (true) {

                    case productId.includes('year'):
                        subscriptionLengthText = '12 month subscription'
                        break;

                    case productId.includes('month'):
                        subscriptionLengthText = '1 month subscription'
                        break;

                    default:
                        subscriptionLengthText = 'HymnsAM Subscription'
                        break;
                }

            }

        }

        switch (true) {

            case this.state.platform == 'ios':

                if (this.props.products['oneMonthIOSProduct'] != null) {
                    this.oneMonthIsBeingPurchased = (this.props.products['oneMonthIOSProduct'].state === 'requested' || this.props.products['oneMonthIOSProduct'].state === 'initiated');
                }

                if (this.props.products['twelveMonthIOSProduct'] != null) {
                    this.twelveMonthIsBeingPurchased = (this.props.products['twelveMonthIOSProduct'].state === 'requested' || this.props.products['twelveMonthIOSProduct'].state === 'initiated');
                }

                break;

            case this.state.platform == 'android':

                if (this.props.products['oneMonthAndroidProduct'] != null) {
                    this.oneMonthIsBeingPurchased = (this.props.products['oneMonthAndroidProduct'].state === 'requested' || this.props.products['oneMonthAndroidProduct'].state === 'initiated');
                }

                if (this.props.products['oneMonthAndroidProduct'] != null) {
                    this.twelveMonthIsBeingPurchased = (this.props.products['twelveMonthAndroidProduct'].state === 'requested' || this.props.products['twelveMonthAndroidProduct'].state === 'initiated');
                }

                break;

        }

        let expiryDatePlusOneDay = new Date();
        if (this.props.latestSubscription != null) {
            expiryDatePlusOneDay = new Date(this.props.latestSubscription['expiryDate'])
            expiryDatePlusOneDay.setDate(expiryDatePlusOneDay.getDate() + 1);
        }

        console.log('this.props.validSubscriptionStatus', this.props.validSubscriptionStatus);


        return (
            // <IonPage>
            <div>

                {/* If user is accessing the site from a desktop & doesn't have an valid subscription, 
                  * assign the 'web-show-header' found in App.css which determines whether or not the screen width 
                  * is should show the default header (which is simply just a red BG). 
                  * 
                  * If the user does have a valid subscription, then the header should always be shown to allow them access to the 'close' button
                  * */}

                <IonHeader className={isPlatform('desktop') && !this.props.validSubscriptionStatus ? 'web-show-header' : ''}>
                    <IonToolbar>
                        {this.props.validSubscriptionStatus == true || isTrial == true || (this.props.isEligbleForFreeTrial == true) && !(!this.isLoggedIn && isPlatform('desktop')) ?
                            <IonButtons slot="end" onClick={async () => await this.props.closeSubscriptionModal()}>
                                <IonButton>
                                    <div className="backButtonTxt">
                                        Close
                                    </div>
                                </IonButton>
                            </IonButtons>
                            : ''}
                    </IonToolbar>
                </IonHeader>

                <div className="contentContainer">
                    <div className="content content-border">

                            {/* If the user is using a desktop and doesn't have a valid subscription, the 'web-graphic' class can either be display: none, or display: block
                            * depending on whether or not the user's screen width is wide enough - this is set in App.css. If it is then the web graphic will be shown.
                            * 
                            * Doing it this way prevents the user from potentially reducing the width of their browser and the image becoming too squashed.
                            */}
                            {isPlatform('desktop') && !this.props.validSubscriptionStatus ?
                                <div style={{ position: 'relative' }}>
                                    <img className='web-graphic' src='images/Feature-Graphic-All.jpg' />
                                    <img
                                        style={{
                                            position: 'absolute',
                                            display: 'block',
                                            bottom: 17,
                                            marginLeft: '6%',
                                            height: '10%',
                                            width: '15%'
                                        }}
                                        src='images/Web_Button_AppStore@3x.png'
                                    />
                                    <img
                                        style={{
                                            position: 'absolute',
                                            display: 'block',
                                            bottom: 17,
                                            marginLeft: '22%',
                                            height: '10%',
                                            width: '15%'
                                        }}
                                        src='images/Web_Button_Google@3x.png'
                                    />
                                </div>
                                : ''}

                            {this.state.isLoggedIn ?
                                <div className="subscriptionsContainer">
                                    <div className="subscriptionsTitleContainer">
                                        <div className="subscriptionsTitle">
                                            You are currently logged in as {this.state.userDetails?.email}
                                        </div>
                                    </div>
                                    {/* <IonButton className="subscriptionClearBtn" expand="block" fill='outline' onClick={() => this.setState({showManageAccountModal: true})}>
                                        Manage account
                                    </IonButton> */}
                                    <IonButton className="subscriptionClearBtn" expand="block" fill='outline' onClick={async () => { await this.logout(); this.setState({ signInDropdownIsOpen: true }) }}>
                                        Log out
                                    </IonButton>
                                </div>
                                : <div className="subscriptionsContainer">
                                    <div className="subscriptionsTitleContainer" onClick={() => this.setState({ signInDropdownIsOpen: !this.state.signInDropdownIsOpen })}>
                                        <div className="subscriptionsTitle">
                                            Sign into your Hymns Ancient & Modern account
                                        </div>
                                        <div className="subscriptionsChevron">
                                            {this.state.signInDropdownIsOpen ?
                                                <IonIcon icon={upChevronIcon} />
                                                : <IonIcon icon={downChevronIcon} />
                                            }
                                        </div>
                                    </div>

                                    <div className="seperator" style={{ width: '159px' }} />

                                    {this.state.signInDropdownIsOpen ?
                                        <div>

                                            <div className="subscriptionsSubTitle">
                                                If you already have a hymnsam.co.uk account, log in here to access the app.
                                            </div>

                                            <div className="loginContainer">
                                                <div className="loginTitle">
                                                    Email
                                                </div>
                                                <IonInput
                                                    className="loginInput"
                                                    onIonChange={(e) => this.emailChanged(e.detail.value!)}
                                                />
                                                <div className="loginTitle" style={{ paddingTop: '8px' }}>
                                                    Password
                                                </div>
                                                <IonInput
                                                    className="loginInput"
                                                    type="password"
                                                    value={this.state.password}
                                                    onIonChange={(e) => this.passwordChanged(e.detail.value!)}
                                                />
                                            </div>

                                            <div className="signInContainer">
                                                <IonButton className='subscriptionBlueBtn' style={{ height: '44px', opacity: this.state.signInBtnEnabled ? '1' : '0.4' }} onClick={async () => await this.onSubmit()}>
                                                    Sign In
                                                </IonButton>
                                            </div>

                                            {this.state.showWarningMessage ?
                                                <div className="warningMessage">
                                                    {this.state.warningMessage}
                                                </div>
                                                : ''}
                                        </div>
                                        : ''}
                                </div>}
                    </div>
                </div>

                <div className="contentContainer">
                    <div className="content content-border">
                        <div className="subscriptionsContainer">
                            <div className="subscriptionsTitleContainer" onClick={() => this.setState({ subscriptionOptionsIsOpen: !this.state.subscriptionOptionsIsOpen })}>

                                <div className="subscriptionsTitle">
                                    Subscription Options
                                    </div>

                                <div className="subscriptionsChevron">
                                    <IonIcon icon={this.state.subscriptionOptionsIsOpen ? upChevronIcon : downChevronIcon}></IonIcon>
                                </div>

                            </div>

                            <div className="seperator" style={{ width: '159px', marginBottom: '15px' }}></div>

                            {/* If user has an expired subscription */}
                            {(this.state.subscriptionMessage != null && this.state.subscriptionMessage != '')
                                && this.state.subscriptionOptionsIsOpen
                                && !this.props.validSubscriptionStatus ?
                                <div className="subscriptionsSubTitle2" style={{ paddingBottom: '16px' }}>
                                    {this.state.subscriptionMessage}
                                </div>
                                : ''}

                            {/* If user has a valid subscription which is a trial */}
                            {this.props.latestSubscription
                                && this.props.validSubscriptionStatus
                                && this.state.subscriptionOptionsIsOpen
                                && this.props.latestSubscription != null
                                && this.props.latestSubscription['isTrial'] != null
                                && (this.props.latestSubscription['isTrial'] == true || this.props.latestSubscription['isTrial'] == 'true') ?
                                <div className='subscriptionsSubTitle2' style={{ marginBottom: '12px' }}>
                                    You are currently using a free trial version of the app which expires in less than {daysUntilSubscriptionExpires} {daysUntilSubscriptionExpires == 1 ? 'day' : 'days'}, on {format(new Date(this.props.latestSubscription['expiryDate']), 'MMM d, yyyy')} If you would like to access Hymns after this date, please select a subscription option below.
                                        {isPlatform('desktop') ?
                                        <div>
                                            <div style={{ paddingTop: '16px' }}>
                                                To purchase a subscription go to <a className="subscriptionInfoTextHighlight" target='blank' href='https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern'>https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern</a>. Alternatively, you can download
                                                    the app on an iOS or Android device below, and purchase an app subscription there.
                                                </div>
                                            <div style={{ paddingTop: '16px' }}>
                                                <img
                                                    style={{
                                                        marginRight: '20px'
                                                    }}
                                                    width='175'
                                                    height='55'
                                                    src='images/Web_Button_AppStore@3x.png'
                                                />
                                                <img
                                                    width='180'
                                                    height='55'
                                                    src='images/Web_Button_Google@3x.png'
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                : ''}

                            {/* If user has a valid subscription which is not a trial */}
                            {(this.props.latestSubscription != false && this.props.latestSubscription != 'false')
                                && this.props.validSubscriptionStatus
                                && this.state.subscriptionOptionsIsOpen
                                && this.props.latestSubscription != null
                                && this.props.latestSubscription['isTrial'] != null
                                && (this.props.latestSubscription['isTrial'] == false || this.props.latestSubscription['isTrial'] == 'false' || this.props.latestSubscription['isTrial'] == null || this.props.latestSubscription['isTrial'] == 'undefined') ?
                                <div>
                                    <div className='subscriptionsSubTitle4' style={{ paddingBottom: '4px' }}>
                                        Current subscription
                                        </div>
                                    <div className="subscriptionsSubTitle5" style={{ paddingBottom: '15px' }}>
                                        {subscriptionLengthText}
                                    </div>
                                    <div className='subscriptionsSubTitle4' style={{ paddingBottom: '4px' }}>
                                        Expiry date
                                        </div>
                                    <div className="subscriptionsSubTitle5" style={{ paddingBottom: '38px' }}>
                                        {format(expiryDatePlusOneDay, 'MMM d, yyyy')}
                                    </div>

                                    {this.state.platform != 'web' ?
                                        <IonButton className="subscriptionClearBtn" expand="block" fill='outline' onClick={() => this.managePurchases()}>
                                            Manage my subscription
                                            </IonButton>
                                        : ''
                                    }
                                </div>
                                : ''
                            }

                            {/* iOS i.e. if platform is ios and the user doesn't have a valid subscription or is using a free trial, show the purchase buttons*/}
                            {this.state.platform == 'ios' && this.state.subscriptionOptionsIsOpen
                                && ((this.props.validSubscriptionStatus == false
                                    && this.state.restoringExistingPurchases == false)
                                    || (this.props.latestSubscription && (this.props.latestSubscription['isTrial'] == true || this.props.latestSubscription['isTrial'] == 'true'))) ?
                                <div>

                                    <IonButton disabled={this.twelveMonthIsBeingPurchased} className="subscriptionBlueBtn" style={{ height: '50px' }} expand="block" onClick={() => {
                                        this.subscribe('twelveMonthIOSProduct');
                                    }}>
                                        <div className="subscriptionBtnText">
                                            12 Month / {this.props.products['twelveMonthIOSProduct'] != null ? this.props.products['twelveMonthIOSProduct'].price : <IonSpinner className="subscriptionButtonSpinner" />}
                                        </div>
                                        {this.twelveMonthIsBeingPurchased ? (
                                            <IonSpinner className="subscriptionButtonSpinner" />
                                        ) : null}
                                    </IonButton>

                                    <IonButton disabled={this.oneMonthIsBeingPurchased} className="subscriptionBlueBtn" style={{ height: '50px', marginTop: '12px' }} expand="block" onClick={() => {
                                        this.subscribe('oneMonthIOSProduct')
                                    }}>
                                        <div className="subscriptionBtnText">
                                            1 Month / {this.props.products['oneMonthIOSProduct'] != null ? this.props.products['oneMonthIOSProduct'].price : <IonSpinner className="subscriptionButtonSpinner" />}
                                        </div>
                                        {this.oneMonthIsBeingPurchased ? (
                                            <IonSpinner className="subscriptionButtonSpinner" />
                                        ) : null}
                                    </IonButton>
                                </div>
                                : ''}

                            {/* Android */}
                            {this.state.platform == 'android' && this.state.subscriptionOptionsIsOpen
                                && ((this.props.validSubscriptionStatus == false
                                    && this.state.restoringExistingPurchases == false)
                                    || (this.props.latestSubscription && (this.props.latestSubscription['isTrial'] == true || this.props.latestSubscription['isTrial'] == 'true'))) ?
                                <div>

                                    <IonButton disabled={this.twelveMonthIsBeingPurchased} className="subscriptionBlueBtn" style={{ height: '50px' }} expand="block" onClick={() => {
                                        this.subscribe('twelveMonthAndroidProduct');
                                    }}>
                                        <div className="subscriptionBtnText">
                                            12 Month / {this.props.products['twelveMonthAndroidProduct'] != null ? this.props.products['twelveMonthAndroidProduct'].price : <IonSpinner className="subscriptionButtonSpinner" />}
                                        </div>
                                        {this.twelveMonthIsBeingPurchased ? (
                                            <IonSpinner className="subscriptionButtonSpinner" />
                                        ) : null}
                                    </IonButton>

                                    <IonButton disabled={this.oneMonthIsBeingPurchased} className="subscriptionBlueBtn" style={{ height: '50px', marginTop: '12px' }} expand="block" onClick={() => {
                                        this.subscribe('oneMonthAndroidProduct')
                                    }}>
                                        <div className="subscriptionBtnText">
                                            1 Month / {this.props.products['oneMonthAndroidProduct'] != null ? this.props.products['oneMonthAndroidProduct'].price : <IonSpinner className="subscriptionButtonSpinner" />}
                                        </div>
                                        {this.oneMonthIsBeingPurchased ? (
                                            <IonSpinner className="subscriptionButtonSpinner" />
                                        ) : null}
                                    </IonButton>

                                </div>
                                : ''}

                            {/* Only show on web devices when the user doesn't have a valid subscription*/}
                            {this.state.platform == 'web' && this.props.validSubscriptionStatus == false && this.state.subscriptionOptionsIsOpen ?
                                <div className="subscriptionsSubTitle2" style={{ paddingBottom: '14px' }}>
                                    To purchase a subscription go to <a className="subscriptionInfoTextHighlight" target='blank' href='https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern'>https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern</a>. Alternatively, you can download
                                        the app on an iOS or Android device below, and purchase an app subscription there.
                                        <div style={{ paddingTop: '16px' }}>
                                        <img
                                            style={{
                                                marginRight: '20px'
                                            }}
                                            width='175'
                                            height='55'
                                            src='images/Web_Button_AppStore@3x.png'
                                        />
                                        <img
                                            width='180'
                                            height='55'
                                            src='images/Web_Button_Google@3x.png'
                                        />
                                    </div>
                                </div>
                                : ''
                            }

                            {/* Only show on mobile devices */}
                            {this.state.platform != 'web' && this.state.subscriptionOptionsIsOpen ?
                                <IonButton
                                    disabled={this.state.restoringExistingPurchases}
                                    className="subscriptionRestoreBtn btn-no-border"
                                    color='medium'
                                    expand="block" fill='solid'
                                    style={{ marginTop: '12px' }}
                                    onClick={() => this.restorePurchases()}
                                >
                                    Restore existing purchase
                                        {this.state.restoringExistingPurchases ? (
                                        <IonSpinner className="subscriptionButtonSpinner" style={{ '--color': 'var(--cta-1-50)' }} />
                                    ) : null}
                                </IonButton>
                                : ''}

                            {this.state.subscriptionOptionsIsOpen ?
                                <div>
                                    <div className="subscriptionsSubTitle2" style={{ paddingBottom: '12px' }}>
                                        Got a question or need help regarding subscriptions?
                                        </div>

                                    <IonButton className="subscriptionClearBtn" expand="block" fill='outline' onClick={() => this.props.sendSupportEmail('Subscription Support')}>
                                        Contact support
                                        </IonButton>
                                </div>
                                : ''
                            }

                        </div>
                    </div>
                </div>

                <div className="contentContainer" style={{ paddingBottom: '16px', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                    <div className="content content-border">
                        <div className="subscriptionsContainer">
                            <div className="subscriptionsTitleContainer" onClick={() => { this.setState({ subscriptionInfoIsOpen: !this.state.subscriptionInfoIsOpen }) }}>

                                <div className="subscriptionsTitle">
                                    Subscription Information
                                    </div>

                                <div className="subscriptionsChevron">
                                    <IonIcon icon={this.state.subscriptionInfoIsOpen ? upChevronIcon : downChevronIcon}></IonIcon>
                                </div>

                            </div>

                            <div className="seperator" style={{ width: '159px', marginBottom: '16px' }}></div>

                            <div className="subscriptionInfoText">
                                Subscribing to Hymns Ancient & Modern app gives you ongoing access to all the Hymns. This includes all hymns that get added to the app over time, all hymn information and all song information.
                            </div>

                            {/* Only show when subscription info is open and user is on a mobile device */}
                            {this.state.subscriptionInfoIsOpen && !isPlatform('desktop') ?

                                <ul style={{ paddingLeft: '18px' }}>
                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            In-app auto-renewing subscriptions: these are purchased using your {isPlatform('ios') ? 'iTunes/Apple' : 'Android'} Account and automatically renew.
                                            To cancel this type of subscription you need to visit your account on {isPlatform('ios') ? 'iTunes or the App' : ' the Google Play'} Store.
                                        </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            If you already have a web subscription you can use the account details here.
                                            </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Payment will be charged to the {isPlatform('ios') ? 'iTunes/Apple' : 'Android'} Account at confirmation of purchase.
                                            </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.
                                            </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Account will be charfed for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal.
                                            </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Subscriptions may be managed and auto-renewal may be turned off by going to the Account Settings in the {isPlatform('ios') ? 'iTunes/App Store' : 'Android/Play Store'} after purchase.
                                            </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Please read the <a className='subscriptionInfoTextHighlight' href='https://myaccount.hymnsam.co.uk/privacy-policy/'>Privacy Policy</a> and <a className='subscriptionInfoTextHighlight' href='https://myaccount.hymnsam.co.uk/terms-conditions'>Terms of Use</a>
                                        </div>
                                    </li>

                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.
                                            </div>
                                    </li>
                                </ul>
                                : ''
                            }

                            {this.state.subscriptionInfoIsOpen && isPlatform('desktop') ?
                                <ul>
                                    <li style={{ paddingLeft: '14px' }}>
                                        <div className="subscriptionInfoText">
                                            Please read the <a className='subscriptionInfoTextHighlight' target='blank' href='https://myaccount.hymnsam.co.uk/privacy-policy/'>Privacy Policy</a> and <a className='subscriptionInfoTextHighlight' target='blank' href='https://myaccount.hymnsam.co.uk/terms-conditions'>Terms of Use</a>
                                        </div>
                                    </li>
                                </ul>
                                : ''
                            }

                        </div>
                    </div>
                    <div className="logoContainer" style={{paddingTop: '16px', paddingBottom: '32px'}}>
                            <img src={hymnsLogo} width='126' height='49' />
                    </div>
                </div>

                <IonToast
                    color={this.state.toastColor}
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({ showToast: false })}
                    message={this.state.toastMessage}
                    duration={4000}
                />


                {/* </IonPage> */}
            </div>
        )

    }

}

export default withIonLifeCycle(Subscriptionsv2);