import {
    Plugins,
    FilesystemDirectory,
    FilesystemEncoding,
} from "@capacitor/core";
import mylists from "../pages/mylists/mylists";
import { AuthController } from "./AuthController";
import { addDays, format, isAfter, isBefore, parseISO } from 'date-fns';

const { Storage, Filesystem, Device } = Plugins;

export class DataController {

    async getListByCreationDate(creationDate: any) {

        const myLists = await this.getAllMyLists(false);
        let list = myLists.find((list: any) => list['creationDate'] == creationDate);

        if(list != null) {
            return list;
        } else {
            list = await this.createNewActiveList();
            return list
        }

    }

    async getAllMyLists(openFirstElement: boolean) {

        const myListsStr = await Storage.get({key: 'myLists'});
        const myLists = myListsStr.value != null ? JSON.parse(myListsStr.value) : []

        if(myLists.length > 0) {

            // Sort lists by creation date, then set the first element's isOpen to true so it's automatically open when the user accesses the dashboard
            myLists.sort((a: any, b: any) => (a['creationDate'] > b['creationDate']) ? 1 : ((b['creationDate'] > a['creationDate']) ? -1 : 0));

            if (openFirstElement === true){
                myLists[0]['isOpen'] = true;
            } else {
                myLists[0]['isOpen'] = false;
            }
            return myLists
        } else {
            return []
        }

    }

    async getActiveList() {

        const activeList = await Storage.get({key: 'activeList'});

        if (activeList.value != null) {
            return JSON.parse(activeList.value);
        } else {
            const newActiveList = await this.createNewActiveList();
            return newActiveList;
        }

    }

    async setActiveList(activeList: any) {

        await this.setValue('activeList', activeList);

    }

    async createNewActiveList() {

        const newActiveList = {
            name: '',
            date: '',
            hymnsAdded: []
        };

        await this.setValue('activeList', newActiveList);

        return newActiveList;

    }

    async startTrial(platform: 'ios' | 'android' | 'hymns') {

        let deviceInfo = await Device.getInfo();
        let uuid = deviceInfo.uuid;

        // Prepare POST body to send to server to validate receipt
        const body: any = { 
            UUID: uuid, 
            Product: 'com.hymnsam.hymnsancientmodern'
        };

        // Convert body to application/x-www-form-urlencoded format
        var formBody: any = [];
        for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        await fetch('https://cofe.aimernginx.co.uk/IAP/getFreeTrailData', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(async (response: any) => {

            // Check response returned ok
            if (response.ok) {

            // Unpack response from server
            await response.json().then(async (jsonResponse: any) => {
                
                const trialInfo = jsonResponse['params'];
                const trialExpiryDate = new Date(trialInfo['TrialEndDate']);
                const today = new Date();

                // console.log('Started trial:');
                // console.log('trialInfo:', trialInfo);

                // Make sure trial expiry date is after today, then write trial subscription to storage
                if(!isAfter(today, trialExpiryDate)) {

                    const trialSubscription = {
                        isDirectlyFromServer: false,
                        platform: platform,
                        startDate: new Date(),
                        expiryDate: trialExpiryDate,
                        transactionId: '0',
                        productId: 'com.hymnsam.hymnsancientmodern',
                        isTrial: true
                    }
                  
                    await this.overwriteSubscription(trialSubscription);                    

                } else {
                    console.log("Couldn't start trial as user has already had one on this device")
                }

            });

            } else {
            console.log('Response not ok when checking trial info: ', response.statusText);
            }

        }).catch((error: any) => {

            console.log('Fetch error when checking trial info - ', error.message);

        });

    }

    async overwriteSubscription(subscription: {
        isDirectlyFromServer: boolean,
        platform: 'ios' | 'android' | 'hymns' | 'unknown-hasFreeTrailData' | string
        startDate: Date,
        expiryDate: Date,
        transactionId: string,
        productId: string,
        isTrial: boolean | 'true' | 'false'
    }) {

        console.log('Subscription received in overwrite method:', subscription)
        await this.setValue('subscription', subscription);

    }

    async removeSubscriptionData() {
        this.removeValue('subscription');
    }

    async getSubscription() {

        const subscription = await this.getValue('subscription');
        return subscription != null ? subscription : false;

    }

    async printStorage() {
        console.log('window.localStorage', window.localStorage);
    }

    async latestSubscriptionIsInDate() {

        const latestSubscription = await this.getSubscription();

        // If latestSubscription exists and isn't null/false
        if(latestSubscription) {

            // Give the user 24h lee way
            const today: any = new Date();
            let expiryDatePlusOneDay = new Date (latestSubscription['expiryDate']);
            expiryDatePlusOneDay.setDate(expiryDatePlusOneDay.getDate() + 1);

            // i.e if expiryDate is greater than or equal to todaysDate
            if(!isBefore(expiryDatePlusOneDay,today)){

                console.log('Latest subscription is in date');
                console.log(`Expiry date: ${expiryDatePlusOneDay} is greater than or equal to todaysDate: ${today}`);
                return true;

            } else {
                console.log('Latest subscription in storage is out of date');
                console.log(`Expiry date: ${expiryDatePlusOneDay} is less than todaysDate: ${today}`);
                return false;
            }

        }

    }

    async isDateGreaterThanExpiryDateInStorage(passedInExpiryDate: Date){

        try {
            const storageSubscription = await this.getSubscription();

            console.log('subscription in storage: ', storageSubscription);

            if(storageSubscription == false) {
                return true;
            }

            const storageSubscriptionExpiryDate = new Date(storageSubscription['expiryDate']);

            if(isAfter(passedInExpiryDate,storageSubscriptionExpiryDate)) {
                return true;
            } else {
                return false;
            }
        } catch(error: any) {
            console.log('Error:', error);
        }

    }

    async isDateLessThanExpiryDateInStorage(passedInExpiryDate: Date){

        const storageSubscription = await this.getSubscription();

        if(storageSubscription == false) {
            return true;
        }

        const storageSubscriptionExpiryDate = new Date(storageSubscription['expiryDate']);

        if(isBefore(passedInExpiryDate,storageSubscriptionExpiryDate)) {
            return true;
        } else {
            return false;
        }

    }

    async getValue(key: any) {

        let result: any;
        try{
            
            result = await Storage.get({key: key});

            if(result.value != null) {
                result = JSON.parse(result.value);
            } else {
                result = null;
            }
        } catch(error) {
            console.log('Error:', error);
        }

        return result;

    }

    async setValue(key: any, value: any) {

        try{

            await Storage.set({
                key: key,
                value: JSON.stringify(value)
            });
        } catch(error) {
            console.log('Error:', error);
        }

    }

    async removeValue(key: any) {

        try {
            await Storage.remove({key});
        } catch(error) {
            console.log('Error:', error.message);
        }

    }

    async moveHymnInActiveList(moveFromIndex: number, moveToIndex: number) {

        const activeList = await this.getActiveList();
        let activeListHymns = activeList['hymnsAdded'];

        if(activeListHymns != null) {

            let cutOut = activeListHymns.splice(moveFromIndex, 1)[0]; // cut the element at index 'from'
            activeListHymns.splice(moveToIndex, 0, cutOut); // insert it at index 'to'

            activeList['hymnsAdded'] = activeListHymns;
            await this.setValue('activeList', activeList);

        }

        await this.saveActiveList();
        return activeList;

    }

    async updateAttributeInActiveList(attName: any, attValue: any) {

        // The value returned from Storage.get is always a stringified JSON object
        const activeList = await this.getActiveList();

        // Change 'name' in the object
        activeList[attName] = attValue;

        // Set the updated object to 'newList' in storage
        await this.setValue('activeList', activeList);
        await this.saveActiveList();

    }

    async pushHymnToActiveList(hymnNo: any, firstLine: any) {

        const activeList: any = await this.getActiveList();

        const hymnAlreadyExistsInActiveList = activeList['hymnsAdded'].filter((hymn: any) => hymn['hymnNo'] == hymnNo).length > 0 ? true : false

        if(!hymnAlreadyExistsInActiveList) {

            activeList['hymnsAdded'].push({hymnNo: hymnNo, firstLine: firstLine});

            await this.setValue('activeList', activeList);
            await this.saveActiveList();

            return activeList;

        } else {
            return false;
        }

    }

    async saveActiveList() {
        
        const activeList = await this.getActiveList();

        // If the activeList has a creationDate we know it's an already-created list so we just need to
        // update its element in the myLists object
        if(activeList['creationDate'] != null) {

            await this.updateListInMyLists(activeList['creationDate'], activeList);

        } else {

            activeList['creationDate'] = new Date();
            activeList['isOpen'] = false;

            const myLists = await this.getAllMyLists(false);
            myLists.push(activeList);
            await this.setValue('myLists', myLists);

        }

        await this.setValue('activeList', activeList);

    }
    
    async toggleDarkMode() {

        const darkModeIsOn = await this.getValue('darkMode');

        if(darkModeIsOn == null) {
            await this.setValue('darkMode', true);
        } else {
            await this.setValue('darkMode', !darkModeIsOn);
        }

    }


    async getFontSizes(multiplier: any) {


        const fontSizes = {
            hymnNumber: 34 * multiplier,
            verseNumber: 13 * multiplier,
            verse: 17 * multiplier,
            fsc: 15 * multiplier,
            poems: 18 * multiplier,
            fn: 15 * multiplier
        }

        return fontSizes;

    }

    async setFontSizeMultiplier(multiplier: any) {

        try{
            await this.setValue('multiplier', multiplier);
        } catch(error) {
            console.log('Error:', error);
        }

    }

    async getFontSizeMultiplier() {

        let multiplier = await this.getValue('multiplier');
        if(multiplier != null) {
            return parseFloat(multiplier);
        } else {
            try{
                await this.setValue('multiplier', 1);
            } catch(error) {
                console.log('Error:', error);
            }
            return 1;
        }
        

    }

    async deleteActiveList() {

        const activeList = await this.getActiveList();
        let myLists = await this.getAllMyLists(false);

        // If the activeList has a creationDate we know it's an already-created list so we just need to
        // delete its element in the myLists object
        if(activeList['creationDate'] != null) {

            myLists = myLists.filter((list: any) => {

                return list['creationDate'] != activeList['creationDate'];

            })

            await this.setValue('myLists', myLists);
            await this.createNewActiveList();

            return myLists;


        } else {

            await this.createNewActiveList();
            return myLists;

        }

    }

    async updateListInMyLists(creationDate: any, updatedList: any) {

        await this.getAllMyLists(false).then(async myLists => {

            const indexOfList = myLists.findIndex((list: any) => list['creationDate'] == creationDate);

            myLists[indexOfList] = updatedList;

            await this.setValue('myLists', myLists);
            
        })

    }

    async removeHymnFromActiveList(hymnNo: any){

        const activeList = await this.getActiveList();
        activeList['hymnsAdded'] = activeList['hymnsAdded'].filter((hymn: any) => hymn['hymnNo'] != hymnNo)

        await this.setValue('activeList', activeList);
        await this.saveActiveList();

        return activeList;

    }

    async clearStorage() {
        await Storage.clear();
    }

    async getBookmarks() {

        const bookmarks = await this.getValue('bookmarks');

        if (bookmarks != null) {

            return bookmarks;

        } else {

            await this.setValue('bookmarks', [])
            return [];

        }

    }

    async pushHymnToBookmarks(hymnNo: any, firstLine: any) {

        const bookmarks = await this.getBookmarks();
        bookmarks.push({hymnNo: hymnNo, firstLine: firstLine});
        
        await this.setValue('bookmarks', bookmarks);

        return bookmarks;

    }

    async removeHymnFromBookmarks(hymnNo: any) {

        let bookmarks = await this.getBookmarks();
        bookmarks = bookmarks.filter((hymn: any) => hymn['hymnNo'] != hymnNo);

        await this.setValue('bookmarks', bookmarks);
        return bookmarks;

    }

    async checkHymnIsBookmarked(hymnNo: any) {

        const bookmarks = await this.getBookmarks();
        const checkHymnIsBookmarked = bookmarks.find((bookmarkedHymn: any) => hymnNo == bookmarkedHymn['hymnNo']);

        if(checkHymnIsBookmarked != null) {
            return true;
        } else {
            return false;
        }

    }

    async moveHymnInBookmarks(moveFromIndex: number, moveToIndex: number) {

        const bookmarks = await this.getBookmarks();

        if(bookmarks != null) {

            let cutOut = bookmarks.splice(moveFromIndex, 1)[0]; // cut the element at index 'from'
            bookmarks.splice(moveToIndex, 0, cutOut); // insert it at index 'to'

            console.log('bookmarks after repositioning in array:', bookmarks);

            await this.setValue('bookmarks', bookmarks);

        }

        return bookmarks;

    }
}

export default new DataController();