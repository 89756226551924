/* eslint-disable no-unused-expressions */
class DataQuerying {

    constructor(){}

    findHymn(airTableData, hymnNo){

        const hymnData =    airTableData != null ? 
                            airTableData.tables != null ?
                            airTableData.tables['Hymns'].find(hymn => hymnNo == hymn['Number']) : [] : [];

        // console.log(hymnData);

        // Returns an array of record IDs e.g. ['rec334942342', 'rec90439058', etc.] associated with current hymn
        const tuneRecordIDs =   hymnData != null ?
                                hymnData['Tunes'] : [];

        const authorRecordIDs =     hymnData != null ?
                                    hymnData['Authors'] : [];

        const thematicRecordIDs =   hymnData != null ?
                                    hymnData['Thematic'] : [];

        const bibleRefRecordIDs =   hymnData != null ?
                                    hymnData['Bible Ref'] : [];

        const HOSRecordIDs =    hymnData != null ?
                                hymnData['HOS'] : [];

        // Finds the corresponding fields for the tune record ID and stores them as an object along with any
        // other tunes associated with the selected hymn
        hymnData['tunesFields'] =   tuneRecordIDs != null ?
                                    tuneRecordIDs.map(currentTuneRecordID => {

                                        // console.log(`Current tune record ID: ${currentTuneRecordID}`);

                                        // Get the current tune fields
                                        const currentTuneFields = 
                                        this.findLinkedRecordFields(airTableData, 'Tunes', currentTuneRecordID);

                                        if(currentTuneFields['Source'] != null) {
                                            if(typeof currentTuneFields['Source'] == 'string'){
                                                currentTuneFields['Source'] = JSON.parse(`${currentTuneFields['Source']}`);
                                            }
                                        }
                                        
                                        // currentTuneFields['Source'] = currentTuneFields['Source'] != null ?
                                        // JSON.parse(currentTuneFields['Source']) : '';

                                        // Create a field for the returned composer fields
                                        currentTuneFields.composersFields = [];
                                        // Get all the composer record IDs for the current tune
                                        const currentTuneComposers = currentTuneFields['composers'] != null ? currentTuneFields['composers'] : [];
                                        // Get the corresponding 
                                        currentTuneComposers.forEach(currentTuneComposerRecordID => {

                                            const currentTuneCurrentComposerFields = 
                                            this.findLinkedRecordFields(airTableData, 'People', currentTuneComposerRecordID);

                                            currentTuneFields.composersFields.push(currentTuneCurrentComposerFields);

                                        });

                                        currentTuneFields.harmonisersFields = [];
                                        // Get all the harmoniser record IDs for the current tune
                                        const currentTuneHarmonisers = currentTuneFields['harmonisers'] != null ? currentTuneFields['harmonisers'] : [];
                                        // Get the corresponding 
                                        currentTuneHarmonisers.forEach(currentTuneHarmoniserRecordID => {

                                            const currentTuneCurrentHarmoniserFields = 
                                            this.findLinkedRecordFields(airTableData, 'People', currentTuneHarmoniserRecordID);

                                            currentTuneFields.harmonisersFields.push(currentTuneCurrentHarmoniserFields);

                                        });

                                        currentTuneFields.arrangersFields = [];
                                        // Get all the arranger record IDs for the current tune
                                        const currentTuneArrangers = currentTuneFields['arrangers'] != null ? currentTuneFields['arrangers'] : [];
                                        // Get the corresponding 
                                        currentTuneArrangers.forEach(currentTuneArrangersRecordID => {

                                            const currentTuneCurrentArrangersFields = 
                                            this.findLinkedRecordFields(airTableData, 'People', currentTuneArrangersRecordID);

                                            currentTuneFields.arrangersFields.push(currentTuneCurrentArrangersFields);

                                        });

                                        currentTuneFields.adaptersFields = [];
                                        // Get all the adapter record IDs for the current tune
                                        const currentTuneAdapters = currentTuneFields['adapters'] != null ? currentTuneFields['adapters'] : [];
                                        // Get the corresponding 
                                        currentTuneAdapters.forEach(currentTuneAdaptersRecordID => {

                                            const currentTuneCurrentAdaptersFields = 
                                            this.findLinkedRecordFields(airTableData, 'People', currentTuneAdaptersRecordID);

                                            currentTuneFields.adaptersFields.push(currentTuneCurrentAdaptersFields);

                                        });

                                        currentTuneFields.editorsFields = [];
                                        // Get all the editor record IDs for the current tune
                                        const currentTuneEditors = currentTuneFields['editors'] != null ? currentTuneFields['editors'] : [];
                                        // Get the corresponding 
                                        currentTuneEditors.forEach(currentTuneEditorsRecordID => {

                                            const currentTuneCurrentEditorsFields = 
                                            this.findLinkedRecordFields(airTableData, 'People', currentTuneEditorsRecordID);

                                            currentTuneFields.editorsFields.push(currentTuneCurrentEditorsFields);

                                        });

                                        // console.log(currentTuneFields);

                                        return currentTuneFields;

                                    })
        : [];

        hymnData['authorsFields'] =     authorRecordIDs != null ?
                                        authorRecordIDs.map(currentAuthorRecordID => {

                                        // Get the current tune fields
                                        const currentAuthorFields = 
                                        this.findLinkedRecordFields(airTableData, 'People', currentAuthorRecordID);

                                        // If isOpen value isn't defined on object, set it to false so by default, the dropdown is closed
                                        if (currentAuthorFields['isOpen'] == null) {
                                            currentAuthorFields['isOpen'] = false;
                                        }

                                        // Get related hymns
                                        currentAuthorFields['relatedHymns'] = 
                                        currentAuthorFields['Author (from Notes)'].filter(authorHymnNo => authorHymnNo != hymnNo).map(hymnNo => {
                                            return airTableData.tables['Hymns'].find(hymn => hymnNo == hymn['Number']);
                                        }).sort((a, b) => (a['Number'] > b['Number']) ? 1 : ((b['Number'] > a['Number']) ? -1 : 0));

                                        return currentAuthorFields;

                                    })
        : [];


        hymnData['thematicsFields'] =   thematicRecordIDs != null ?
                                        thematicRecordIDs.map(currentThematicRecordID => {

                                        // Get the current tune fields
                                        const currentThematicFields = 
                                        this.findLinkedRecordFields(airTableData, 'Thematic', currentThematicRecordID);

                                        // If isOpen value isn't defined on object, set it to false so by default, the dropdown is closed
                                        if (currentThematicFields['isOpen'] == null) {
                                            currentThematicFields['isOpen'] = false;
                                        }

                                        // Get related hymn records and sort them in numeric order
                                        currentThematicFields['relatedHymns'] = 
                                        currentThematicFields['Number (from Notes)'].filter(thematicHymnNo => thematicHymnNo != hymnNo).map(hymnNo => {
                                            return airTableData.tables['Hymns'].find(hymn => hymnNo == hymn['Number']);
                                        }).sort((a, b) => (a['Number'] > b['Number']) ? 1 : ((b['Number'] > a['Number']) ? -1 : 0));

                                        return currentThematicFields;

                                    })
        : [];

        hymnData['bibleRefFields'] =    bibleRefRecordIDs != null ?
                                        bibleRefRecordIDs.map(currentBibleRefRecordID => {

                                        // Get the current tune fields
                                        const currentBibleRefFields = 
                                        this.findLinkedRecordFields(airTableData, 'Bible Ref', currentBibleRefRecordID);

                                        if (currentBibleRefFields['isOpen'] == null) {
                                            currentBibleRefFields['isOpen'] = false;
                                        }

                                        // Get related hymns
                                        currentBibleRefFields['relatedHymns'] = 
                                        currentBibleRefFields['Number (from Notes)'].filter(bibleRefHymnNo => bibleRefHymnNo != hymnNo).map(hymnNo => {
                                            return airTableData.tables['Hymns'].find(hymn => hymnNo == hymn['Number']);
                                        }).sort((a, b) => (a['Number'] > b['Number']) ? 1 : ((b['Number'] > a['Number']) ? -1 : 0));

                                        return currentBibleRefFields;

                                    })
        : [];

        hymnData['HOSFields'] = HOSRecordIDs != null ?
                                HOSRecordIDs.map(currentHOSRecordID => {

                                // Get the current tune fields
                                const currentHOSFields = 
                                this.findLinkedRecordFields(airTableData, 'HOS', currentHOSRecordID);

                                if (currentHOSFields['isOpen'] == null) {
                                    currentHOSFields['isOpen'] = false;
                                }

                                // Get related hymns
                                currentHOSFields['relatedHymns'] = 
                                currentHOSFields['Number (from Notes)'].filter(hosHymnNo => hosHymnNo != hymnNo).map(hymnNo => {
                                    return airTableData.tables['Hymns'].find(hymn => hymnNo == hymn['Number']);
                                }).sort((a, b) => (a['Number'] > b['Number']) ? 1 : ((b['Number'] > a['Number']) ? -1 : 0));

                                return currentHOSFields;

                            })
        : [];

        return hymnData;

    }

    //Returns an array of hymn numbers which should be passed to the hymn-number.tsx to display them in a list
    getRelatedHymns(airTableData, type, value) {

        let relatedHymns = [];

        switch(true) {

            case type == 'thematic':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = airTableData.tables['Thematic'].find(thematicRecord => value == thematicRecord['Name'])['Number (from Notes)']
                : [] : [];
                break;

            case type == 'hos':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = airTableData.tables['HOS'].find(hosRecord => value == hosRecord['Display Text'])['Number (from Notes)']
                : [] : [];
                break;

            case type == 'category':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = airTableData.tables['Hymns'].filter(hymnRecord => value == hymnRecord['Categories']).map(relatedHymnRecord => relatedHymnRecord['Number'])
                : [] : [];
                break;

            case type == 'bible':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = [].concat.apply([], airTableData.tables['Bible Ref'].filter(bibleRecord => value == bibleRecord['Book Name']).map(relatedBibleRecord => relatedBibleRecord['Number (from Notes)']))
                : [] : [];
                break;

            case type == 'author':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = airTableData.tables['People'].find(personRecord => {

                    // Construct the person's name so it's the same format as the value which was passed
                    const hasSurname = personRecord['Surname'] != null;
                    const hasForenames = personRecord['Forenames'] != null;

                    let displayName = ''
                    switch(true) {

                      case hasSurname && hasForenames:
                        displayName = `${personRecord['Surname']}, ${personRecord['Forenames']}`;
                        break;
                      case hasSurname && !hasForenames:
                        displayName = personRecord['Surname'];
                        break;
                      case !hasSurname && hasForenames:
                        displayName = personRecord['Forenames'];
                        break;
                      case !hasSurname && !hasForenames:
                        displayName = `No name`
                        break;
                        default:
                            // do nothing
                    }

                    return value == displayName
                
                })['Author (from Notes)']
                : [] : [];
                break;

            case type == 'composer':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = [].concat.apply([], airTableData.tables['People'].filter(personRecord => {

                    // Construct the person's name so it's the same format as the value which was passed
                    const hasSurname = personRecord['Surname'] != null;
                    const hasForenames = personRecord['Forenames'] != null;

                    let displayName = ''
                    // eslint-disable-next-line default-case
                    switch(true) {

                      case hasSurname && hasForenames:
                        displayName = `${personRecord['Surname']}, ${personRecord['Forenames']}`;
                        break;
                      case hasSurname && !hasForenames:
                        displayName = personRecord['Surname'];
                        break;
                      case !hasSurname && hasForenames:
                        displayName = personRecord['Forenames'];
                        break;
                      case !hasSurname && !hasForenames:
                        displayName = `No name`
                        break;
                    }

                    return value == displayName
                
                }).map(filteredPersonRecord => {
                    return  filteredPersonRecord['Composer (from Notes)'] != null ?
                                filteredPersonRecord['Composer (from Notes)']
                            :   []
                }))
                : [] : [];
                break;

            case type == 'tune':
                airTableData != null ? 
                airTableData.tables != null ?
                relatedHymns = [].concat.apply([], airTableData.tables['Tunes'].filter(tuneRecord => value == tuneRecord['name']).map(relatedTuneRecord => relatedTuneRecord['Number (from Notes)']))
                : [] : [];
                break;

                default:
                    // do nothing
        }

        return relatedHymns;

    }


    // findInTable is the AT table name we want to get the fields for the record ID from e.g. 'People', 'Tunes', 'Bible Ref' etc.
    // recordIDToFind is simply the the AT record ID e.g. rec4389438432
    findLinkedRecordFields(airTableData, findInTable, recordIDToFind, filter) {

        let toFindTableRecords =    airTableData != null ? 
                                    airTableData.tables != null ?
                                    airTableData.tables[findInTable] : []
                                    : [];

        const correspondingRecordFields = 
        toFindTableRecords.find(record => record['AT Record ID'] === recordIDToFind);
        
        return correspondingRecordFields;
    }

    howManyTimesValueOccursInTable(airTableData, table, field, value) {

        let count = 0;

        if(airTableData?.tables != null) {

            airTableData.tables[table].forEach((record) => {
                if(record[field] == value) {
                    count++;
                }
            });

        }

        return count;

    }

    getAllPossibleVerseNos(airTableData) {

        let verseNos = [];

        if(airTableData?.tables != null) {

            airTableData.tables['Bible Ref'].forEach((record) => {

                let verseNo = this.getVerseNumbersFromBibleRef(record['BibleRef']);
                if (typeof verseNo == 'string') {
                    verseNo = verseNo.split("-")[1];
                }

                if(!verseNos.includes(verseNo) && verseNo != 0) {
                    verseNos.push(verseNo);
                }

            });
        }
        return verseNos.sort((a, b) => a - b);
    }


    getAllPossibleChapterNos(airtableData) {

        let chapterNos = [];

        if(airtableData?.tables != null) {
            airtableData.tables['Bible Ref'].forEach((record) => {

                const bibleRefWithoutLetters = record['BibleRef'].replace(/[a-zA-Z]/g, ''); // leaves us with something like: .1.1-1.2
                const chapterNo = parseInt(bibleRefWithoutLetters.split('.')[1]);

                if(!chapterNos.includes(chapterNo) && chapterNo != 0) {
                    chapterNos.push(chapterNo);
                }
            });

        }

        return chapterNos.sort((a, b) => a - b);

    }

    getChaptersByBookName(airTableData, bookName) {
            
        let chapters = [];
        if(airTableData?.tables != null) {

            airTableData.tables['Bible Ref'].forEach((record) => {

                if(record['Book Name'] == bookName) {

                    const bibleRefWithoutLetters = record['BibleRef'].replace(/[a-zA-Z]/g, '');
                    const chapterNo = parseInt(bibleRefWithoutLetters.split('.')[1]);

                    if(!chapters.includes(chapterNo)){
                        chapters.push(chapterNo);
                    }

                }

            });

            return chapters;
        }
    }

    getChapterNoFromBibleRef(bibleRef) {

        const bibleRefWithoutLetters = bibleRef.replace(/[a-zA-Z]/g, '');
        const chapterNo = parseInt(bibleRefWithoutLetters.split('.')[1]);

        return chapterNo;

    }

    // Bible Refs aren't just a single integer which can be simply sorted, quite a bit of processing is required in order to
    // calculate a number which reflects the position the hymn should appear amongst a list of other hymns.
    //
    // This method basically takes the 'order' field from the hymn's first bible ref, multiplies it by a thousand
    // then adds the chapter number (multiplied by 100) and the verse number (multiplied by 10).
    //
    // e.g. Genesis 1.11 with and order of 1 becomes: (1 * 10000000) 10,000,000 + (11*10,000) 110,000 + (1 * 10) 10 = 10,011,010
    // Genesis 2.1 with and order of 2 becomes: (2 * 10000000) 20,000,000 + (1*10,000) 110,000 + (1 * 10) 10 = 20,011,010
    calulateOrderOfHymnBasedOnBibleRef(hymn, bookName) {

        // const bibleRef = hymn['bibleRefs'][0]['BibleRef'];
        let bibleRefToOrderByRecord;

        if(bookName != 'All' && bookName != null) {
            const bibleRefIndexFromHymn = hymn['bibleRefs'].findIndex(ref => ref['Book Name'] == bookName);
            if(bibleRefIndexFromHymn != -1) {
                bibleRefToOrderByRecord = hymn['bibleRefs'][bibleRefIndexFromHymn];
            } else {
                bibleRefToOrderByRecord = hymn['bibleRefs'][0];
            }
    
        } else {
            bibleRefToOrderByRecord = hymn['bibleRefs'][0];
        }

        const bibleRef = bibleRefToOrderByRecord['BibleRef'];

        const chapterNo = this.getChapterNoFromBibleRef(bibleRef);

        const bibleRefWithoutLetters = bibleRef.replace(/[a-zA-Z]/g, '');
        const appearsAcrossMultipleChapters = bibleRefWithoutLetters.includes('-');

        let firstVerseNo;
        // i.e. if the format of the bible ref is something like (John.1.1-John.1.5)
        if(appearsAcrossMultipleChapters) {
            const versesInThisChapter = bibleRefWithoutLetters.split('-');
            firstVerseNo = parseInt(versesInThisChapter[0].split('.')[2]);
        } else {      
            firstVerseNo = parseInt(bibleRefWithoutLetters.split('.')[2]);
        }

        const originalRefOrderFromRecord = bibleRefToOrderByRecord['Order'];
        return (originalRefOrderFromRecord * 10000000) + (chapterNo * 10000) + (firstVerseNo * 10);

    }

    getVerseNumbersFromBibleRef(bibleRef) {

        const bibleRefWithoutLetters = bibleRef.replace(/[a-zA-Z]/g, '');

        const appearsAcrossMultipleChapters = bibleRefWithoutLetters.includes('-');

        if(appearsAcrossMultipleChapters) {
            const versesInThisChapter = bibleRefWithoutLetters.split('-');
            return `${parseInt(versesInThisChapter[0].split('.')[2])}-${parseInt(versesInThisChapter[1].split('.')[2])}`;
        } else {
            return parseInt(bibleRefWithoutLetters.split('.')[2]);
        }

    }

    getVersesByBookName(airTableData, bookName) {

        let verses = [];

        if(airTableData?.tables != null) {

            airTableData.tables['Bible Ref'].forEach((record) => {

                if(record['Book Name'] == bookName) {

                    const bibleRefWithoutLetters = record['BibleRef'].replace(/[a-zA-Z]/g, '');
                    const appearsAcrossMultipleChapters = bibleRefWithoutLetters.includes('-');

                    // i.e. if the format of the bible ref is something like (John.1.1-John.1.5)
                    if(appearsAcrossMultipleChapters) {

                        const versesInThisChapter = bibleRefWithoutLetters.split('-');
                        const firstVerse = parseInt(versesInThisChapter[0].split('.')[2]);
                        const lastVerse = parseInt(versesInThisChapter[1].split('.')[2]);

                        for(let i = firstVerse; i <= lastVerse; i++) {

                            if(!verses.includes(i)) {
                                verses.push(i);
                            }

                        }

                    } else {
                        const verseNo = parseInt(bibleRefWithoutLetters.split('.')[2]);
                        if(!verses.includes(verseNo)) {
                            verses.push(verseNo);
                        }
                    }

                }

            });

            return verses;

        }

    }

    getVersesByBookNameAndChapterNo(airTableData, bookName, chapterNo) {

        let verses = [];

        if(airTableData?.tables != null) {

            airTableData.tables['Bible Ref'].forEach((record) => {

                const bibleRefWithoutLetters = record['BibleRef'].replace(/[a-zA-Z]/g, '');
                const chapterNoFromRecord = parseInt(bibleRefWithoutLetters.split('.')[1]);

                // console.log('chapterNo', chapterNo, 'bibleRefWithoutLetters',bibleRefWithoutLetters, 'chapterNoFromRecord', chapterNoFromRecord);
                // i.e. if the bible ref is the same bookName and chapterNo received in parameters
                if(record['Book Name'] == bookName && chapterNoFromRecord == chapterNo.toString()) {
    
                    const appearsAcrossMultipleChapters = bibleRefWithoutLetters.includes('-');

                    // i.e. if the format of the bible ref is something like (John.1.1-John.1.5)
                    if(appearsAcrossMultipleChapters) {

                        const versesInThisChapter = bibleRefWithoutLetters.split('-');
                        const firstVerse = parseInt(versesInThisChapter[0].split('.')[2]);
                        const lastVerse = parseInt(versesInThisChapter[1].split('.')[2]);

                        for(let i = firstVerse; i <= lastVerse; i++) {

                            if(!verses.includes(i)) {
                                verses.push(i);
                            }

                        }

                    } else {
                        const verseNo = parseInt(bibleRefWithoutLetters.split('.')[2]);
                        if(!verses.includes(verseNo)) {
                            verses.push(verseNo);
                        }
                    }

                }

            });

        }

        return verses.sort((a, b) => a - b);

    }

    getVersesByChapterNo(airTableData, chapterNo) {

        let verses = [];

        if(airTableData?.tables != null) {

            // Loop through each bible ref in Airtable
            airTableData.tables['Bible Ref'].forEach((record) => {

                // Retrieve the chapter number from the bible ref
                const bibleRefWithoutLetters = record['BibleRef'].replace(/[a-zA-Z]/g, '');
                const chapterNoFromRecord = parseInt(bibleRefWithoutLetters.split('.')[1]);

                // i.e. if the bible ref chapter number is the same as chapterNo received in parameters
                if(chapterNoFromRecord == chapterNo.toString()) {
    
                    const appearsAcrossMultipleChapters = bibleRefWithoutLetters.includes('-');

                    // i.e. if the format of the bible ref is something like (John.1.1-John.1.5)
                    if(appearsAcrossMultipleChapters) {

                        const versesInThisChapter = bibleRefWithoutLetters.split('-');
                        const firstVerse = parseInt(versesInThisChapter[0].split('.')[2]);
                        const lastVerse = parseInt(versesInThisChapter[1].split('.')[2]);

                        for(let i = firstVerse; i <= lastVerse; i++) {

                            if(!verses.includes(i)) {
                                verses.push(i);
                            }

                        }

                    } else {
                        const verseNo = parseInt(bibleRefWithoutLetters.split('.')[2]);
                        if(!verses.includes(verseNo)) {
                            verses.push(verseNo);
                        }
                    }

                }

            });

        }

        return verses.sort((a, b) => a - b);

    }

}

export default DataQuerying