import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonSearchbar, IonToolbar, withIonLifeCycle } from "@ionic/react";
import { addOutline, chevronForwardOutline } from "ionicons/icons";
import { Component } from "react";
import { withRouter } from "react-router";
import List from "../../components/List";
import DataController from "../../controllers/DataController";

import searchIcon from '../../theme/assets/svgs/button-36-pt-search.svg'

type Props = {
    hymnNumberList: any,
    setTabs: any,
    history: any
}

type State = {
    searchText: any
}

class AddHymns extends Component<Props, State> {

    constructor(props: Props){
        super(props);

        this.state = {
            searchText: ''
        }

    }

    async getSelectedItem(item: any) {

        const cameFrom = this.props.history.location.state['cameFrom'] != null ?
                         this.props.history.location.state['cameFrom'] : 'home'

        await DataController.pushHymnToActiveList(item['number'], item['text']);
        this.props.history.replace('create-or-modify-list', {type: 'adding', cameFrom: cameFrom});

        // this.props.history.goBack({type: 'adding', cameFrom: cameFrom});

        // ^^ Testing to see if goBack() can be used whilst passing a state, the current replace method
        // used above leads to an incorrect animation being used, as if the process is moving forward
        // when it's going back to the create list page

        // Can't pass state using goBack but there may be some way of using a listener to intercept a goBack() call
        // and replace the state there, see top-rated answer: https://stackoverflow.com/questions/52507789/how-to-define-state-when-using-history-goback 

    }

    setSearchText(newValue: string) {

        this.setState({
            searchText: newValue
        });

    }

    render(){

        const compareField = 'compareField'
        const displayNumberField = 'number'
        const displayTextField = 'text'
        const placeholder = 'Please enter hymn number or first line';

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {this.props.history.replace('create-or-modify-list')}}>
                                Close
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent scrollY={false} style={{'--background': 'var(--content-background)'}} fullscreen>
                    <div className="allContentContainer" id="allContentContainer" style={{ height: 300, overflow: "auto" }}>

                        <div className="contentContainer">
                            <div className="content" style={{backgroundColor: 'var(--content-background)'}}>
                                <IonSearchbar searchIcon={searchIcon} value={this.state.searchText} placeholder={placeholder} onIonChange={e => this.setSearchText(e.detail.value!)}></IonSearchbar>
                            </div>
                        </div>

                        <div className="contentContainer">
                            <div className="content content-border">
                                <div className="listContainer">
                                    <List
                                        list={this.props.hymnNumberList != null ?
                                                this.props.hymnNumberList : []
                                        } 
                                        searchTerm={this.state.searchText != null ? this.state.searchText : ''}
                                        compareField={compareField}
                                        displayNumberField = {displayNumberField}
                                        displayTextField = {displayTextField}
                                        getSelectedItem = {this.getSelectedItem.bind(this)}
                                        detailIcon = {addOutline}
                                        boldFont = {false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );

    }

}

export default withRouter(withIonLifeCycle(AddHymns))