import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonIcon, IonRouterLink, IonRouterOutlet, withIonLifeCycle } from '@ionic/react';
import './browse.css';
import React, { Component} from 'react';

import {AirTableData, BrowseListItem} from '../../interfaces';

/* Theme variables */
import '../../theme/variables.css';
import rightChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Right.svg'
import { Route, withRouter } from 'react-router';

type Props = {
  airTableData: AirTableData | null,
  history: any, setTabs: any
}
type State = {
  
}

class Browse extends Component <Props, State> {

  constructor(props: Props) {
    super(props);

  }

  ionViewWillEnter() {
    this.props.setTabs('browse', false);
  }

  componentDidMount() {

  }

  render() {

    return (

    <IonPage> 

      <IonHeader>
        <IonToolbar>
          <IonTitle>Browse</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent style={{'--background': 'var(--content-background)'}} fullscreen>

        <div className="allContentContainer">
          <div className="contentContainer">  
            <div className="content content-border">
              {/* <div className="listContainer"> */}
                <IonList>
                  <IonItem button key='Hymn Number' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'hymn-number',{hymns: 'default', title: ''})} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Hymn Number
                    </p>
                  </IonItem>
                  <IonItem button key='First Line' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'first-line',{hymns: 'default', title: ''})} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      First Line
                    </p>
                  </IonItem>
                  <IonItem button key='Categories' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'categories', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Categories
                    </p>
                  </IonItem>
                  {/* <IonItem button key='Biblical Index' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'biblical-index', 'Default')} style={{'--detail-icon-font-size': '14px'}}>  */}
                  <IonItem button key='Biblical Index' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'biblical-index',{hymns: 'default', headerTitle: 'Biblical Index', useBibleBookFilters: true, showBibleBookName: true})} style={{'--detail-icon-font-size': '14px'}}> 
                    <p className="listItemTextBold">
                      Biblical Index
                    </p>
                  </IonItem>
                  <IonItem button key='Thematic Index' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'thematic-index', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Thematic Index
                    </p>
                  </IonItem>
                  <IonItem button key='Calendar of Sundays' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'sundays-and-major-feasts', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Sundays & Major Feast Days
                    </p>
                  </IonItem>
                  <IonItem button key='Author' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'author', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Author
                    </p>
                  </IonItem>
                  <IonItem button key='Composer' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'composer', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Composer
                    </p>
                  </IonItem>
                  <IonItem button key='Tune' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'tune', 'Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      Tune
                    </p>
                  </IonItem>
                  <IonItem button key='My bookmarks' detail={true} detailIcon={rightChevronIcon} onClick={this.props.history.push.bind(this,'my-bookmarks','Default')} style={{'--detail-icon-font-size': '14px'}}>
                    <p className="listItemTextBold">
                      My bookmarks
                    </p>
                  </IonItem>
                </IonList>
              {/* </div> */}
            </div>
          </div>
        </div>
      </IonContent>

    </IonPage>
  )}

};

export default withRouter(withIonLifeCycle(Browse));
