import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonNote, IonPage, IonReorder, IonReorderGroup, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import { chevronBack, searchOutline, menuOutline } from "ionicons/icons";
import { Component } from "react"
import { withRouter } from "react-router";
import  DataController  from "../../../controllers/DataController";

import leftChevronIcon from '../../../theme/assets/svgs/Button_Disclosure_Back.svg'

type Props = {airTableData: any, bookmarksList: any, setTabs: any, history: any}
type State = { bookmarkedHymns: any, reorder: any}
  
class BookmarksList extends Component <Props, State> {

    items: any = [];

    ionItemSlidingRefs: any = [];  
    setRef = (ref: any) => {
        this.ionItemSlidingRefs.push(ref);
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            bookmarkedHymns: [],
            reorder: false
        }

    }

    async ionViewWillEnter() {

        this.props.setTabs('browse' , false);

        const bookmarkedHymns = await DataController.getBookmarks();
        this.setState({
            bookmarkedHymns: bookmarkedHymns
        })

    }

    async removeHymn(hymnNo: any, index: any) {

        // Close the 'Remove' button which appears when swiping to remove hymns
        if(this.ionItemSlidingRefs[index] != null) {
            await this.ionItemSlidingRefs[index].closeOpened();
        }

        // Give the UI half a second to reset the ionItemSliding item before removing to make transition smoother 
        setTimeout(() => {
            DataController.removeHymnFromBookmarks(hymnNo).then((updatedBookmarks: any) => {
                this.setState({bookmarkedHymns: updatedBookmarks});
            });    
        }, 500);

    }

    doReorder(event: any) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        DataController.moveHymnInBookmarks(event.detail.from, event.detail.to).then((updatedBookmarks) => {

            // Finish the reorder and position the item in the DOM based on
            // where the gesture ended. This method can also be called directly
            // by the reorder group
            event.detail.complete(this.state.bookmarkedHymns);

            this.setState({bookmarkedHymns: updatedBookmarks});

        })
    
    }

    render() {

        return (
            
            <IonPage>
    
                <IonHeader>
                    <IonToolbar>
    
                        <IonButtons slot="start">
                            <IonButton onClick={() => this.props.history.goBack()}>
                                <IonIcon style={{height: '17px'}} icon={leftChevronIcon} />
                                <div className='backButtonTxt'>Back</div>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Bookmarks</IonTitle>
    
                    </IonToolbar>
                </IonHeader>
    
                <IonContent style={{'--background': 'var(--content-background)'}} fullscreen>

                    <div className="allContentContainer">
                        <div className="contentContainer">
                            <div className="content content-border">
                                <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '5px', paddingTop: '5px'}}>
                                    {this.state.reorder == false ?
                                        <div 
                                            className="smallBtnText clickable" 
                                            onClick={() => this.setState({reorder: !this.state.reorder})}
                                            style={{opacity: this.state.bookmarkedHymns.length <= 1 ? '0.5' : '1', paddingRight: '12px', paddingTop: '12px'}} 
                                        >
                                            Reorder
                                        </div>
                                    :   <div className="smallBtnText clickable" onClick={() => this.setState({reorder: !this.state.reorder})} style={{paddingRight: '12px', paddingTop: '12px'}}>
                                            Done
                                        </div>
                                    }
                                </div>

                                <p hidden={this.state.bookmarkedHymns.length > 0} className="listItemText" style={{textAlign: 'center', paddingTop: '12px', paddingBottom: '24px'}}>No bookmarks have been added yet</p>

                                <IonReorderGroup disabled={!this.state.reorder} onIonItemReorder={this.doReorder.bind(this)}>
                                                    
                                    {this.state.bookmarkedHymns.map((currentHymn: any, index: any) => {

                                        return  <IonReorder key={'ionitemreorder' + index}>
                                                    <IonItemSliding key={'ionitemsliding' + index} ref={this.setRef}>  
                                                        <IonItem lines={index == this.state.bookmarkedHymns.length - 1 ? 'none' : 'inset'} key={'ionitem' + index} detail={this.state.reorder} detailIcon={menuOutline}>
                                                            <IonNote 
                                                                slot='start'
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto'
                                                                    }}
                                                            >
                                                                <div className='number-box' style={{display: 'block'}}>
                                                                    <div className="number">
                                                                        {currentHymn['hymnNo']}
                                                                    </div>
                                                                </div>
                                                            </IonNote>
                                                            <IonLabel>
                                                                {currentHymn['firstLine']}
                                                            </IonLabel>

                                                        </IonItem>
                                                        <IonItemOptions side="end">
                                                            <IonItemOption color='danger' onClick={()=> this.removeHymn(currentHymn['hymnNo'], index)}>Remove</IonItemOption>
                                                        </IonItemOptions>
                                                </IonItemSliding>
                                            </IonReorder>

                                    })}

                                </IonReorderGroup>
                            </div>
                        </div>
                    </div>
                </IonContent>
    
            </IonPage>
            )

    }

}

export default withRouter(withIonLifeCycle(BookmarksList));