/* Theme variables */
import { AirTableData } from '../../interfaces';
import React, { Component, createRef, ReactNode, Ref, useRef} from 'react';
import '../../theme/variables.css';
import { IonContent, IonHeader, withIonLifeCycle, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonCol, IonSlide, IonSlides, getPlatforms, isPlatform, IonIcon, IonImg, IonItem, IonLabel, IonToast, IonTabBar, IonTabButton, IonList, IonNote, IonRange, IonToggle, IonPopover, IonModal } from '@ionic/react';
import { addOutline, chevronBack, chevronBackOutline, chevronForward, chevronForwardOutline, create, timeSharp} from 'ionicons/icons';
import './hymn.css'

import DataQuerying from '../../data/DataQuerying';
import { Link, withRouter } from 'react-router-dom';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import aAButton from '../../theme/assets/Button_Text_Options@3x.png';

import readingListInactiveButton from '../../theme/assets/Button_Reading_List_Inactive@3x.png';
import readingListActiveButton from '../../theme/assets/Button_Reading_List_Active@3x.png';

import shareiOSButton from '../../theme/assets/Button_Share_iOS@3x.png';

import disclosureBack from '../../theme/assets/button-disclosure-back@3x.png'
import textDecrease from '../../theme/assets/Button_Text_Decrease@3x.png';
import textIncrease from '../../theme/assets/Button_Text_Increase@3x.png';

import leftChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Back.svg'
import upChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Up.svg'
import downChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Down.svg'

import bookmarkActive from '../../theme/assets/Button_Bookmark_Active@3x.png';
import bookmarkInactive from '../../theme/assets/Button_Bookmark_Inactive@3x.png';

import addToList from '../../theme/assets/Button_Add-to-list@3x.png';
import addIcon from '../../theme/assets/svgs/Button_Add_Sepia.svg'

import { ClickAwayListener } from '@mui/material';

import Swiper, {SwiperOptions} from "swiper"
import "swiper/swiper.min.css";
import DataController from '../../controllers/DataController';

import { format } from "date-fns";

import { Share } from '@capacitor/core';
import { SocialSharing } from '@ionic-native/social-sharing';
import { Clipboard } from '@capacitor/core';

type Props = {airTableData: AirTableData | null, history: any, setTabs: any}
type State = {
    virtualData: any,
    currentHymn: any,
    currentHymnIsBookmarked: any,
    tuneBodyIsOpen: any,
    relatedHymnBodyIsOpen: any,
    fontBoxIsOpen: any,
    addToListBoxIsOpen: any,
    showToast: boolean,
    showModalAddToList: boolean,
    myLists: any,
    toastMessage: any,
    toastColor: any,
    fontSizeMultiplier: any
}

class Hymn extends Component <Props, State> {

    content: any = createRef();
    currentAllContent: any = createRef();

    currentHymnNo: number = 0;
    currentHymnIndex: number = 0;
    loadedHymns: any = [];
    list: any = [];

    dataQuerying: DataQuerying;
    swiper: any;
    fontBoxRef: any = createRef();

    constructor(props: Props) {
        super(props);

        this.dataQuerying = new DataQuerying();
        this.state = {
            virtualData: { slides: []},
            currentHymn: null,
            currentHymnIsBookmarked: false,
            tuneBodyIsOpen: true,
            relatedHymnBodyIsOpen: true,
            fontBoxIsOpen: false,
            addToListBoxIsOpen: false,
            showToast: false,
            myLists: [],
            showModalAddToList: false,
            toastMessage: '',
            toastColor: 'success',
            fontSizeMultiplier: 1
        }

    }

    // This method is executed just before the component is displayed
    async ionViewWillEnter() {

        // await DataController.setFontSizeMultiplier(1);

        const fontSizeMultiplier = await DataController.getFontSizeMultiplier();
        const fontSizes = await DataController.getFontSizes(fontSizeMultiplier);
        // console.log('fontSizeMultiplier:', fontSizeMultiplier);
        // console.log('fontSizes:', fontSizes);

        document.documentElement.style.setProperty('--hymn-number-font-size', `${fontSizes['hymnNumber']}px`);
        document.documentElement.style.setProperty('--verse-font-size', `${fontSizes['verse']}px`);
        document.documentElement.style.setProperty('--verse-number-font-size', `${fontSizes['verseNumber']}px`);
        document.documentElement.style.setProperty('--fsc-font-size', `${fontSizes['fsc']}px`);
        document.documentElement.style.setProperty('--poems-font-size', `${fontSizes['poems']}px`);
        document.documentElement.style.setProperty('--fn-font-size', `${fontSizes['fn']}px`);

        this.setState({
            fontSizeMultiplier: fontSizeMultiplier
        })

        this.props.setTabs('browse', true);

        // Collapse any related hymn sections which may have been opened from a previously viewed hymn
        this.collapseAllRelatedHymns();

        //Scroll to top upon loading hymn
        if(this.content != null) {
            if(this.content.current != null) {
                this.content.current.scrollEvents = true;
                this.content.current.scrollToTop();
            }
        }

        const self = this;

        // We're using the window.history state here instead of the state in this.props.history because window.history state seems less prone to being lost upon refreshing.
        //
        // The state in window.history can be edited by calling the window.history.replaceState method (as used in a few methods below), but it can also access the state which is passed when using the
        // this.props.history.push method - the annoying thing about this however, is that the state from the this.props.history.location object gets saved in window.history.state.state as
        // opposed to directly in window.history.state.
        // 
        // When we use the window.history.replaceState method, the state will be stored directly in window.history.state, however other components within the app (like hymn-number.tsx) navigates to this
        // component by using this.props.history.push, therefore it's important to ensure we are retrieving the state variables correctly.
        //
        // We could check to see if window.history.state.state is null before just checking window.history.state, but to save the making the if statements even more tedious
        // I simply just pass the state variables inside an object called state when using window.history.replaceState to keep things consistent when handling states received from window.history.replaceState and this.props.history.location.push.

        // Check the state passed from the history.push/replace method
        if(window.history.state?.state != null) {

            // Check to make sure the selectedHymn has a value
            if(window.history.state.state.selectedHymn != null) {

                this.currentHymnNo = window.history.state.state.selectedHymn;
                const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

                // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

                this.setState({
                    currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                    currentHymnIsBookmarked: isBookmarked
                })

                this.list = window.history.state.state.hymnList;

              // If the history.state.state.selectedHymn value is null it's because the hymn was directly loaded using the URL  
            } else {

                // Retrieve hymn number from current path
                this.currentHymnNo = parseInt(window.location.pathname.substring(
                    window.location.pathname.lastIndexOf('/')+1,
                    window.location.pathname.length
                ));

                // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);
                const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

                this.setState({
                    currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                    currentHymnIsBookmarked: isBookmarked
                })

                // Save an array from numbers 1 to 847
                this.list = Array.from(Array(847), (_,x) => x+1);

            }

        // If the history.state is null it's because the hymn was directly loaded using the URL  
        } else {
            // Retrieve hymn number from current path
            this.currentHymnNo = parseInt(window.location.pathname.substring(
                    window.location.pathname.lastIndexOf('/')+1,
                    window.location.pathname.length
            ));
            // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

            const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

            this.setState({
                currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                currentHymnIsBookmarked: isBookmarked
            })

            this.list = Array.from(Array(847), (_,x) => x+1);
        }

        // Initialise swiper
        this.swiper = new Swiper('.swiper-container', {
            virtual: {
              slides: self.state.virtualData.slides,
              renderExternal(data) {
                // assign virtual slides data
                self.setState({
                  virtualData: data,
                });
              }
            },
            // autoHeight: true,
            observer: true,
            observeParents: true,
            initialSlide: 1 // Set this based on which number slide user is on (out of three where index starts at zero)
        });

        // Set initial slides after first load
        this.updateSlides();

        // Set slides after swiped to next slide
        this.swiper.on('slideNextTransitionEnd', async () => {

            //Scroll to top upon swiping
            if(this.content != null) {
                if(this.content.current != null) {
                    this.content.current.scrollEvents = true;
                    this.content.current.scrollToTop();
                }
            }

            this.collapseAllRelatedHymns();

            if (this.currentHymnNo === 847) {

                this.updateSlides();

            } else {

                this.currentHymnIndex = this.currentHymnIndex + 1;
                this.currentHymnNo = this.list[this.currentHymnIndex];
                // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

                const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

                this.setState({
                    currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                    currentHymnIsBookmarked: isBookmarked
                })

                window.history.replaceState({state: {title: '', hymnList: this.list, selectedHymn: this.currentHymnNo}}, "New Page Title", `hymn/${this.currentHymnNo}`);

                this.updateSlides();

            }

        });

        // Set slides after swiped to previous slide
        this.swiper.on('slidePrevTransitionEnd', async () => {

            //Scroll to top upon swiping
            if(this.content != null) {
                if(this.content.current != null) {
                    this.content.current.scrollEvents = true;
                    this.content.current.scrollToTop();
                }
            }

            this.collapseAllRelatedHymns();

            if (this.currentHymnNo === 0) {

                this.updateSlides();

            } else {

                this.currentHymnIndex = this.currentHymnIndex -1;
                this.currentHymnNo = this.list[this.currentHymnIndex];
                // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

                const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

                this.setState({
                    currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                    currentHymnIsBookmarked: isBookmarked
                })

                window.history.replaceState({state: {title: '', hymnList: this.list, selectedHymn: this.currentHymnNo}}, "New Page Title", `hymn/${this.currentHymnNo}`);
                
                this.updateSlides();

            }

        });

        if (this.props.history.location.state != null) {

            if (this.props.history.location.state.toastMessage != null) {

                this.setState({
                    showToast: true,
                    toastMessage: this.props.history.location.state.toastMessage
                })

            }

        }

        await DataController.getAllMyLists(false).then((myLists: any) => {

            this.setState({myLists: myLists});
            // console.log('myLists:', this.state.myLists);
      
        })

    }

    async onPrevButtonClick() {

        //Scroll to top upon swiping
        if(this.content != null) {
            if(this.content.current != null) {
                this.content.current.scrollEvents = true;
                this.content.current.scrollToTop();
            }
        }

        if (this.currentHymnNo === 0) {

            this.updateSlides();

        } else {

            this.currentHymnIndex = this.currentHymnIndex -1;
            this.currentHymnNo = this.list[this.currentHymnIndex];
            // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

            const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

            this.setState({
                currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                currentHymnIsBookmarked: isBookmarked
            })

            window.history.replaceState({state: {title: '', hymnList: this.list, selectedHymn: this.currentHymnNo}}, "New Page Title", `hymn/${this.currentHymnNo}`);
            
            this.updateSlides();

        }

    }

    async onNextButtonClick() {

        //Scroll to top upon swiping
        if(this.content != null) {
            if(this.content.current != null) {
                this.content.current.scrollEvents = true;
                this.content.current.scrollToTop();
            }
        }

        if (this.currentHymnNo === 847) {

            this.updateSlides();

        } else {

            this.currentHymnIndex = this.currentHymnIndex + 1;
            this.currentHymnNo = this.list[this.currentHymnIndex];
            // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

            const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

            this.setState({
                currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
                currentHymnIsBookmarked: isBookmarked
            })

            window.history.replaceState({state: {title: '', hymnList: this.list, selectedHymn: this.currentHymnNo}}, "New Page Title", `hymn/${this.currentHymnNo}`);

            this.updateSlides();

        }

    }

    async jumpToSlide(hymnNo: any) {

        this.currentHymnNo = hymnNo;
        // this.currentHymn = this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo);

        const isBookmarked = await DataController.checkHymnIsBookmarked(this.currentHymnNo);

        this.setState({
            currentHymn: this.dataQuerying.findHymn(this.props.airTableData, this.currentHymnNo),
            currentHymnIsBookmarked: isBookmarked
        })

        this.collapseAllRelatedHymns();

        //Scroll to top upon swiping
        if(this.content != null) {
            if(this.content.current != null) {
                this.content.current.scrollEvents = true;
                this.content.current.scrollToTop();
            }
        }

        // Replace state using the default 1 to 847 hymns
        this.list = Array.from(Array(847), (_,x) => x+1);
        window.history.replaceState({state: {title: '', hymnList: this.list, selectedHymn: this.currentHymnNo}}, "New Page Title", `hymn/${this.currentHymnNo}`);

        this.updateSlides();

    }

    updateSlides() {

        this.currentHymnIndex = this.list.indexOf(this.currentHymnNo);

        let slides: any = [];

        // If there's only one slide in the list
        if (this.list.length == 1) {
        
            slides = [this.list[this.currentHymnIndex]];
            this.swiper.activeIndex = 0;
        
          // if there's more than one slide in the list
        } else {

            // If the slide we're at is first in the list
            if (this.currentHymnIndex == 0) {
                slides = [  this.list[this.currentHymnIndex],
                            this.list[this.currentHymnIndex+1]
                        ];
                this.swiper.activeIndex = 0;

            // If the slide we're at is the last in the list
            } else if(this.currentHymnIndex == this.list.length-1) {
                slides = [  this.list[this.currentHymnIndex-1],
                            this.list[this.currentHymnIndex]
                        ];
                this.swiper.activeIndex = 1;

            // if the slide is somewhere in the middle
            } else {
                slides = [  this.list[this.currentHymnIndex-1],
                            this.list[this.currentHymnIndex],
                            this.list[this.currentHymnIndex+1]
                        ];
                this.swiper.activeIndex = 1;
            }

        }

        this.setState({
          virtualData: {
            slides: slides,
          }
        })

    }

    /* This function receives a person object. A person object's properties are basically just all the fields from their record
     * in the 'People' table in AirTable.
     * 
     * This function is primarily used to format the people who appear at the bottom of a hymn in the 'Music:' section
     * It isn't sufficient to just use the 'Forenames', 'Surname' and 'Dates' properties/fields when displaying a person in this section.
     * This is because there are cases were, for example, there isn't anything in 'Forenames' so the 'Surname' and 'Dates' should
     * just be used.
     * 
     * The goal is to format the people in this section so that they match the same format at the bottom of each hymn in 
     * the 'AncientandModernWordsepub.epub' file. Some of the differences between how a person should be formatted are quite 
     * peculiar, that is why we check conditions like 'isCorporate', 'hasSuffix', 'hasPrintName' etc. Each different 
     * permutation will lead to a slightly different way of formatting the person's details. 
     * 
     * WHEN AN ERROR OCCURS - !IMPORTANT!
     * Hopefully now I've covered every potential permutation which may come up, however if errors do occur and seem to be happening
     * due to this method, the thing which is likely causing it will be that a new case for an undetected permutation is required, or
     * one of the cases are accessing fields which don't exist on the person object.
     * 
     * 
     */
    formatPerson(person: any) {

        const isCorporate = person['IsCorporate'] != null ? true : false;
        const hasSuffix = person['Suffix'] != null ? true : false;
        const hasPrintName = person['Print Name'] != null ? true : false;

        const hasForenames = person['Forenames'] != null ? true : false;
        const hasDate = person['Dates'] != null ? true : false;

        let display: any = '';

        switch(true) {

            // These are the permutations/combinations for each case where isCorporate, hasSuffix and hasPrintName is true/false
            case isCorporate && hasSuffix && hasPrintName:
                display = [`${person['Print Name']}, `, 
                <span className="tuneSuffix">{`${person['Suffix']} `}</span>, 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            case isCorporate && hasSuffix && !hasPrintName:
                display = [`${person['Forenames']}, `, 
                <span className="tuneSuffix">{`${person['Suffix']} `}</span>, 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            case isCorporate && !hasSuffix && hasPrintName:
                display = [`${person['Print Name']} `, 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            case isCorporate && !hasSuffix && !hasPrintName:
                display = [`${person['Forenames']} `, 
                <span className="tuneDate">{`${person['Dates']}`}</span>];
                break;
            
            case !isCorporate && hasSuffix && hasPrintName:
                display = [this.formatPersonName(person['Print Name']),
                ', ',
                <span className="tuneSuffix">{`${person['Suffix']} `}</span>, 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            case !isCorporate && hasSuffix && !hasPrintName:
                display = [this.formatPersonName(person['Forenames']),
                ' ',
                this.formatPersonName(person['Surname']), 
                ', ',
                <span className="tuneSuffix">{`${person['Suffix']} `}</span>, 
                <span className="tuneDate">{`(${person['Dates']})`}</span>]
                break;

            case !isCorporate && !hasSuffix && hasPrintName:
                display = [this.formatPersonName(person['Print Name']), 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            // These permutations are for rare cases which is why the additional 'hasForenames' and 'hasDate' are included
            // in the case condition.
            case !isCorporate && !hasSuffix && !hasPrintName && hasForenames && hasDate:
                display = [this.formatPersonName(person['Forenames']), 
                ' ',
                this.formatPersonName(person['Surname']), 
                <span className="tuneDate">{`(${person['Dates']})`}</span>];
                break;

            case !isCorporate && !hasSuffix && !hasPrintName && hasForenames && !hasDate:
                display = [this.formatPersonName(person['Forenames'])]
                break;

            case !isCorporate && !hasSuffix && !hasPrintName && !hasForenames && !hasDate:
                display = [this.formatPersonName(person['Surname'])];
                break;

        }

        return display;

    }

    // This method might receive something like, 'John Smith'. It will split the name into two elements: 'John' and 'Smith'.
    // From here, it will loop through each of the names and format them into an array containing the first letter of their name
    // and the rest of the characters of their name (which are capitalised). e.g. 'John' becomes ['J', 'OHN'] and 'Smith' becomes ['S', 'MITH'].
    // 
    // This needs to happen because if you look at the bottom of each hymn in the 'AncientandModernWordsepub.epub' file, you'll see that each person's
    // name will have a slightly larger first letter from the rest of the characters, and everything will be in uppercase. By splitting the first letter
    // here, it allows us to wrap it in a div which contains css code with a greater font size than the rest of their name.
    formatPersonName(name: any) {

        const names = name.split(' ');

        return names.map((currentName: any) => {

            const firstLetter = currentName.charAt(0);
            const restOfName = currentName.substring(1, name.length);
            return [firstLetter.toUpperCase(), <span className="personRestOfName">{restOfName.toUpperCase()} </span>]

        })


    }

    collapseAllRelatedHymns() {

        this.loadedHymns.forEach((currentHymn: any) => {

            if (currentHymn['thematicsFields'] != null) {
                currentHymn['thematicsFields'].forEach((currentThematic: any) => {

                    currentThematic['isOpen'] = false;

                })
            }

            if (currentHymn['authorsFields'] != null) {
                currentHymn['authorsFields'].forEach((currentAuthor: any) => {

                    currentAuthor['isOpen'] = false;

                })
            }
            
            if (currentHymn['bibleRefFields'] != null) {
                currentHymn['bibleRefFields'].forEach((currentBibleRef: any) => {

                    currentBibleRef['isOpen'] = false;

                })
            }

            if (currentHymn['HOSFields'] != null) {
                currentHymn['HOSFields'].forEach((currentHOS: any) => {

                    currentHOS['isOpen'] = false;

                })
            }

        })
        
    }

    async increaseFontSizes() {

        // Get current font size multiplier and increment it by 0.1 i.e 10%
        const currentFontSizeMultiplier = await DataController.getFontSizeMultiplier();
        const fontSizeMultiplierIncreased = currentFontSizeMultiplier + 0.1;

        // Save new font size multiplier value to storage so font sizes don't reset upon refreshing
        await DataController.setFontSizeMultiplier(fontSizeMultiplierIncreased);

        const increasedFontSizes = await DataController.getFontSizes(fontSizeMultiplierIncreased);

        document.documentElement.style.setProperty('--hymn-number-font-size', `${increasedFontSizes['hymnNumber']}px`)
        document.documentElement.style.setProperty('--verse-font-size', `${increasedFontSizes['verse']}px`)
        document.documentElement.style.setProperty('--verse-number-font-size', `${increasedFontSizes['verseNumber']}px`)
        document.documentElement.style.setProperty('--fsc-font-size', `${increasedFontSizes['fsc']}px`)
        document.documentElement.style.setProperty('--poems-font-size', `${increasedFontSizes['poems']}px`)
        document.documentElement.style.setProperty('--fn-font-size', `${increasedFontSizes['fn']}px`)

        // Save new font size multiplier to state so it can be displayed in the JSX render method below
        this.setState({
            fontSizeMultiplier: fontSizeMultiplierIncreased
        })

    }

    async decreaseFontSizes() {

        const currentFontSizeMultiplier = await DataController.getFontSizeMultiplier();
        const fontSizeMultiplierDecreased = currentFontSizeMultiplier - 0.1;

        // Save new font size multiplier value to storage so font sizes don't reset upon refreshing
        await DataController.setFontSizeMultiplier(fontSizeMultiplierDecreased);

        const decreasedFontSizes = await DataController.getFontSizes(fontSizeMultiplierDecreased);

        document.documentElement.style.setProperty('--hymn-number-font-size', `${decreasedFontSizes['hymnNumber']}px`)
        document.documentElement.style.setProperty('--verse-font-size', `${decreasedFontSizes['verse']}px`)
        document.documentElement.style.setProperty('--verse-number-font-size', `${decreasedFontSizes['verseNumber']}px`)
        document.documentElement.style.setProperty('--fsc-font-size', `${decreasedFontSizes['fsc']}px`)
        document.documentElement.style.setProperty('--poems-font-size', `${decreasedFontSizes['poems']}px`)
        document.documentElement.style.setProperty('--fn-font-size', `${decreasedFontSizes['fn']}px`)

        // Save new font size multiplier to state so it can be displayed in the JSX render method below
        this.setState({
            fontSizeMultiplier: fontSizeMultiplierDecreased
        })

    }

    loadHymn(hymnNumber: any) {

        // console.log(`Loading content for hymn number ${hymnNumber}`);

        const currentHymn = this.dataQuerying.findHymn(this.props.airTableData, hymnNumber);

        // Store hymn in loadedHymns so we can reference it outside of this method (mainly to be able to collapse the related hymn sections)
        // in the collapseAllRelatedHymns() method
        this.loadedHymns.push(currentHymn);

        const showButtons = isPlatform('desktop');

        const hymnHTML =    currentHymn != null ? 
                            currentHymn['Hymn HTML'] : '';

        const seeAlso = currentHymn != null ?
                        currentHymn['See Also'] != null ?
                        JSON.parse(currentHymn['See Also']) : ''
                        : '';

        return <div className="allContentContainer selectable-text" ref={this.currentAllContent}>
                    <div className="contentContainer">  
                        <div className="hymnContent">
                            <div className='hymnNumber'>{hymnNumber}</div>
                            <div className='hymnWords'
                                dangerouslySetInnerHTML={{
                                __html: hymnHTML
                            }}></div>

                            <div style={{height: '32px'}}></div>

                        </div>
                    </div>

                    {/* START OF TUNES SECTION */}
                    <div className="tuneContainer">
                        <div className="tuneContent">
                            {/* <div className={this.state.tuneBodyIsOpen ? "tuneHeaderWithBorder" : "tuneHeaderWithoutBorder"}> */}
                            <div 
                                className="tuneHeader"
                                onClick={() => { this.setState({
                                    tuneBodyIsOpen: !this.state.tuneBodyIsOpen
                                })}} 
                            >
                                <div>
                                    Tunes
                                </div>  
                                <div className="tuneChevron clickable">
                                    <IonIcon 
                                        icon={this.state.tuneBodyIsOpen ? upChevronIcon : downChevronIcon} 
                                    />
                                </div>
                            </div>
                            
                            {this.state.tuneBodyIsOpen ?
                                <div>
                                    <div className="seperator" style={{width: '75px', backgroundColor: 'var(--seperator-1)', marginBottom: '5px'}} />
                                    <div className="tuneBody">
                                        {currentHymn['tunesFields'].map((tune: any, index: number) =>
                                            <div key={'tune'+index}>

                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span className="tuneName">
                                                        {tune['name']}
                                                    </span>
                                                    <span className="tuneMetre">
                                                        {tune['metre']}
                                                    </span>
                                                </div>
                                                
                                                <div className="musicContent">
                                                    <div className='tuneMusic' key={'tuneID'+index}>
                                                        <i>Music:</i>
                                                    </div>   

                                                    <div>
                                                        <div className="tuneComposer">
                                                            {tune['composersFields'].length > 0 ? 
                                                                [' ', 
                                                                tune['composersFields'][0]['Detail'] != null ?
                                                                    tune['composersFields'][0]['Detail']
                                                                :   '', 
                                                                ' ',
                                                                tune['composersFields'].map((composer: any, index: any) => {
                                                                
                                                                    const lastIndex = tune['composersFields'].length-1;
                                                                    const composerDisplay = this.formatPerson(composer)

                                                                    // Converts the array of people to a comma-seperated list with ' and ' before the last element 
                                                                    // e.g. 'Keith Getty (b.1974), Kristyn Getty (b.1980) and Stuart Townsend (b. 1963)'
                                                                    if(index+2 <= lastIndex){ composerDisplay.push([', ']); return composerDisplay };
                                                                    if(index+1 == lastIndex){ composerDisplay.push(' and '); return composerDisplay};
                                                                    if(index == lastIndex){ return composerDisplay};
                                                                    return composerDisplay

                                                                })]
                                                            : ''}
                                                        </div>
                                                        {/* Display the data from source */}
                                                        {tune['Source'] != null ?
                                                            <div className="tuneSource">
                                                                {tune['Source'].map((source: any, index: any) => {
                                                                    return  source['inanimate'] != null ?
                                                                            source['inanimate'][0]['description'] != null ?
                                                                                index+1 != tune['Source'].length ?
                                                                                    `${source['inanimate'][0]['description'][0]} `
                                                                                :   [<i>{`${source['inanimate'][0]['description'][0]}`}</i>]
                                                                            : '' : ''
                                                                })}
                                                            </div> : ''
                                                        }

                                                        {/* Display harmoniser data */}
                                                        <div className="tuneHarmoniser">
                                                            {tune['harmonisersFields'].length > 0 ? 
                                                                [' ', 
                                                                <span className="tuneItalic">
                                                                    {tune['harmonisersFields'][0]['Detail'] != null ?
                                                                        tune['harmonisersFields'][0]['Detail']
                                                                    :   `harmonised by`
                                                                    }
                                                                </span>, 
                                                                ' ',
                                                                tune['harmonisersFields'].map((harmoniser: any, index: any) => {
                                                                
                                                                    const lastIndex = tune['harmonisersFields'].length-1;
                                                                    const harmoniserDisplay = this.formatPerson(harmoniser)

                                                                    // Converts the array of people to a comma-seperated list with ' and ' before the last element
                                                                    if(index+2 <= lastIndex){ harmoniserDisplay.push([', ']); return harmoniserDisplay };
                                                                    if(index+1 == lastIndex){ harmoniserDisplay.push(' and '); return harmoniserDisplay};
                                                                    if(index == lastIndex){ return harmoniserDisplay};
                                                                    return harmoniserDisplay                                
                                                                })]
                                                            : '' 
                                                        }</div>

                                                        {/* <br/> */}

                                                        {/* Display arranger data */}
                                                        <div className="tuneArranger">
                                                            {tune['arrangersFields'].length > 0 ? 
                                                                [' ', 
                                                                <span className="tuneItalic">
                                                                    {tune['arrangersFields'][0]['Detail'] != null ?
                                                                        tune['arrangersFields'][0]['Detail']
                                                                    :   `arranged by`
                                                                    }
                                                                </span>, 
                                                                ' ',
                                                                tune['arrangersFields'].map((arranger: any, index: any) => {
                                                                
                                                                    const lastIndex = tune['arrangersFields'].length-1;
                                                                    const arrangerDisplay = this.formatPerson(arranger)

                                                                    // Converts the array of people to a comma-seperated list with ' and ' before the last element
                                                                    if(index+2 <= lastIndex){ arrangerDisplay.push([', ']); return arrangerDisplay };
                                                                    if(index+1 == lastIndex){ arrangerDisplay.push(' and '); return arrangerDisplay};
                                                                    if(index == lastIndex){ return arrangerDisplay};
                                                                    return arrangerDisplay                                
                                                                })]
                                                            : '' 
                                                        }</div>

                                                        {/* Display arranger data */}
                                                        <div className="tuneAdapter">
                                                            {tune['adaptersFields'].length > 0 ? 
                                                                [' ', 
                                                                <span className="tuneItalic">
                                                                    {tune['adaptersFields'][0]['Detail'] != null ?
                                                                        tune['adaptersFields'][0]['Detail']
                                                                    :   `adapted by`
                                                                    }
                                                                </span>, 
                                                                ' ',
                                                                tune['adaptersFields'].map((adapter: any, index: any) => {
                                                                
                                                                    const lastIndex = tune['arrangersFields'].length-1;
                                                                    const adapterDisplay = this.formatPerson(adapter)

                                                                    // Converts the array of people to a comma-seperated list with ' and ' before the last element
                                                                    if(index+2 <= lastIndex){ adapterDisplay.push([', ']); return adapterDisplay };
                                                                    if(index+1 == lastIndex){ adapterDisplay.push(' and '); return adapterDisplay};
                                                                    if(index == lastIndex){ return adapterDisplay};
                                                                    return adapterDisplay                                
                                                                })]
                                                            : '' 
                                                        }</div>

                                                        {/* Display arranger data */}
                                                        <div className="tuneEditor">
                                                            {tune['editorsFields'].length > 0 ? 
                                                                [' ', 
                                                                <span className="tuneItalic">
                                                                    {tune['editorsFields'][0]['Detail'] != null ?
                                                                        tune['editorsFields'][0]['Detail']
                                                                    :   `adapted by`
                                                                    }
                                                                </span>, 
                                                                ' ',
                                                                tune['editorsFields'].map((editor: any, index: any) => {
                                                                
                                                                    const lastIndex = tune['editorsFields'].length-1;
                                                                    const editorDisplay = this.formatPerson(editor)

                                                                    // Converts the array of people to a comma-seperated list with ' and ' before the last element
                                                                    if(index+2 <= lastIndex){ editorDisplay.push([', ']); return editorDisplay };
                                                                    if(index+1 == lastIndex){ editorDisplay.push(' and '); return editorDisplay};
                                                                    if(index == lastIndex){ return editorDisplay};
                                                                    return editorDisplay                                
                                                                })]
                                                            : '' 
                                                        }</div>
                                                    </div>
                                                </div>

                                                {tune['m_copyright'] != null ?
                                                    <div 
                                                        className="fn" 
                                                        style={{paddingBottom: '10px', paddingTop: '24px'}}
                                                        dangerouslySetInnerHTML={{
                                                            __html: JSON.parse(tune['m_copyright'])[0]['detail'].toString().replace('(c)', '&copy;')
                                                        }} 
                                                    />
                                                    :   ''
                                                }

                                            </div>
                                        )}
                                    </div>
                                </div>
                            :   ''}

                            <div className="seperator" style={{width: '100%', backgroundColor: 'var(--seperator-1'}}></div>

                            {/* START OF RELATED HYMN SECTION */}
                            <div className="tuneHeader">
                                Related hymns
                            </div>  

                            <div className="seperator" style={{width: '175px', backgroundColor: 'var(--seperator-1)'}}></div>

                            {/* Loop through and display related Thematic Indexes */}

                            {this.state.relatedHymnBodyIsOpen ?
                                <div className="relatedHymnsBody">
                                    {currentHymn['thematicsFields'] != null ?
                                     currentHymn['thematicsFields']
                                     .filter((currentThematic: any) => currentThematic['relatedHymns'].length > 0)
                                     .map((currentThematic: any) => {
                                     return <div>
                                                <div
                                                    onClick={() => {
                                                        currentThematic['isOpen'] = !currentThematic['isOpen']
                                                        this.forceUpdate();
                                                    }} 
                                                    className='clickable'
                                                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}
                                                    >
                                                        <div>
                                                            <div className="relatedHymnHeader">
                                                                {currentThematic['Name']}
                                                            </div>  
                                                            <div className="relatedHymnChildHeader">
                                                                Thematic Index
                                                            </div>
                                                        </div>
                                                        <div className="relatedHymnChevron clickable">
                                                            <IonIcon
                                                                icon={currentThematic['isOpen'] ?
                                                                upChevronIcon : downChevronIcon} 
                                                            />
                                                        </div>
                                                </div>
                                                <div className="seperator" style={{width: '100%', background: 'var(--seperator-1)', marginTop: '10px'}} />
                                                {currentThematic['isOpen'] ? 
                                                    <div>
                                                        <IonList style={{marginTop: '12px'}}>
                                                            {currentThematic['relatedHymns']
                                                            .filter((element: any, index: any) => index < 5) /*only show the first five related hymns*/
                                                            .map((relatedHymn: any, index: any) => {
                                                                return <IonItem
                                                                            button 
                                                                            detail={true}
                                                                            style={{'--detail-icon-font-size': '17px', '--padding-end': '0px'}}
                                                                            onClick={this.jumpToSlide.bind(this,relatedHymn['Number'])}
                                                                            key={'thematic' + currentThematic['Name'] + index + this.currentHymnIndex}
                                                                        >
                                                                        <IonNote 
                                                                            slot='start'
                                                                            style={{
                                                                                marginTop: 'auto',
                                                                                marginBottom: 'auto'
                                                                            }} 
                                                                        >
                                                                            <div className='number-box' style={{display: 'block'}}>
                                                                                <div className="number">
                                                                                    {relatedHymn['Number']}
                                                                                </div>
                                                                            </div>
                                                                        </IonNote>
                                                                        <p className="listItemText">
                                                                            {relatedHymn['Name/First Line']}
                                                                        </p>
                                                                </IonItem>
                                                            })}

                                                            {/* Only show 'See more' option if the amount of related hymns is greater than 5 */}
                                                            {currentThematic['relatedHymns'].length > 5 ?
                                                            <div 
                                                                className='clickable' 
                                                                style={{color: 'var(--disclosure-2)', paddingTop: '6px', paddingBottom: '6px', display: 'flex', justifyContent: 'center'}}
                                                                onClick={(() => {
                                                                const relatedHymns = currentThematic['relatedHymns'].map((relatedHymn: any) => relatedHymn['Number'])
                                                                return this.props.history.push('../hymn-number',{hymns: relatedHymns, title: currentThematic['Name']})
                                                                })}
                                                            >
                                                                See More...
                                                            </div>
                                                            : ''}
                                                        </IonList>
                                                    </div>
                                                :   ''}
                                            </div>
                                    }) : ''}

                                    {/* Loop through and display related Bible Refs */}
                                    {currentHymn['bibleRefFields'] != null ?
                                     currentHymn['bibleRefFields']
                                     .filter((currentBibleRef: any) => currentBibleRef['relatedHymns'].length > 0) // only display bible title if it has related hymns
                                     .map((currentBibleRef: any) => {
                                     return <div>
                                                <div
                                                    onClick={() => {
                                                        currentBibleRef['isOpen'] = !currentBibleRef['isOpen']
                                                        this.forceUpdate();
                                                    }} 
                                                    className='clickable'
                                                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}
                                                    >
                                                        <div>
                                                            <div className="relatedHymnHeader">
                                                                {currentBibleRef['Name']}
                                                            </div>  
                                                            <div className="relatedHymnChildHeader">
                                                                Bible Index
                                                            </div>
                                                        </div>
                                                        <div className="relatedHymnChevron clickable">
                                                            <IonIcon
                                                                icon={currentBibleRef['isOpen'] ?
                                                                upChevronIcon : downChevronIcon} 
                                                            />
                                                        </div>
                                                </div>
                                                <div className="seperator" style={{width: '100%', background: 'var(--seperator-1)', marginTop: '10px'}} />
                                                {currentBibleRef['isOpen'] ?
                                                    <div>
                                                    <IonList style={{marginTop: '12px'}}>
                                                        {currentBibleRef['relatedHymns']
                                                        .filter((element: any, index: any) => index < 5) /*only show the first five related hymns*/
                                                        .map((relatedHymn: any, index: any) => {
                                                            return <IonItem
                                                                        button 
                                                                        detail={true}
                                                                        style={{'--detail-icon-font-size': '17px', '--padding-end': '0px'}}
                                                                        onClick={this.jumpToSlide.bind(this,relatedHymn['Number'])}
                                                                        key={'bibleRef' + currentBibleRef['Name'] + index + this.currentHymnIndex}
                                                                    >
                                                                    <IonNote 
                                                                        slot='start'
                                                                        style={{
                                                                            marginTop: 'auto',
                                                                            marginBottom: 'auto'
                                                                        }} 
                                                                    >
                                                                        <div className='number-box' style={{display: 'block'}}>
                                                                            <div className="number">
                                                                                {relatedHymn['Number']}
                                                                            </div>
                                                                        </div>
                                                                    </IonNote>
                                                                    <p className="listItemText">
                                                                        {relatedHymn['Name/First Line']}
                                                                    </p>
                                                            </IonItem>
                                                        })}

                                                        {/* Only show 'See more' option if the amount of related hymns is greater than 5 */}
                                                        {currentBibleRef['relatedHymns'].length > 5 ?
                                                        <div 
                                                            className='clickable' 
                                                            style={{color: 'var(--disclosure-2)', paddingTop: '6px', paddingBottom: '6px', display: 'flex', justifyContent: 'center'}}
                                                            onClick={(() => {
                                                            const relatedHymns = currentBibleRef['relatedHymns'].map((relatedHymn: any) => relatedHymn['Number'])
                                                            return this.props.history.push('../hymn-number',{hymns: relatedHymns, title: currentBibleRef['Name']})
                                                            })}
                                                        >
                                                            See More...
                                                        </div>
                                                        : ''}
                                                    </IonList>
                                                </div>
                                                :   ''}
                                            </div>
                                     }) : ''}

                                    {/* Loop through and display related Hymns on Sunday */}
                                    {currentHymn['HOSFields'] != null ?
                                     currentHymn['HOSFields']
                                     .filter((currentHOS: any) => currentHOS['relatedHymns'].length > 0) // only display HOS title if it has related hymns
                                     .map((currentHOS: any) => {
                                     return <div>
                                                <div
                                                    onClick={() => {
                                                        currentHOS['isOpen'] = !currentHOS['isOpen']
                                                        this.forceUpdate();
                                                    }} 
                                                    className='clickable'
                                                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}
                                                    >
                                                        <div>
                                                            <div className="relatedHymnHeader">
                                                                {currentHOS['Name']}
                                                            </div>  
                                                            <div className="relatedHymnChildHeader">
                                                                Sundays & Major Feasts Days
                                                            </div>
                                                        </div>
                                                        <div className="relatedHymnChevron clickable">
                                                            <IonIcon
                                                                icon={currentHOS['isOpen'] ?
                                                                upChevronIcon : downChevronIcon} 
                                                            />
                                                        </div>
                                                </div>
                                                <div className="seperator" style={{width: '100%', background: 'var(--seperator-1)', marginTop: '10px'}} />
                                                {currentHOS['isOpen'] ?
                                                    <div>
                                                    <IonList style={{marginTop: '12px'}}>
                                                        {currentHOS['relatedHymns']
                                                        .filter((element: any, index: any) => index < 5) /*only show the first five related hymns*/
                                                        .map((relatedHymn: any, index: any) => {
                                                            return <IonItem
                                                                        button 
                                                                        detail={true}
                                                                        style={{'--detail-icon-font-size': '17px', '--padding-end': '0px'}}
                                                                        onClick={this.jumpToSlide.bind(this,relatedHymn['Number'])}
                                                                        key={'hos' + currentHOS['Name'] + index + this.currentHymnIndex}
                                                                    >
                                                                    <IonNote 
                                                                        slot='start'
                                                                        style={{
                                                                            marginTop: 'auto',
                                                                            marginBottom: 'auto'
                                                                        }} 
                                                                    >
                                                                        <div className='number-box' style={{display: 'block'}}>
                                                                            <div className="number">
                                                                                {relatedHymn['Number']}
                                                                            </div>
                                                                        </div>
                                                                    </IonNote>
                                                                    <p className="listItemText">
                                                                        {relatedHymn['Name/First Line']}
                                                                    </p>
                                                            </IonItem>
                                                        })}

                                                        {/* Only show 'See more' option if the amount of related hymns is greater than 5 */}
                                                        {currentHOS['relatedHymns'].length > 5 ?
                                                        <div 
                                                            className='clickable' 
                                                            style={{color: 'var(--disclosure-2)', paddingTop: '6px', paddingBottom: '6px', display: 'flex', justifyContent: 'center'}}
                                                            onClick={(() => {
                                                            const relatedHymns = currentHOS['relatedHymns'].map((relatedHymn: any) => relatedHymn['Number'])
                                                            return this.props.history.push('../hymn-number',{hymns: relatedHymns, title: currentHOS['Name']})
                                                            })}
                                                        >
                                                            See More...
                                                        </div>
                                                        : ''}
                                                    </IonList>
                                                    </div>
                                                :   ''}
                                            </div>
                                     }) : ''}

                                    {/* Loop through and display related Authors */}
                                    {currentHymn['authorsFields'] != null ?
                                     currentHymn['authorsFields']
                                     .filter((currentAuthor: any) => currentAuthor['relatedHymns'].length > 0) // only display author name if they have related hymns
                                     .map((currentAuthor: any) => {
                                     return <div>
                                                <div
                                                    onClick={() => {
                                                        currentAuthor['isOpen'] = !currentAuthor['isOpen']
                                                        this.forceUpdate();
                                                    }} 
                                                    className='clickable'
                                                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}
                                                    >
                                                        <div>
                                                            <div className="relatedHymnHeader">
                                                                {currentAuthor['Surname'] != null ? 
                                                                    
                                                                    // Has surname
                                                                    currentAuthor['Forenames'] != null ?
                                                                        // Has surname and forenames
                                                                        `${currentAuthor['Surname']}, ${currentAuthor['Forenames']}`

                                                                        // Has surname but no forenames
                                                                    :    currentAuthor['Surname']
                                                                    // No surname
                                                                :   currentAuthor['Forenames'] != null ?
                                                                        // No surname, has forenames
                                                                        currentAuthor['Forenames']
                                                                        
                                                                        // No surname, no forenames
                                                                    :   ''

                                                                }
                                                            </div>  
                                                            <div className="relatedHymnChildHeader">
                                                                Author
                                                            </div>
                                                        </div>
                                                        <div className="relatedHymnChevron clickable">
                                                            <IonIcon
                                                                icon={currentAuthor['isOpen'] ?
                                                                upChevronIcon : downChevronIcon} 
                                                            />
                                                        </div>
                                                </div>
                                                <div className="seperator" style={{width: '100%', background: 'var(--seperator-1)', marginTop: '10px'}} />
                                                {currentAuthor['isOpen'] ?
                                                    <div>
                                                    <IonList style={{marginTop: '12px'}}>
                                                        {currentAuthor['relatedHymns']
                                                        .filter((element: any, index: any) => index < 5) /*only show the first five related hymns*/
                                                        .map((relatedHymn: any, index: any) => {
                                                            return <IonItem
                                                                        button 
                                                                        detail={true}
                                                                        style={{'--detail-icon-font-size': '17px', '--padding-end': '0px'}}
                                                                        onClick={this.jumpToSlide.bind(this,relatedHymn['Number'])}
                                                                        key={'author' + currentAuthor['Forenames'] + currentAuthor['Surname'] + index + this.currentHymnIndex}
                                                                    >
                                                                    <IonNote 
                                                                        slot='start'
                                                                        style={{
                                                                            marginTop: 'auto',
                                                                            marginBottom: 'auto'
                                                                        }} 
                                                                    >
                                                                        <div className='number-box' style={{display: 'block'}}>
                                                                            <div className="number">
                                                                                {relatedHymn['Number']}
                                                                            </div>
                                                                        </div>
                                                                    </IonNote>
                                                                    <p className="listItemText">
                                                                        {relatedHymn['Name/First Line']}
                                                                    </p>
                                                            </IonItem>
                                                        })}

                                                        {/* Only show 'See more' option if the amount of related hymns is greater than 5 */}
                                                        {currentAuthor['relatedHymns'].length > 5 ?
                                                        <div 
                                                            className='clickable' 
                                                            style={{color: 'var(--disclosure-2)', paddingTop: '6px', paddingBottom: '6px', display: 'flex', justifyContent: 'center'}}
                                                            onClick={(() => {
                                                                const relatedHymns = currentAuthor['relatedHymns'].map((relatedHymn: any) => relatedHymn['Number'])
                                                                return this.props.history.push('../hymn-number',{hymns: relatedHymns, title: currentAuthor['Name']})
                                                            })}
                                                        >
                                                            See More...
                                                        </div>
                                                        : ''}
                                                    </IonList>
                                                    </div>
                                                :   ''}
                                            </div>
                                     }) : ''}
                                </div>
                            :   ''}
                        </div>
                    </div>
                </div>
                    
    }

    async addHymnToSelectedList(creationDate: any) {

        // Retrieve the list from storage
        const selectedList = await DataController.getListByCreationDate(creationDate);
    
        // Make the retrieved list the active list
        await DataController.setActiveList(selectedList);
    
        // Push the hymn into the active list
       const updatedActiveList = await DataController.pushHymnToActiveList(this.state.currentHymn['Number'], this.state.currentHymn['Name/First Line']);

        if (updatedActiveList) {

            this.setState({
                myLists: await DataController.getAllMyLists(false),
                showModalAddToList: false,
                showToast: true,
                toastMessage: `Hymn ${this.state.currentHymn['Number']} was successfully added to list '${selectedList['name']}'`
           })

        } else {
 
            this.setState({
                showToast: true,
                toastColor: 'danger',
                toastMessage: `Hymn number ${this.state.currentHymn['Number']} already exists in this list`
            })
        }
    
    }

    async initiateShare() {

        try {
            if(isPlatform('ios') || isPlatform('android')){
                SocialSharing.share(`I've been looking at hymn ${this.currentHymnNo}, ${this.state.currentHymn['Name/First Line']} in the Hymns Ancient and Modern app. Download the app from - `, 'Hymns Ancient & Modern', undefined, `https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern`)
            } else {
                await Share.share({
                    title: 'Hymns Ancient & Modern',
                    text: `I've been looking at hymn ${this.currentHymnNo}, ${this.state.currentHymn['Name/First Line']} in the Hymns Ancient and Modern app. Download the app from - `,
                    url: `https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern`,
                    dialogTitle: 'Share',
                });
            }
        } catch(error) {
            console.log('Error', error);
        }

    }

    async writeToClipboard(text: string) {

        await Clipboard.write({
          string: text.replace(/<[^>]*>/g, "")
        });

        this.setState({
            showToast: true,
            toastColor: 'success',
            toastMessage: `Copied to clipboard`
        })

    };

    render() {

        // Reset on every render to prevent more than 3 hymns being pushed into array
        this.loadedHymns = [];

        return (
        <IonPage>
            <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.css" />
            <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css" />

            <IonHeader>

                <IonToolbar>

                    <IonButtons slot="start">
                        <IonButton onClick={() => this.props.history.goBack()}>
                            <IonIcon style={{height: '17px'}} icon={leftChevronIcon} />
                            <div className='backButtonTxt'>Back</div>
                        </IonButton>
                    </IonButtons>
                    
                    <IonButtons slot="end">
                    <IonButton  onClick={() => this.writeToClipboard(this.state.currentHymn['Hymn HTML'])} >Copy</IonButton>
                        <img 
                            className="clickable" 
                            onClick={() => this.setState({fontBoxIsOpen: !this.state.fontBoxIsOpen})} 
                            src={aAButton} 
                            height='40' 
                            width='40'
                            style={{opacity: this.state.fontBoxIsOpen ? '0.5' : '1'}}
                        />
                        <img 
                            className="clickable" 
                            onClick={() => this.setState({addToListBoxIsOpen: true})} 
                            src={this.state.currentHymnIsBookmarked ? readingListActiveButton : readingListInactiveButton} 
                            height='40' 
                            width='40'
                            style={{opacity: this.state.addToListBoxIsOpen ? '0.5' : '1'}}
                        />
                        <img className="toolBarIcon clickable" onClick={() => this.initiateShare()} src={shareiOSButton} height='40' width='40'></img>
                        
                    </IonButtons>

                </IonToolbar>

                

            </IonHeader>

            {/* Code below is for when the 'add to list' model box menu is open */}
            <IonModal isOpen={this.state.showModalAddToList}>
                
                <div className="modal-custom-css">

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={() => {this.setState({showModalAddToList: false})}}>
                                    Close
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    {/* <IonContent style={{'--background': 'var(--content-background)'}}> */}

                        <div className="allContentContainer flexChildFullHeight">

                            <div className="contentContainer">
                                <div className="content content-border">
                                    <div className="myListContainer">

                                        <IonButton 
                                            expand="block" 
                                            className='createListBtn' 
                                            color='tertiary'
                                            onClick={() => {
                                                this.props.history.push('../create-or-modify-list', {
                                                    preselectedHymns: [
                                                        {hymnNo: this.state.currentHymn['Number'], firstLine: this.state.currentHymn['Name/First Line']}
                                                    ]
                                                });
                                                this.setState({showModalAddToList: false})
                                            }}>
                                            <div slot="start" className="createListBtnTxt">
                                                <IonIcon style={{height: '15px'}} icon={addIcon}></IonIcon> Create new list
                                            </div>
                                        </IonButton>

                                        <div className="seperator" />

                                        {this.state.myLists.map((currentList: any, listIndex: any) => {

                                            return <div key={'myLists' + listIndex}>
                                        
                                                <div className="listsContent">

                                                    <div className="listHeader">
                                                        {currentList['name']}
                                                    </div>

                                                    <div style={{display: 'flex'}}>
                                                        <div 
                                                            className="smallBtn clickable" 
                                                            onClick={() => this.props.history.push('create-or-modify-list', {creationDate: currentList['creationDate']})}
                                                        />
                                                        <div 
                                                            className="listChevron clickable" 
                                                            onClick={() => this.addHymnToSelectedList(currentList['creationDate'])}
                                                            style={{paddingLeft: '20px', color: 'var(--cta-1-50)', fontSize: '15px', fontFamily: 'Literata'}} 
                                                        >
                                                            Add {this.currentHymnNo}
                                                        </div>
                                                    </div>
                                                </div>

                                                {currentList['date'] != '' ?
                                                <div className="listDate">
                                                    {/* The Date object gets lost when stored as a JSON object, so the currentList date has to be converted back before using get methods*/}
                                                    {format(new Date(currentList['date']), 'd MMMM yyyy')}
                                                </div>
                                                : ''}

                                                <IonList style={{marginTop: '8px'}}>
                                                    <IonItem key={listIndex + 'hymnsAddedClosed'}>
                                                        { currentList['hymnsAdded'] != null ?
                                                            currentList['hymnsAdded'].map((currentHymn: any, hymnIndexInList: any) => {  
                                                            return  <div className='number-box-minimized' style={{display: 'block'}} /*onClick={() => this.navigateToListHymn(listIndex, hymnIndexInList)}*/>
                                                                        <div className="number">
                                                                            {currentHymn['hymnNo']}
                                                                        </div>
                                                                    </div>
                                                            })
                                                        : ''}
                                                    </IonItem>
                                                </IonList>

                                                {listIndex !== this.state.myLists.length - 1 ?
                                                    <div className="seperator" style={{width: 'inherit'}} />
                                                : ''
                                                }
                                            </div>

                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </IonContent> */}
                </div>
            </IonModal>

            <IonContent style={{'--background': 'var(--content-background)'}} ref={this.content} color="card-fill">

                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    // touchEvent="onTouchStart"
                    onClickAway={() => this.setState({fontBoxIsOpen: false})}
                >

                    <div hidden={!this.state.fontBoxIsOpen}>
                        <div className="fontBoxPointer" style={{right: '90px'}} />
                        <div className="fontBoxContainer" style={{right: '30px'}}>
                            <div style={{display: 'flex'}}>

                                <div
                                    className='fontResizeBtn flexCenter'
                                    onClick={async () => {
                                        if (this.state.fontSizeMultiplier > 0.3){
                                            await this.decreaseFontSizes();
                                        }
                                    }} 
                                    style={{borderRight: '1px solid var(--seperator-1)', flexGrow: 1}}
                                >
                                    <img src={textDecrease} height='40' width='40' style={{opacity: this.state.fontSizeMultiplier > 0.3 ? '100%' : '20%'}}/>
                                </div>

                                <div 
                                    className='flexCenter'
                                    style={{borderRight: '1px solid var(--seperator-1)', flexGrow: 1}}>
                                    {Math.round(this.state.fontSizeMultiplier * 100)}%
                                </div>

                                <div 
                                    className='fontResizeBtn flexCenter'
                                    onClick={async () => {
                                        if (this.state.fontSizeMultiplier <= 2){
                                            await this.increaseFontSizes();
                                        }
                                    }}
                                    style={{flexGrow: 1}}
                                >
                                    <img src={textIncrease} height='40' width='40' style={{opacity: this.state.fontSizeMultiplier <= 2 ? '100%' : '20%'}} />
                                </div>
                            </div>
                            <div className="seperator" style={{width: '100%'}}></div>
                            <div className="copy-to-clipboard" onClick={() => this.writeToClipboard(this.state.currentHymn['Hymn HTML'])}>
                                Copy hymn to clipboard
                            </div>
                        </div>
                    </div>

                </ClickAwayListener>

                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => this.setState({addToListBoxIsOpen: false})}
                >

                    <div hidden={!this.state.addToListBoxIsOpen}>
                        <div className="fontBoxPointer" style={{right: '50px'}}/>
                        
                        <div className="fontBoxContainer" style={{right: '30px', height: '90px'}}>
                            <div className="bookmarkAndAddToListMenu" >

                                <div 
                                    className="bookmarkAndAddToListItem clickable" 
                                    onClick={async () => {
                                        if(this.state.currentHymnIsBookmarked) {
                                            await DataController.removeHymnFromBookmarks(this.state.currentHymn['Number']);
                                        }
                                        else {
                                            await DataController.pushHymnToBookmarks(this.state.currentHymn['Number'], this.state.currentHymn['Name/First Line']);
                                        }

                                        this.setState({
                                            currentHymnIsBookmarked: !this.state.currentHymnIsBookmarked
                                        });
                                    
                                    }}
                                >
                                    <div>Bookmark this hymn</div>
                                    <img src={this.state.currentHymnIsBookmarked ? bookmarkActive : bookmarkInactive} height='44' width='44' />
                                </div>

                                <div className="seperator" style={{width: '100%'}} />

                                <div 
                                    className="bookmarkAndAddToListItem clickable"
                                    onClick={() => this.setState({
                                        showModalAddToList: true
                                    })}
                                >
                                    <div>Add to My lists</div>
                                    <img src={addToList} height='44' width='44' />
                                </div>

                            </div>
                        </div>
                    </div>

                </ClickAwayListener>

                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {/* It is important to set "left" style prop on every slide */}
                        {this.state.virtualData.slides.map((slide: any, index: any) => (
                        <div className="swiper-slide"
                            key={index}
                            style={{left: `${this.state.virtualData.offset}px`}}
                        >{this.loadHymn(slide)}</div>
                        ))}
                    </div>
                </div>

                <IonToast
                    color={this.state.toastColor}
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({showToast: false})}
                    message={this.state.toastMessage}
                    duration={3000}
                />

            </IonContent>

            {/* Bit of a hack, but having an empty tab bar creates the space at the bottom for the toolbar (class below) to occupy. 
              * Without having the empty tab bar, the web version looks okay but on mobile, there is a bit of space underneath
              * the toolbar when it should be stuck right to the bottom.
            */}
            <IonTabBar style={{height: '15px'}}></IonTabBar>

            <div className="bottomToolBar" style={{paddingBottom: 'env(safe-area-inset-bottom)'}}>
                <div className="contentContainer">
                    <div className="content">
                        <div className="bottomToolBarContainer">

                            <div className="prevNextHymnToolBarContainer feedBackOnClick clickable" onClick={this.onPrevButtonClick.bind(this)}>
                                <span className="currentPageToolBarIcon" hidden={this.currentHymnIndex == 0}>
                                    <IonIcon style={{height: '14px'}} icon={this.currentHymnIndex >= 0 ? chevronBackOutline : ''} /> 
                                </span>

                                <span className="prevNextHymnToolBarText" hidden={this.currentHymnIndex == 0 }>
                                    {this.list[this.currentHymnIndex-1]}
                                </span>
                            </div>

                            <div className="prevNextHymnToolBarContainer">
                                <div className="currentPageToolBarText">
                                    {`${this.currentHymnIndex + 1} / ${this.list.length}`}
                                </div>
                            </div>
                            <div className="prevNextHymnToolBarContainer feedBackOnClick clickable" onClick={this.onNextButtonClick.bind(this)}>
                                <span className="prevNextHymnToolBarText" style={{marginLeft: 'auto'}} hidden={this.currentHymnIndex == this.list.length-1}>
                                    {this.list[this.currentHymnIndex+1]} 
                                </span>
                                
                                <span className="currentPageToolBarIcon" hidden={this.currentHymnIndex == this.list.length-1}>
                                    <IonIcon style={{height: '14px'}} icon={this.currentHymnIndex != this.list.length-1 ? chevronForwardOutline : ''} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IonPage>
        )

    }

}

export default withRouter(withIonLifeCycle(Hymn));