import { IonIcon, IonItem, IonLabel, IonNote, withIonLifeCycle } from "@ionic/react";
import { chevronForwardOutline, refreshCircleSharp } from "ionicons/icons";
import { Component, createRef } from "react";

import { Virtuoso } from 'react-virtuoso'
import 'globalthis';

import '../pages/browse/lists/lists.css'

import './Listv2.css'

type Props = { 
    list: [],
    displayNumberField: string | undefined | null
    displayTextField: string,
    getSelectedItem: any,
    detailIcon: any,
    detailColor: any,
    subtitle: any,
    boldFont: boolean,
    tuneHymns: any, // receives array of numbers
    removeTopAndBottomPaddingFromMainText: any,
    sortBy: any
}

type State = {
    visibleRange: any
}

class Listv2 extends Component <Props, State> {

    constructor(props: Props){
        super(props);

        this.state = {
            visibleRange: false
        }

    }

    ionViewDidEnter(){


    }

    render(){

        // console.log('this.props.list:', this.props.list);

        const randomHeights = Array(10)
                .fill(true)
                .map(() => Math.round(Math.random() * 14) + 1)

        return (
            <Virtuoso
                data={this.props.list}
                itemContent={(index: any, item: any) => (
                    <div>
                        <div
                            onClick={() => {this.props.getSelectedItem(item)}}
                            className='custom-list-item clickable'
                            // Add border radius to first and last items (this has to be done due to issues with container bordering not working)
                            style={{ borderRadius:  index == 0 ? 
                                                        '4px 4px 0px 0px' 
                                                    :  index == this.props.list.length-1 ?
                                                        '0px 0px 4px 4px'
                                                    :  '0px',
                                    borderTop: index == 0 ? '1px solid var(--card-border)' : '',
                                    borderBottom: index == this.props.list.length-1  ? '1px solid var(--card-border)' : '',
                            }}
                        >

                            <div className='number-box' 
                                style={{
                                    display: this.props.displayNumberField != null ? 'block' : 'none', 
                                    marginLeft: '12px'
                                }}
                            >
                                <div className="number">
                                    {item[this.props.displayNumberField != null ? this.props.displayNumberField : '']}
                                </div>
                            </div>

                            <div style={{borderBottom: index != this.props.list.length - 1 ? '1px solid var(--seperator-1)' : '0px', display: 'flex', width: '100%', paddingBottom: '19px', paddingTop: '19px', marginRight: '12px', marginLeft: '12px', alignItems: 'center'}}>
                                <IonLabel 
                                    className="ion-text-wrap" 
                                    style={{width: '100%'}}
                                >
                                    <div 
                                        className={this.props.boldFont ? "listItemTextBold" : "listItemText"}
                                        style={{
                                            paddingTop: this.props.removeTopAndBottomPaddingFromMainText ? '0px' : '12px',
                                            paddingBottom: this.props.removeTopAndBottomPaddingFromMainText ? '0px' : '12px'
                                        }}
                                    >
                                        {item[this.props.displayTextField]}
                                    </div>
                                    {item['subtitle'] != null ?
                                    <div className='listSubtitle'>
                                        {item['subtitle']}
                                    </div> : ''
                                    }
                                    {item[this.props.tuneHymns] != null ?
                                        item[this.props.tuneHymns].map((hymnNo: any) => {

                                            return <div className='number-box-small'>
                                                        <div className="number-small">
                                                            {hymnNo}
                                                        </div>
                                                    </div>

                                        })
                                    :   ''
                                    }
                                </IonLabel>
                                <div style={{fontSize: '14px', color: this.props.detailColor != null ? this.props.detailColor : 'var(--seperator-1)', width: '44px', textAlign: 'right'}}>
                                    <IonIcon icon={this.props.detailIcon} />
                                </div>
                            </div>
                        </div>    
                    </div>  

                )}
                // The code below can be uncommented to display a temporary shape as the list scrolls at a fast pace
                // components={{
                //     // You can use index to randomize
                //     // and make the placeholder list more organic.
                //     // the height passed is the one measured for the real item. 
                //     // the placeholder should be the same size.
                //     ScrollSeekPlaceholder: ({ height, index }) => (
                //       <div
                //         style={{
                //           height,
                //           padding: "8px",
                //           boxSizing: "border-box",
                //           overflow: "hidden",
                //         }}
                //       >
                //         <div
                //           style={{
                //             background: index % 2 ? "var(--footer-border)": "white",
                //             height: randomHeights[index % 10],
                //           }}
                //         ></div>
                //       </div>
                //     ),
                //   }}
                //   scrollSeekConfiguration={{
                //     enter: (velocity) => Math.abs(velocity) > 750,
                //     exit: (velocity) => {
                //       const shouldExit = Math.abs(velocity) < 10;
                //       if (shouldExit) {
                //         this.setState({visibleRange: ["-", "-"]});
                //       }
                //       return shouldExit;
                //     },
                //     change: (_velocity, { startIndex, endIndex }) => this.setState({visibleRange: [startIndex, endIndex]})
                //   }}
            />
        
        )

    }

}

export default withIonLifeCycle(Listv2);