import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonModal, IonNote, IonPage, IonSearchbar, IonTitle, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { Component, createRef } from 'react';
import { menuController } from '@ionic/core/components';
import { withRouter } from 'react-router';
import { addOutline, bookSharp, chevronDownOutline, chevronForwardOutline, chevronUpOutline, menuOutline, searchOutline } from "ionicons/icons";
import './dashboard.css';

import Subscriptionsv2 from "../../pages/subscriptions/subscriptionsv2";

import browseMenuIcon from '../../theme/assets/Menu_Browse@3x.png';
import infoMenuIcon from '../../theme/assets/Menu_Info@3x.png';
import subscriptionsMenuIcon from '../../theme/assets/Menu_Subscriptions@3x.png';
import supportMenuIcon from '../../theme/assets/Menu_Support@3x.png';

import DataController  from '../../controllers/DataController';
import HOSController from '../../controllers/HOSController';
import DataQuerying from '../../data/DataQuerying';
import { format, compareAsc, parseISO } from 'date-fns'

import searchIcon from '../../theme/assets/Button_36pt_Search@3x.png'
import rightChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Right.svg'
import upChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Up.svg'
import downChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Down.svg'
import menuIcon from '../../theme/assets/svgs/Button_Menu.svg'
import addIcon from '../../theme/assets/svgs/Button_Add_Sepia.svg'
import hymnsLogo from '../../theme/assets/Logo_HymnsAM@3x.png'
import AuthController from '../../controllers/AuthController';

import { Plugins, StatusBarStyle } from '@capacitor/core';
const { Device } = Plugins;


type Props = {  history: any, 
                setTabs: any, 
                airTableData: any, 
                setLoginStatus: any, 
                setValidSubscriptionStatus: any, 
                validateSubscriptionFromServer: any, 
                validateUserAccess: any, 
                sendSupportEmail: any, 
                latestSubscription: any, 
                hasValidSubscription: any,
                products: any,
                setHasCheckedServerAlready: any,
                updateDashboardModalStates: any,
                openSubscriptionModal: any
             }

type State = {  searchText: any, 
                showToast: boolean, 
                showModalMenu: boolean,  
                myLists: {
                  name: string,
                  date: Date | null,
                  creationDate: Date,
                  isOpen: boolean,
                  hymnsAdded: any
                }[] | any,
                bookmarks: any,
                HOS: any,
                upcomingSunday: any,     
                signInDropdownIsOpen: any,
                subscriptionOptionsIsOpen: any,
                subscriptionInfoIsOpen: any,
                showAboutApp: any
             }

class Dashboard extends Component<Props,State> {

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  dataQuerying: DataQuerying = new DataQuerying();

  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
      showToast: false,
      showModalMenu: false,
      myLists: [],
      bookmarks: [],
      HOS: [],
      upcomingSunday: '',
      signInDropdownIsOpen: false,
      subscriptionOptionsIsOpen: true,
      subscriptionInfoIsOpen: true,
      showAboutApp: false
    }

  }

  async ionViewWillEnter() {

    this.props.setTabs('home', false);
    await this.loadMyLists();
    await this.loadHOS();
    await this.loadBookmarks();

  }

  async loadHOS() {

    // Start a background task which will try to download the HOS data from the server into localStorage,
    // then load the upcoming Sunday's hymns into state
    HOSController.downloadHOSIntoStorage().then(async () => {

      const nextSunday = HOSController.getNextSunday(new Date());
      const nextSundayHymnNos = await HOSController.getNextSundaysHymns(nextSunday);

      const nextSundayHymns = nextSundayHymnNos?.map((hymnNo: any) => {
        return this.dataQuerying.findHymn(this.props.airTableData, hymnNo)
      })
      this.setState({
        HOS: nextSundayHymns != null ? nextSundayHymns : [],
        upcomingSunday: nextSunday
      })

      // console.log("nextSundayHymnNos:", nextSundayHymnNos, "after loading HOS");

    });


    // Whilst the task above is completing, load the HOS data which already exists from localStorage into state so the user isn't
    // waiting for the server to return the data
    const nextSunday = HOSController.getNextSunday(new Date());
    const nextSundayHymnNos = await HOSController.getNextSundaysHymns(nextSunday);

    // console.log('nextSundayHymnNos', nextSundayHymnNos);

    const nextSundayHymns = nextSundayHymnNos?.map((hymnNo: any) => {
      return this.dataQuerying.findHymn(this.props.airTableData, hymnNo)
    })

    this.setState({
      HOS: nextSundayHymns != null ? nextSundayHymns : [],
      upcomingSunday: nextSunday
    })

  }

  async loadMyLists() {

    DataController.getAllMyLists(true).then((myLists:any) => {

      this.setState({myLists: myLists})

    })

  }

  async loadBookmarks() {

    const bookmarks = await DataController.getBookmarks();
    this.setState({
      bookmarks: bookmarks
    })

  }

  setSearchText(searchText: string) {

    const currDir = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/')+1,
      window.location.pathname.length
    )

    if(currDir == 'home'){
      this.props.history.push('hymn-number', {hymns: 'default', title: ''});
    }

  }

  navigateToListHymn(listIndex: any, hymnIndexInList: any) {

    const list = this.state.myLists[listIndex];
    const hymns = list['hymnsAdded'];
    const hymn = hymns[hymnIndexInList];

    this.props.history.push(`hymn/${hymn['hymnNo']}`, {   
      title:  list['name'], 
      hymnList: hymns.map((currentHymn: any) => currentHymn['hymnNo']), selectedHymn: hymn['hymnNo']
    })    

  }
  
  async logout() {

    await AuthController.logout(); 
    this.props.setLoginStatus(false); 
    DataController.removeSubscriptionData();
    await this.props.validateUserAccess();
    this.setState({
      showModalMenu: false
    })
    this.props.history.replace('./home');

  }

  render() {

    return (
      <IonPage>

        <IonHeader>
          <IonToolbar>
            <IonButtons onClick={() => {this.setState({showModalMenu: true})}} style={{paddingLeft: '12px'}} slot="start">
              <IonButton>
                  <IonIcon icon={menuIcon}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>{`Hymns A&M`}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* Code below is for when the model box menu is open */}
        <IonModal 
          isOpen={this.state.showModalMenu} 
          mode='ios'
        >
          <div className="modal-custom-css">

            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => {this.setState({showModalMenu: false})}}>
                    <div className="backButtonTxt">
                      Close
                    </div>  
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            {/* <IonContent scrollY={false} style={{'--background': 'var(--content-background)'}}> */}
              <div className="contentContainer">
                <div className="content content-border">
                  <IonList>
                    <IonItem 
                      button 
                      key='menuOp1'
                      detail={false}
                      className="clickable"
                      onClick={() => {
                        this.props.history.push('hymn-number',{hymns: 'default', title: ''});
                        this.setState({showModalMenu: false}); 
                      }
                    }>
                      <img src={browseMenuIcon} height="44" width="44" />
                      <span className="menuListText">Browse Hymns</span>
                    </IonItem>
                    <IonItem
                      button 
                      key='menuOp2'
                      detail={false}
                      className="clickable"
                      onClick={() => this.setState({showAboutApp: true, showModalMenu: false})}
                    >
                      <img src={infoMenuIcon} height="44" width="44" />
                      <span className="menuListText">About the app</span>
                    </IonItem>
                    <IonItem 
                      button 
                      key='menuOp3'
                      detail={false}
                      className="clickable"
                      onClick={() => {
                        this.setState({showModalMenu: false});
                        this.props.sendSupportEmail('Subscription Support');
                      }
                    }>
                      <img src={supportMenuIcon} height="44" width="44" />
                      <span className="menuListText">Contact support</span>
                    </IonItem>
                    <IonItem 
                      button 
                      key='menuOp4'
                      detail={false}
                      className="clickable"
                      onClick={() => {
                        this.props.openSubscriptionModal(false, true, true);             
                        this.setState({showModalMenu: false})
                      }}>
                      <img src={subscriptionsMenuIcon} height="44" width="44" />
                      <span className="menuListText">Account & Subscriptions</span>
                    </IonItem>
                  </IonList>
                </div>
              </div>
            {/* </IonContent> */}
          </div>
        </IonModal>

        <IonModal className='full-screen-modal'  isOpen={this.state.showAboutApp}>
          <div className="modal-custom-css" style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <IonHeader>
              <IonToolbar>
                  <IonButtons onClick={() => {this.setState({showAboutApp: false})}} style={{paddingLeft: '12px'}} slot="end">
                    <IonButton>
                        <div className="backButtonTxt">
                          Close
                        </div>
                    </IonButton>
                  </IonButtons>
                  <IonTitle>{`About the app`}</IonTitle>
              </IonToolbar>
            </IonHeader>

            <div style={{'background': 'var(--content-background)', height: '95%'}}>

              <div className="contentContainer" style={{paddingBottom: '16px', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%'}}>          
                  <div className="content content-border">
                    <div className="dashboard-container" style={{paddingBottom: '16px'}}>
                      <div className="aboutTheAppHeader">
                        About the app
                      </div>
                      <div className="howToUseSubHeader">
                        How to use Hymns Ancient & Modern
                      </div>

                      <div className="seperator" style={{width: '159px', marginTop: '16px', marginBottom: '16px'}}/>

                      <div className="aboutAppText">
                        The Hymns Ancient & Modern app allows you to explore the world's most famous hymn book on your phone, tablet or laptop.
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        Find words for all 847 hymns and songs found in <span style={{fontStyle: 'italic'}}>Ancient & Modern (2013)</span>
                      </div>
                      <div className="aboutAppText">
                        Adjust the hymn text size, see suggested hymn tunes, and check copyright information.
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        Use the search bar to find hymns by number or first line
                      </div>
                      <div className="aboutAppText">
                        Hymn numbers match those in print editions of <span style={{fontStyle: 'italic'}}>Ancient & Modern (2013).</span>
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        Browse the collection by theme, lectionary reading, book of the Bible, author, or composer
                      </div>
                      <div className="aboutAppText">
                        Unsure of the first line? Go to Browse for more ways to find the right hymn.
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        Create, edit and manage your own lists of hymns
                      </div>
                      <div className="aboutAppText">
                        Sync your lists across devices by signing into the app with your Hymns Ancient & Modern account. Go to the main menu and select "Account".
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        About <span style={{fontStyle: 'italic'}}>Ancient & Modern: Hymns and songs for refreshing worship</span>
                      </div>
                      <div className="aboutAppText">
                        Published in 2013, the latest edition of Hymns Ancient & Modern contains over 840 items, ranging from Psalm settings to songs by John Bell, Bernadette Farrell, Stuart Townend and others.
                      </div>
                      <div className="aboutAppText">
                        It includes hymns for all the seasons of the Church year, new items for carol services, songs for all-age worship, fresh translations of some ancient hymnody, short songs and chants, and music from the world church.
                      </div>
                      <div className="aboutAppText">
                        Print editions of the hymn book are available from <span><a className='canterburyPressLink' href="http://www.canterburypress.co.uk">Canterbury Press</a></span>.
                      </div>
                      <div className="aboutAppText">
                        To copy hymn texts (for projection or to add to a service sheet) purchase the Electronic Words edition from Canterbury Press. Hymn texts cannot be copied from the Hymns Ancient & Modern app.
                      </div>
                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        PRIVACY POLICY
                      </div>
                      <div className="aboutAppText">
                        <a className="tosAndPrivacyLink" target='blank' href="https://myaccount.hymnsam.co.uk/privacy-policy/">https://myaccount.hymnsam.co.uk/privacy-policy/</a>
                      </div>

                      <div className="aboutAppText" style={{fontWeight: 600}}>
                        TERMS OF USE
                      </div>
                      <div className="aboutAppText">
                        <a className="tosAndPrivacyLink" target='blank' href="https://myaccount.hymnsam.co.uk/terms-conditions/">https://myaccount.hymnsam.co.uk/terms-conditions</a>
                      </div>

                      <div className="aboutAppCopyrightText">
                        Copyright © Hymns Ancient & Modern Ltd.
                      </div>

                    </div>
                  </div>
                  <div className="logoContainer" style={{paddingTop: '16px', paddingBottom: '32px'}}>
                    <img src={hymnsLogo} width='126' height='49' />
                  </div>
              </div>
            </div>
          </div>
        </IonModal>

        {/* Code below is the default dashboard page (model box menu is closed) */}
        <IonContent style={{'--background': 'var(--content-background)'}} fullscreen>

          {/* Search button */}
          
          <div className="allContentContainer">
            <div className="contentContainer">
              <div className="content" style={{background: 'none'}}>
                <div className="searchBar clickable" onClick={() => {this.props.history.push('hymn-number', {hymns: 'default', title: '', cameFromDashboard: true})}}>
                  <img src={searchIcon} height='40' width='40' />
                  <span className="searchBarTxt">Search for hymns numbers or keywords</span>
                </div>
              </div>
            </div>

            {/* Hymns on Sunday */}

            <div className="contentContainer">          
              <div className="content content-border">
                <div className="dashboard-container">

                  <div className="sundayHymnTitle">
                    This Sunday's Hymns
                  </div>
                  <div className="sundayHymnDate">
                  {`${new Date(this.state.upcomingSunday).getDate()} ${this.months[new Date(this.state.upcomingSunday).getMonth()]} ${new Date(this.state.upcomingSunday).getFullYear()}`}
                  </div>

                  <div className="seperator" style={{width: '155px'}} />

                </div>

                <IonList style={{marginTop: '12px', marginBottom: '12px'}}>
                  {this.state.HOS.length == 0 ? 
                    <div className="noHymnsOnSunday">
                        Unable to load upcoming Sundays, please check your internet connection or try again later.
                      </div>
                    : null}
                  {this.state.HOS.map((hymn: any, index: any) => {
                    return <IonItem
                    button 
                    key={'HOS' + index}
                    detail={true}
                    style={{'--detail-icon-font-size': '17px'}}
                    onClick={() => {return this.props.history.push(`hymn/${hymn['Number']}`, {   
                      title:  'Hymns on Sunday', 
                      hymnList: this.state.HOS.map((hymn: any) => hymn['Number']),
                      selectedHymn: hymn['Number']
                    })}}
                    >
                    <IonNote 
                      slot='start'
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto'
                      }} 
                    >
                    <div className='number-box' style={{display: 'block'}}>
                        <div className="number">
                          {hymn['Number']}
                        </div>
                    </div>
                    </IonNote>
                    <p className="listItemText" style={{paddingTop: '12px', paddingBottom: '12px', paddingRight: '10px'}}>
                      {hymn['Name/First Line']}
                    </p>
                    </IonItem>
                  })}
                </IonList>
              </div>
            </div>

            {/* My lists */}
            <div className="contentContainer">  
              <div className="content content-border">
                <div className="dashboard-container">

                  <div className="myListsText">
                    <div className="myListsHeader">
                      My Lists
                    </div>

                    <IonButton color="secondary" className="smallBtn" onClick={() => this.props.history.push('mylists')}>
                      <div className="smallBtnText">
                        View All
                      </div>
                    </IonButton>
                  </div>

                  <div className="seperator" style={{width: '93px'}} />

                  <IonButton 
                    expand="block" 
                    className='createListBtn' 
                    color='tertiary'
                    onClick={() => this.props.history.push('create-or-modify-list')}
                  >
                    <div slot="start" className="createListBtnTxt">
                      <IonIcon style={{height: '15px'}} icon={addIcon}></IonIcon> Create new list
                    </div>
                  </IonButton>

                  <div hidden={this.state.myLists?.length == 0} className="seperator" style={{width: 'inherit'}} />

                </div>

                {this.state.myLists.map((currentList: any, listIndex: number) => {

                  return <div key={'myList' + listIndex}>
                    <div className="listsContent">

                      <div 
                        className="listHeader clickable"
                        onClick={() => {currentList['isOpen'] = !currentList['isOpen']; this.forceUpdate();}}
                      >
                        {currentList['name']}
                      </div>

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <IonButton 
                          color="secondary" 
                          className="smallBtn" 
                          onClick={() => this.props.history.push('create-or-modify-list', {creationDate: currentList['creationDate']})}
                        >
                          <div className="smallBtnText">
                            Edit
                          </div>
                        </IonButton>
                        <div className="listChevron clickable" style={{paddingLeft: '12px'}} onClick={() => {currentList['isOpen'] = !currentList['isOpen']; this.forceUpdate();}}>
                          <IonIcon icon={currentList['isOpen'] ? upChevronIcon : downChevronIcon} />
                        </div>
                      </div>

                    </div>

                    {currentList['date'] != '' ?
                      <div className="listDate">
                          {/* The Date object gets lost when stored as a JSON object, so the currentList date has to be converted back before using get methods*/}
                          {`${new Date(currentList['date']).getDate()} ${this.months[new Date(currentList['date']).getMonth()]} ${new Date(currentList['date']).getFullYear()}`}
                      </div>
                    : ''}

                    {currentList['isOpen'] ?
                      <IonList style={{marginTop: '12px', marginBottom: '12px'}}>
                        { currentList['hymnsAdded'] != null ?
                          currentList['hymnsAdded'].map((currentHymn: any, hymnIndexInList: any) => {       
                          return  <IonItem 
                                    button 
                                    key={'mylistopen' + currentHymn + hymnIndexInList}
                                    style={{'--detail-icon-font-size': '17px'}}
                                    onClick={() => this.navigateToListHymn(listIndex, hymnIndexInList)} detail={true}
                                  >
                                    <IonNote 
                                      slot='start'
                                      style={{
                                        marginTop: 'auto',
                                        marginBottom: 'auto'
                                      }}
                                    >
                                      <div className='number-box' style={{display: 'block'}}>
                                          <div className="number">
                                            {currentHymn['hymnNo']}
                                          </div>
                                      </div>
                                    </IonNote>
                                    <p className="listItemText" style={{paddingTop: '12px', paddingBottom: '12px', paddingRight: '10px'}}>
                                      {currentHymn['firstLine']}
                                    </p>
                                  </IonItem>
                        })
                        : ''}

                      </IonList>

                    : <IonList style={{marginTop: '16px', marginBottom: '16px'}}>
                        <IonItem key={'mylistsclosed'}>
                          { currentList['hymnsAdded'] != null ?
                            currentList['hymnsAdded'].map((currentHymn: any, hymnIndexInList: any) => {  
                            return  <div key={currentHymn + hymnIndexInList} className='number-box-minimized clickable' style={{display: 'block'}} onClick={() => this.navigateToListHymn(listIndex, hymnIndexInList)}>
                                      <div className="number">
                                        {currentHymn['hymnNo']}
                                      </div>
                                    </div>
                          })
                          : ''}
                        </IonItem>
    
                      </IonList>
                    }
                    {listIndex !== this.state.myLists.length - 1 ?
                      <div className="seperator" style={{width: 'inherit', marginLeft: '12px', marginRight: '12px'}} />
                    : ''
                    }
                  </div>
                })}
              </div>
            </div>

            <div className="contentContainer" style={{paddingBottom: '15px'}}>  
              <div className="content content-border">
                <div className="dashboard-container">

                  <div className="myListsText">

                    <div className="myListsHeader">
                      Bookmarks
                    </div>

                    <IonButton disabled={this.state.bookmarks.length == 0} color="secondary" className="smallBtn" onClick={() => this.props.history.push('my-bookmarks')}>
                      <div className="smallBtnText">
                        View All
                      </div>
                    </IonButton>
                  </div>

                  <div className="seperator" style={{width: '130px'}} />
                </div>

                {this.state.bookmarks.length > 0 ?

                  <IonList style={{marginTop: '8px', marginBottom: '12px'}}>

                    {this.state.bookmarks.map((hymn: any, index: any) => {

                      return <IonItem 
                        button 
                        key={'bookmarks' + hymn + index}
                        style={{'--detail-icon-font-size': '17px'}}
                        onClick={() => {return this.props.history.push(`hymn/${hymn['hymnNo']}`, {   
                          title:  'Bookmarks', 
                          hymnList: this.state.bookmarks.map((hymn: any) => hymn['hymnNo']),
                          selectedHymn: hymn['hymnNo']
                        })}}
                        detail={true}
                      >
                        <IonNote 
                          slot='start'
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto'
                          }}
                        >
                          <div className='number-box' style={{display: 'block'}}>
                              <div className="number">
                                {hymn['hymnNo']}
                              </div>
                          </div>
                        </IonNote>
                        <p className="listItemText" style={{paddingTop: '12px', paddingBottom: '12px'}}>
                          {hymn['firstLine']}
                        </p>
                      </IonItem>

                    })}

                  </IonList>
                : <p className="listItemText" style={{textAlign: 'center', paddingTop: '12px', paddingBottom: '24px'}}>No bookmarks have been added yet</p>
                }

              </div>
            </div>

            <IonToast
              color="dark"
              isOpen={this.state.showToast}
              onDidDismiss={() => this.setState({showToast: false})}
              message="Page under construction"
              duration={2000}
            />

          </div>

        </IonContent>
      </IonPage>
    );

  }
};

export default withRouter(withIonLifeCycle(Dashboard));
