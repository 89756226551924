import { isPlatform } from '@ionic/react';
import { format, parseISO } from 'date-fns';
import { request } from 'http';
import DataController  from './DataController';
var encodeUrl = require('encodeurl')

export class AuthController {

    // Returns true or false depending on whether the get request was successful
    async login(email: string, password: string) {

        try {

            const encodedEmail = encodeURIComponent(email);
            const encodedPassword = encodeURIComponent(password);
            
            const url = `https://hymns-api.hymnsam.co.uk/hymnsam/api/user?username=${encodedEmail}&password=${encodedPassword}`;

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                }
            });
    
            const responseJson = await response.json();

            if(responseJson.ResponseMessage === 'Success' && responseJson.Token !== null) {
        
                let user = {
                    email: email,
                    token: responseJson.Token
                };

                await DataController.setValue('user', user);
                return true;

            } else {
                return false;
            }

        } catch(error) {
            console.log("error logging in", error)
            return false;
        }

    }

    async subscriptionIsDirectlyFromServer() {

        const latestSubscription = await DataController.getSubscription();

        if (latestSubscription['isDirectlyFromServer']){     
            return true;
        } else {
            return false;
        }
    }

    async logout() {

        await DataController.removeValue('user');

        const subscriptionIsDirectlyFromServer = await this.subscriptionIsDirectlyFromServer()

        // Only remove subscription if it was directly taken from the hymns server, or the user is logging out of the desktop version
        if (subscriptionIsDirectlyFromServer || isPlatform('desktop')){ 
            await DataController.removeValue('subscription');
        }
    }

    // Returns array of subscriptions or empty array 
    async getSubscriptionsFromHymns(email: String, token: String) {

        try {

            const url = `https://hymns-api.hymnsam.co.uk/hymnsam/api/subscription?token=${token}&publicationid=39`;

            const hymnsPublicationId = 39;

            const body = {
                Email: email,
                Token: token
            }

            const response = await fetch(url, {
                method: 'GET',
            });
    
            let responseJson;

            responseJson = await response.json();

            // console.log('responseJSON getSubscriptions:', JSON.stringify(responseJson, null, 2));

            if (responseJson.ResponseMessage === 'Success') {

                if (responseJson.Subscriptions && responseJson.Subscriptions.length > 0) {
                    
                    const subs = responseJson.Subscriptions.filter((_subs: any) => _subs.PublicationId === hymnsPublicationId)
                    return subs;
                
                } else {
                    return [];
                }
            } else {
                return [];
            }

        } catch(error) {
            console.log("Error getting subscriptions from server", error)
        }

    }

    getLatestEndingHymnsSubscription(subscriptions: any) {
    
        // If the subscriptions are null, undefined or an empty array just return false
        if (subscriptions === null || subscriptions === undefined || subscriptions === []) {
          return false;
        }

        const hymnsSubscriptions = subscriptions;
    
        if (hymnsSubscriptions.length > 0) {

            return hymnsSubscriptions.reduce((a: any, b: any) => {
                return a.ExpiryDate > b.ExpiryDate ? a : b;
            });

        }
    }

    async writeSubscriptionFromStorageToServer(userToken: string, hymnsServerSubscriptions: any) {

        // Get subscription from storage
        const storageSubscription = await DataController.getSubscription();
        console.log('storageSubscription:', JSON.stringify(storageSubscription, null, 2));

        if (storageSubscription){
            
            const formattedStorageStartDate = format(new Date(storageSubscription['startDate']), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
            const formattedstorageEndDate = format(new Date(storageSubscription['expiryDate']), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

            const storageProductId = storageSubscription['productId'];
            const storageTransactionId = storageSubscription['transactionId'];
            const storageIsTrial = storageSubscription['isTrial'];

            let hymnsSubscriptionId;

            // Check to see if there is already a subscription with the same productId as the one in storage (e.g. com.hymnsam.hymnsancientmodern.month.android)
            const productIdAlreadyExistsOnServer = hymnsServerSubscriptions.some((sub: any) => {

                const additionalAppData = JSON.parse(sub['AdditionalAppData']);
                if (additionalAppData['bid'] == storageProductId) { 
                    hymnsSubscriptionId = sub['SubscriptionId']; 
                    return true 
                }
                else { return false };
            });

            // If there is already a subscription with the same productID
            if (productIdAlreadyExistsOnServer) {

                // Overwrite the subscription in hymns server with the one from storage using SubscriptionID
                const request = encodeUrl(`https://hymns-api.hymnsam.co.uk/hymnsam/api/subscription?token=${userToken}&startdate=${formattedStorageStartDate}&expirydate=${formattedstorageEndDate}&publicationid=39&createdbyid=1&additionalAppData={"transactionId":"${storageTransactionId}","bid":"${storageProductId}","isTrial":"${storageIsTrial}"}&subscriptionId=${hymnsSubscriptionId}&statusid=1`)
                .replace(/\+/g, '%2B');

                console.log('productIdAlreadyExistsOnServer:', request);

                await fetch(request, {
                    method: 'POST',
                }).then(async (response: any) => {

                    if (response.ok) {
                        const responseJSON = await response.json();

                    } else {
                        console.log('Error from server:', JSON.stringify(response, null, 2));
                    }

                });

            } else {

                // Create new subscription in hymns using the one from storage without SubscriptionID
                const request = encodeUrl(`https://hymns-api.hymnsam.co.uk/hymnsam/api/subscription?token=${userToken}&startdate=${formattedStorageStartDate}&expirydate=${formattedstorageEndDate}&publicationid=39&createdbyid=2&additionalAppData={"transactionId":"${storageTransactionId}","bid":"${storageProductId}","isTrial":"${storageIsTrial}"}`)
                .replace(/\+/g, '%2B');

                console.log('Request:', request)

                await fetch(request, {
                    method: 'POST',
                }).then(async (response: any) => {

                    if( response.ok) {

                        const responseJSON = await response.json();
                        // console.log('Response from server:', JSON.stringify(responseJSON, null, 2));

                    } else {
                        console.log('Error from server:', JSON.stringify(response, null, 2));
                    }

                });

            }

        } else {
            
            console.log(`User does NOT have an active subscription in storage to save on the server`);

        }

    }

    async getUser() {

        const user = await DataController.getValue('user');

        if(user != null) {
            return user;
        } else {
            return false;
        }

    }

    getReceiptFromSubscription(subscription: any) {

        if (subscription) {
            const additionalAppData = subscription['additionalAppData'];
            const receipt = additionalAppData['receipt']
            return receipt;
        } else {
            return false;
        }
        
    }

    async sendPurchaseInfo(token: string, formattedStartDate: any, formattedEndDate: any, additional: any,) {

        // const url = `https://rest.hymnsam.co.uk/api/subscription?token=${token}&startdate=${formattedStartDate}&expirydate=${formattedEndDate}&publicationid=36&createdbyid=1&additionalAppData=${JSON.stringify(additional)}`;

    }

    async isLoggedIn() {

        const user = await DataController.getValue('user');

        if(user != null){
            return true;
        } else {
            return false;
        }

    }

}

export default new AuthController();