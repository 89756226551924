import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonPage,
  IonPicker,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonPicker,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
  withIonLifeCycle,
} from "@ionic/react";
import {
  PickerButton,
  PickerColumn,
  PickerOptions,
  pickerController,
} from '@ionic/core/components';
import { defineCustomElement } from '@ionic/core/components/ion-picker.js';
import React, { useCallback } from 'react';

import {
  chevronBack,
  chevronBackOutline,
  chevronForwardOutline,
  searchOutline,
} from "ionicons/icons";
import { Component, createRef, useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router";
import Listv2 from "../../../components/Listv2";

import "./lists.css";
import searchIcon from "../../../theme/assets/svgs/button-36-pt-search.svg";
import buttonDropdown from "../../../theme/assets/Button_Dropdown@3x.png";
import searchIconWhite from "../../../theme/assets/Button_Search_White@3x.png";
import leftChevronIcon from "../../../theme/assets/svgs/Button_Disclosure_Back.svg";
import rightChevronIcon from "../../../theme/assets/svgs/Button_Disclosure_Right.svg";
import buttonRadioActive from "../../../theme/assets/Button_Radio_Active@3x.png";
import buttonRadioInactive from "../../../theme/assets/Button_Radio_Inactive@3x.png";

import DataQuerying from "../../../data/DataQuerying";

// type Props = { hymnNumberList: any, bibleBookList: any, history: any, setTabs: any }
// type State = { searchText: any, filteredList: any, showSearch: any, filterByBookModalIsOpen: any }

const HymnNumberList: React.FC<{
  hymnNumberList: any;
  airTableData: any;
  bibleBookList: any;
  setTabs: any;
}> = ({ hymnNumberList, airTableData, bibleBookList, setTabs }) => {
  let history: any = useHistory();

  let searchBar: any = useRef();
  // let items: any = [];

  let dataQuerying: DataQuerying = new DataQuerying();

  let pageRef: any = useRef();

  let picker: any;

  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [showSearch, setShowSearch] = useState(true);

  const [pickerOpen, setPickerOpen]: any = useState(false);
  const [items, setItems]: any = useState([]);

  const [bookNames, setBookNames]: any = useState([]);
  const [bookSelection, setBookSelection]: any = useState('All');

  const [chapterNumbers, setChapterNumbers]: any = useState([]);
  const [chapterSelection, setChapterSelection]: any = useState('All');

  const [verseNumbers, setVerseNumbers]: any = useState([]);
  const [verseSelection, setVerseSelection]: any = useState('All');

  useEffect(() => {
    setChapterNumbers(['All', ...dataQuerying.getAllPossibleChapterNos(airTableData)]);
    const bookNamesTemp = bibleBookList.map((book: any, index: any) => {
      return book.text;
    });

    setVerseNumbers(['All', ...dataQuerying.getAllPossibleVerseNos(airTableData)]);
    setBookNames(['All', ...bookNamesTemp]);

  }, [])

  useEffect(() => {

    console.log('Set browse tab to active')

    let tempFilteredList;
    if (history.location.state == null) {

      tempFilteredList = hymnNumberList;

    } else {
      
      if (history.location.state.hymns == "default") {

        tempFilteredList = hymnNumberList;

      } else {

        tempFilteredList = hymnNumberList.filter((currentHymn: any) => {
          return history.location.state.hymns.includes(
            currentHymn["number"]
          );
        })
      }

    }

    setFilteredList(tempFilteredList);
    setItems(tempFilteredList);

    return () => {
      console.log('Component destroyed');
      hymnNumberList.forEach((hymn: any) => {
        hymn['subtitle'] = undefined;
      });
      setFilteredList(hymnNumberList);
      setItems(hymnNumberList);
    }

  }, []);

  useIonViewWillEnter(() => {
    setTabs("browse", false);

    // Maybe check the value of a history.state variable to determine whether or not to reset filteredList and items to default hymnNumberList
  });

  useEffect(() => {
    // If the user came from dashboard, make the search bar focused - has to be in ionViewDidEnter otherwise transition animation into page will be faulty
    if (history.location?.state?.cameFromDashboard === true) {
      searchBar.current?.setFocus();
    }
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const getSelectedItem = (selectedItem: any) => {
    history.push(`/hymn/${selectedItem["number"]}`, {
      title: history.location.state != null ? history.location.state.title : "",
      hymnList: filteredList.map((item: any) => item["number"]),
      selectedHymn: selectedItem["number"],
    });
  };

  const placeholder = "Please enter hymn number or first line";
  const compareField = "compareField";
  const displayNumberField = "number";
  const displayTextField = "text";
  const bibleRefField = "bibleRefs";

  // Adjust displays items based on search text
  useEffect(() => {

    if(filteredList != null) {

      if(searchText != "") {
        const list = filteredList.filter((listItem: any) => {
            //****** Filter by search term ******/
            // Convert the list item to lower case along with the search term to avoid missing any matching items
            // because of incorrect casing (e.g. searching for 'christ' wouldn't return hymns with 'Christ' in the name otherwise)
            // also convert to string as the default type for things like hymn number is number, otherwise 'includes()' won't work

            // ** NEED TO ADD ** use ternary operator to check for null
            return listItem[compareField]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase());
        });

        setItems(list);
          

      } else {
        setItems(filteredList);
      }
    } else {
      setItems([]);
    }

  }, [searchText, filteredList]);

  useEffect(() => {

  // If we're viewing the biblical index page, we will have to add additional properties to the list of hymns
  // These are subtitle and order, used to display the bible refs under the hymn, and sort them by the order they appear in the book, respectively
  if (history.location.state != null) {
    if (
      history.location.state.showBibleBookName != null &&
      history.location.state.showBibleBookName === true
    ) {
      configureBibleRefs();
    } else {
      items.sort((a: any, b: any) => {
        return a["number"] - b["number"];
      });
    }
  } else {
    items.sort((a: any, b: any) => {
      return a["number"] - b["number"];
    });
  }

}, /*[bookSelection, chapterSelection, verseSelection]*/);

const configureBibleRefs = () => {

  items.forEach((hymn: any) => {

    if(hymn["bibleRefs"] != null) {

      // Set the initial subtitle
      hymn['subtitle'] = (
        hymn["bibleRefs"].map((bibleRefRecord: any) => {

          const bibleRef = bibleRefRecord["BibleRef"];
          const chapterNo = dataQuerying.getChapterNoFromBibleRef(bibleRef);
          const verseNos = dataQuerying.getVerseNumbersFromBibleRef(bibleRef);

          if(verseNos != 0) {
            return `${bibleRefRecord["Book Name"]} ${chapterNo}.${verseNos}`;
          } else {
            return `${bibleRefRecord["Book Name"]} ${chapterNo}`;
          }
        }).filter((item: any, pos: any, self: any) => {
          // remove duplicate book names so we don't end up with a subtitle like ('Exodus, Genesis, Exodus')
          return self.indexOf(item) == pos;
        })
      );


      // Sort the bibleRefs in the subtitle by the bookSelection
      if(bookSelection !== 'All') {
        const selectedBookInSubtitle = hymn['subtitle'].find((subtitle: any) => {
          return subtitle.includes(bookSelection);
        });

        const selectedBookInSubtitleIndex = hymn['subtitle'].indexOf(selectedBookInSubtitle);

        if(selectedBookInSubtitle != null) {
          hymn['subtitle'].splice(selectedBookInSubtitleIndex, 1);
          hymn['subtitle'] = [selectedBookInSubtitle, ...hymn['subtitle']];
        }
      }

      // Gives us an 'x, y and z' format as opposed to just using .join(' and ') which would give us 'x and y and z'.
      const lastRef = hymn['subtitle'].pop();
      hymn['subtitle'] = hymn['subtitle'].length == 0 ? lastRef : `${hymn['subtitle'].join(', ')} and ${lastRef}`;

    }

    if(hymn["bibleRefs"] != null) {

      // Get the first bibleRef assosicated with hymn, and assign the 'order' property from the hymn's bible ref order field
      // hymn['order'] = hymn["bibleRefs"][0]["Order"];
      hymn['order'] = dataQuerying.calulateOrderOfHymnBasedOnBibleRef(hymn, bookSelection);
    }
  })

  // remove any hymns without bible
  items.filter((hymn: any) => {
    return hymn['bibleRefs'] != null;
  });

  // Now we've added the order property, we can sort the list of hymns by their book order.
  items.sort((a: any, b: any) => {
    return a["order"] > b["order"] ? 1 : -1;
  });

}

  let useBibleBookFilters = false;
  if (history.location.state != null) {
    if (
      history.location.state.useBibleBookFilters != null &&
      history.location.state.useBibleBookFilters === true
    ) {
      useBibleBookFilters = true;
    }
  }

  useEffect(() => {

    filterOnBookSelection();

    // Reset the chosen chapter and verse selections to 'All' to prevent outdated chapter and verse selections being used
    setChapterSelection('All');
    setVerseSelection('All');

  }, [bookSelection]);

  const filterOnBookSelection = () => {

    if(bookSelection != 'All') {
      const chapterNos: any = dataQuerying.getChaptersByBookName(airTableData, bookSelection)
      setChapterNumbers(['All', ...chapterNos]);

      if(chapterSelection != 'All') {
        const verseNos: any = dataQuerying.getVersesByChapterNo(airTableData, chapterSelection)
        setVerseNumbers(['All', ...verseNos]);
      } else {
        const verseNos: any = dataQuerying.getVersesByBookName(airTableData, bookSelection);
        setVerseNumbers(['All', ...verseNos]);
      }

      const updatedList = filteredList.filter((item: any) => {
        const bibleBookNames = item["bibleRefs"]?.map((bibleRefRecord: any) => {
          return bibleRefRecord["Book Name"];
        })

        return bibleBookNames?.includes(bookSelection);

      });

      setItems(updatedList);

    } else {
      setItems(filteredList);
      setChapterNumbers(['All', ...dataQuerying.getAllPossibleChapterNos(airTableData)]);
      setVerseNumbers(['All', ...dataQuerying.getAllPossibleVerseNos(airTableData)]);
    }

  }

  useEffect(() => {

    filterOnBookSelection();
    filterOnChapterSelection();

    setVerseSelection('All');

  }, [chapterSelection]);

  const filterOnChapterSelection = () => {

    if(chapterSelection != 'All') {

      let verseNos: any = []
      if(bookSelection != 'All') {
        verseNos = dataQuerying.getVersesByBookNameAndChapterNo(airTableData, bookSelection, chapterSelection)
      } else {
        verseNos = dataQuerying.getVersesByChapterNo(airTableData, chapterSelection)
      }

      setVerseNumbers(['All', ...verseNos]);

      // filter the tempItems variables to only include hymns with the selected book name and chapter number
      const updatedList = filteredList.filter((hymn: any) => {

        if(hymn["bibleRefs"] != null) {

          const bookNameAndChapterNos = hymn["bibleRefs"].map((bibleRefRecord: any) => {
            const bibleRef = bibleRefRecord["BibleRef"];

            const bookName = bibleRefRecord['Book Name']
            const chapterNo = parseInt(bibleRef.split(".")[1]);

            // If we have a specified bookSelection, we need to return both bookName and chapterNo so we can check if they match
            // the bookSelection and chapterSelection. If no bookSelection is specified, we only need to check if the chapterNo matches
            if(bookSelection != 'All') {
              return bookName + chapterNo;
            } else {
              return chapterNo;
            }

          })

          if(bookSelection != 'All') {
            return bookNameAndChapterNos.includes(bookSelection + chapterSelection);
          } else {
            return bookNameAndChapterNos.includes(chapterSelection);
          }

        }

      });

      setItems(updatedList);

    }

  }

  useEffect(() => {

    filterOnBookSelection();
    filterOnChapterSelection();
    filterOnVerseSelection();

  }, [verseSelection]);

  const filterOnVerseSelection = () => {

    if(verseSelection != 'All') {

      // Loop through all of the hymns
      const updatedList = filteredList.filter((hymn: any) => {

        const verses: any = [];

        if(hymn["bibleRefs"] != null) {

          // Get the bookNames and chapterNos of each bibleRef in the hymn
          const bookNameAndChapterNos = hymn["bibleRefs"].map((bibleRefRecord: any) => {

            const bibleRef = bibleRefRecord["BibleRef"];

            const bookName = bibleRefRecord['Book Name'];
            const chapterNo = parseInt(bibleRef.split(".")[1]);

            // This basically ensures that whenever the bookSelection or chapterSelection are not 'All', that we only return hymns
            // with a bible ref which matches the bookSelection and chapterSelection.
            // if(bookName + chapterNo === bookSelection + chapterSelection) {
            if (  
                ((bookSelection != 'All' && bookName === bookSelection) &&
                (chapterSelection != 'All' && chapterNo === chapterSelection))

                ||

                ((bookSelection != 'All' && chapterSelection == 'All') &&
                (bookName === bookSelection))

                ||

                ((bookSelection == 'All' && chapterSelection != 'All') &&
                (chapterNo === chapterSelection))

                ||

                (bookSelection == 'All' && chapterSelection == 'All') 
               ) {
            
              const appearsAcrossMultipleChapters = bibleRef.includes('-');
              const bibleRefWithoutLetters = bibleRef.replace(/[a-zA-Z]/g, '');

              if(appearsAcrossMultipleChapters) {

                  const versesInThisChapter = bibleRefWithoutLetters.split('-');
                  const firstVerse = parseInt(versesInThisChapter[0].split('.')[2]);
                  const lastVerse = parseInt(versesInThisChapter[1].split('.')[2]);

                  for(let i = firstVerse; i <= lastVerse; i++) {
                      if(!verses.includes(i)) {
                          verses.push(i);
                      }
                  }

              } else {

                  const verseNo = parseInt(bibleRefWithoutLetters.split('.')[2]);
                  if(!verses.includes(verseNo)) {
                      verses.push(verseNo);
                  }
              }
            }


            if(bookSelection != 'All' && chapterSelection != 'All') {
              return bookName + chapterNo
            } else if(bookSelection != 'All' && chapterSelection == 'All') {
              return bookName
            } else if (bookSelection == 'All' && chapterSelection != 'All') {
              return chapterNo
            } else {
              return 'All'
            }

          })

          if(bookSelection != 'All' && chapterSelection != 'All') {
            return bookNameAndChapterNos.includes(bookSelection + chapterSelection) && verses.includes(parseInt(verseSelection));
          } else if(bookSelection != 'All' && chapterSelection == 'All') {
            return bookNameAndChapterNos.includes(bookSelection) && verses.includes(parseInt(verseSelection));
          } else if(bookSelection == 'All' && chapterSelection != 'All') {
            return bookNameAndChapterNos.includes(chapterSelection) && verses.includes(parseInt(verseSelection));
          } else {
            return verses.includes(parseInt(verseSelection));
          }

        }

      });

      setItems(updatedList);

    }

  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          {history.location.state?.headerTitle != null ?
          <IonTitle>{history.location.state.headerTitle}</IonTitle>
          : ''}
          <IonButtons slot="start">
            <IonButton onClick={() => goBack()}>
              <IonIcon style={{ height: "17px" }} icon={leftChevronIcon} />
              <div className="backButtonTxt">Back</div>
            </IonButton>
          </IonButtons>
          {() => {
            if (history.location.state != null) {
              if (
                history.location.state.showBibleBookName != null ||
                history.location.state.showBibleBookName !== true
              ) {
                return (
                  <IonButtons slot="end">
                    <IonButton onClick={() => {
                      setShowSearch(!showSearch)
                    }}>
                      <img src={searchIconWhite} height="44" width="44" />
                    </IonButton>
                  </IonButtons>
                )
              }
            } else {
              return (
                <IonButtons slot="end">
                  <IonButton onClick={() => {
                    setShowSearch(!showSearch)
                  }}>
                    <img src={searchIconWhite} height="44" width="44" />
                  </IonButton>
                </IonButtons>
              )
            }
          }}
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollY={false}
        style={{ "--background": "var(--content-background)" }}
      >
        <div
          className="allContentContainer flexChildFullHeight"
          id="allContentContainer"
          style={{ overflow: "hidden" }}
        >
          {showSearch && !useBibleBookFilters ? (
            <div>
              <div className="contentContainer">
                <div
                  className="content"
                  style={{ backgroundColor: "var(--content-background)" }}
                >
                  <div className="browseListTitle">
                    {history.location.state != null
                      ? history.location.state.title
                      : ""}
                  </div>
                  <IonSearchbar
                    searchIcon={searchIcon}
                    ref={searchBar}
                    value={searchText}
                    placeholder={placeholder}
                    onIonChange={(e) => setSearchText(e.detail.value!)}
                  ></IonSearchbar>
                  <div className="numberOfHymns">{items.length} Hymns</div>
                </div>
              </div>

              <div
                className="seperator"
                style={{ backgroundColor: "var(--footer-border)" }}
              />
            </div>
          ) : (
            ""
          )}

          {useBibleBookFilters ? (
            <div>
              <div className="contentContainer">
                <div
                  className="content"
                  style={{ backgroundColor: "var(--content-background)" }}
                >
                  <div
                    className="bibleBookFiltersContainer"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{ width: "50%" }}
                    >
                      <div className="bibleBookFilterTitle">Book</div>
                    </div>
                    
                    <div
                      style={{ width: "22.5%" }}
                    >
                      <div className="bibleBookFilterTitle">Chapter</div>
                    </div>

                    <div
                      style={{ width: "22.5%" }}
                    >
                      <div className="bibleBookFilterTitle">Verse</div>
                    </div>
                  </div>

                  <div
                    className="bibleBookFiltersContainer"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{ width: "50%" }}
                    >
                      <div className="bibleBookFilterDropdownContainer">
                        <IonSelect
                          value={bookSelection != null ? bookSelection : "All"}
                          interface="action-sheet"
                          onIonChange={e => {
                            setBookSelection(e.detail.value);
                            // applyBibleRefFilters();
                          }}
                        >
                          {bookNames.map((bookName: any) => {
                            return (
                              <IonSelectOption value={bookName}>{bookName}</IonSelectOption>
                            );
                          })}
                        </IonSelect>
                      </div>
                    </div>
                    
                    <div
                      style={{ width: "22.5%" }}
                    >
                        <div className="bibleBookFilterDropdownContainer">
                        <IonSelect 
                          value={chapterSelection != null ? chapterSelection : "All"}
                          interface="action-sheet"
                          onIonChange={(e) => setChapterSelection(e.detail.value)}
                        >
                          {chapterNumbers.map((chapterNo: any) => {
                            return <IonSelectOption value={chapterNo}>{chapterNo}</IonSelectOption>
                          })}
                        </IonSelect>
                        </div>
                    </div>
                    <div
                      style={{ width: "22.5%" }}
                    >
                      <div className="bibleBookFilterDropdownContainer">
                        <IonSelect 
                          value={verseSelection != null ? verseSelection : "All"}
                          interface="action-sheet"
                          onIonChange={(e) => setVerseSelection(e.detail.value)}
                        >
                          {verseNumbers.map((verseNo: any) => {
                            return <IonSelectOption value={verseNo}>{verseNo}</IonSelectOption>
                          })}
                        </IonSelect>
                      </div>

                    </div>
                  </div>
                  <div className="numberOfHymns">{items.length} Hymns</div>
                </div>
              </div>
              <div
                className="seperator"
                style={{ backgroundColor: "var(--footer-border)" }}
              />
            </div>
          ) : (
            ""
          )}

          <div className="contentContainer flexChildFullHeight">
            <div
              className="content flexChildFullHeight"
              style={{ backgroundColor: "transparent" }}
            >
              <Listv2
                list={items != null ? items : []}
                displayNumberField={displayNumberField}
                displayTextField={displayTextField}
                getSelectedItem={getSelectedItem.bind(this)}
                detailIcon={rightChevronIcon}
                boldFont={false}
                subtitle={"subtitle"}
                removeTopAndBottomPaddingFromMainText={true}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HymnNumberList;
