import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
  IonAlert,
  IonApp,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  withIonLifeCycle,
  isPlatform,
  IonBackdrop,
  IonRedirect,
  IonButton,
  IonModal
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import Dashboard from './pages/dashboard/dashboard';
import Browse from './pages/browse/browse';
import MyLists from './pages/mylists/mylists';
import Hymn from './pages/hymn/hymn';
import React, { Component} from 'react';

import { addDays, format, isAfter, isBefore } from 'date-fns';
import bookCover from './theme/assets/Image_BookCover@3x.png';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import browseTabIconActive from './theme/assets/TabBar_Browse_1Active@3x.png';
import browseTabIconInactive from './theme/assets/TabBar_Browse_2Inactive@3x.png';

import homeTabIconActive from './theme/assets/TabBar_Home_1Active@3x.png';
import homeTabIconInactive from './theme/assets/TabBar_Home_2Inactive@3x.png';

import myListsTabIconActive from './theme/assets/TabBar_MyLists_1Active@3x.png';
import myListsTabIconInactive from './theme/assets/TabBar_MyLists_2Inactive@3x.png';

import './App.css';

import {AirTableData, BrowseListItem} from './interfaces';
import HymnNumberList from './pages/browse/lists/hymn-number';
import BiblicalIndexList from './pages/browse/lists/biblical-index';
import CategoriesList from './pages/browse/lists/categories';
import CalendarOfSundaysList from './pages/browse/lists/calendar-of-sundays';
import ThematicIndexList from './pages/browse/lists/thematic-index';
import AuthorList from './pages/browse/lists/author';
import ComposerList from './pages/browse/lists/composer';
import TuneList from './pages/browse/lists/tune';
import author from './pages/browse/lists/author';
import BookmarksList from './pages/browse/lists/bookmarks';
import CreateOrModifyList from './pages/create-new-list/create-or-modify-list';
import AddHymns from './pages/create-new-list/addHymn(obsolete)';
import Subscriptionsv2 from './pages/subscriptions/subscriptionsv2';
import AboutTheApp  from './pages/about-the-app/about-the-app';

import AuthController from "./controllers/AuthController";
import DataController from "./controllers/DataController";
import DataQuerying from './data/DataQuerying';

import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import ManageAccount from './pages/manage-account/manage-account';
import { Contact } from './pages/contact/contact';

import { EmailComposer } from '@ionic-native/email-composer';
import  HOSController  from './controllers/HOSController';

import ResizeObserver from 'resize-observer-polyfill'

// import { InAppPurchase2, IAPProduct } from "@awesome-cordova-plugins/in-app-purchase-2";

// Required for Ionic 6 upgrade
import { setupIonicReact } from '@ionic/react';
import Picker from './components/Picker';
setupIonicReact({
  mode: 'md'
});

const { Device } = Plugins;
const { StatusBar } = Plugins;

const store = InAppPurchase2;


type Props = { } //screenProps: IScreenProps, features: IFeatures
type State = {
  tablesLoaded: boolean,
  tables: AirTableData['tables'] | null,
  hideTabs: boolean,
  activeTab: string,
  isLoggedIn: any,
  hasValidSubscription: any,
  latestSubscription: any,
  oneMonthIOSProduct: any,
  twelveMonthIOSProduct: any,
  oneMonthAndroidProduct: any,
  twelveMonthAndroidProduct: any,
  showTrialExpiredModal: any,
  showSubscriptionsModalByDefault: any
  subscriptionsSignInDropdownIsOpen: any
  subscriptionsOptionsDropdownIsOpen: any
  subscriptionsInfoDropdownIsOpen: any,
  isEligbleForFreeTrial: any
  hymnNumberList: any,
}

const _1monthAndroid = 'com.hymnsam.hymnsancientmodern.month.android';
const _12monthAndroid = 'com.hymnsam.hymnsancientmodern.year.android';
const _1monthIOS = 'com.hymnsam.hymnsancientmodern.month.ios';
const _12monthIOS = 'com.hymnsam.hymnsancientmodern.year.ios';

class App extends Component <Props, State> {

  dataQuerying: DataQuerying = new DataQuerying();
  hasCheckedServerAlready = false;

  // Retrieve data
  airtable = require('./data/airtable.json');

  // Tab switches
  tabs: any = React.createRef();
  homeIsActive = false;
  browseIsActive = false;
  myListsIsActive = false;
  hideTabs = false;

  count = 0;

  airTableData: any = {
    tables: this.airtable['json'],
  }

  constructor(props: Props) {
    
    super(props);

    this.state = {
      tablesLoaded: false,
      tables: null,
      hideTabs: false,
      activeTab: 'home',
      isLoggedIn: false,
      hasValidSubscription: true,
      latestSubscription: null,
      oneMonthIOSProduct: null,
      twelveMonthIOSProduct: null,
      oneMonthAndroidProduct: null,
      twelveMonthAndroidProduct: null,
      showTrialExpiredModal: false,
      showSubscriptionsModalByDefault: false,
      subscriptionsSignInDropdownIsOpen: isPlatform('desktop') ? true : false,
      subscriptionsOptionsDropdownIsOpen: true,
      subscriptionsInfoDropdownIsOpen: true,
      isEligbleForFreeTrial: false,
      hymnNumberList: this.airTableData.tables != null ? 
                      this.airTableData.tables['Hymns'] != null ? 
                      this.airTableData.tables['Hymns'].map((hymn: any) => {
                        return {
                          text: hymn['Name/First Line'], 
                          number: hymn['Number'], 
                          compareField: `${hymn['Number']} ${hymn['Name/First Line']} ${hymn['Bible Ref'] != null ? hymn['Bible Ref'].map((bibleRefRecordID: any) => {

                            const bibleRefRecord = this.dataQuerying.findLinkedRecordFields(this.airTableData, 'Bible Ref', bibleRefRecordID)
                            return bibleRefRecord['BibleRef'];

                          }).join(' ') : ''}`,
                          bibleRefs: hymn['Bible Ref'] != null ? hymn['Bible Ref'].map((bibleRefRecordID: any) => {

                            const bibleRefRecord = this.dataQuerying.findLinkedRecordFields(this.airTableData, 'Bible Ref', bibleRefRecordID)
                            return bibleRefRecord

                          }) : null
                        }
                      }) : [] : [],
    };

    /* For backwards compitability issues relating to virtuoso */
    // @ts-ignore
    if (!window['ResizeObserver']){
      // @ts-ignore
      window.ResizeObserver = ResizeObserver
    }

    // StatusBar.setBackgroundColor({ color: '#ffffff' })
    try{
      // Makes the status bar text at the top white
      StatusBar.setStyle({style: StatusBarStyle.Dark});
    } catch(error) {
      console.log('Error:', error);
    }

  }

  // Creates a tableRecords object with each table name as a key, and an array of records as the corresponding value
  async loadData() {

    const tableRecords = await this.airtable['json'];

    this.setState({
      tablesLoaded: true,
      tables: tableRecords
    })

    // Check version here
    return this.airtable['json'];

  }

  async validateUserAccess() {

    // Check that the subscription in local storage is still valid
    const storageSubscriptionIsInDate = await DataController.latestSubscriptionIsInDate();
    const latestSubscription = await DataController.getSubscription();

    const isLoggedIn = await AuthController.isLoggedIn();

    // User has a valid subscription in local storage
    if(storageSubscriptionIsInDate) {

      // Set the hasValidSubcription value to true which will allow access to the rest of the app routes
      this.setState({
        hasValidSubscription: true,
        latestSubscription: latestSubscription,
        isLoggedIn: isLoggedIn
      });
    } else {

      this.setState({
        hasValidSubscription: false,
        latestSubscription: latestSubscription,
        isLoggedIn: isLoggedIn
      })
    }

  }

  async setTrialEligibility() {

    if(isPlatform('desktop') && !this.state.isLoggedIn && !this.state.latestSubscription) {

      // user is not allowed a trial if they're on the web version and are NOT logged in
      this.setState({
        isEligbleForFreeTrial: false
      })

    }
    
    // If user is on web version and IS logged in, they may be entitled to a free trial
    if (isPlatform('desktop') && this.state.isLoggedIn && !this.state.latestSubscription) {

      const user = await AuthController.getUser();
      const subscriptionsFromHymnsServer = await AuthController.getSubscriptionsFromHymns(user.email, user.token);

      // Check to see whether they have any previous history of subscriptions on their hymns account, if they do, they're not eligible. If they don't, they are eligible
      if(subscriptionsFromHymnsServer.length > 0) {

        this.setState({
          isEligbleForFreeTrial: false
        })

      } else {
        this.setState({
          isEligbleForFreeTrial: true
        })
      }

    } 
    
    // If user is accessing the app from a mobile device and do NOT have any subscription data in storage they may be entitled to a trial, we need to check the server
    if(!isPlatform('desktop')) {

      let deviceInfo = await Device.getInfo();
      let uuid = deviceInfo.uuid;

      // Prepare POST body to send to server to validate receipt
      const body: any = { 
          UUID: uuid, 
          Product: 'com.hymnsam.hymnsancientmodern'
      };

      // Convert body to application/x-www-form-urlencoded format
      var formBody: any = [];
      for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      // Check the backend to see if the uuid has any trial data associated with it
      await fetch('https://hymns-api.hymnsam.co.uk/IAP/hasFreeTrailData', {
          method: 'POST',
          headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: formBody
      }).then(async (response: any) => {

          // Check response returned ok
          if (response.ok) {

            // Unpack response from server
            await response.json().then(async (jsonResponse: any) => {
                
                const trialInfo = jsonResponse['params'];
                const hasUsedFreeTrial = trialInfo['hasFreeTrial'] == 'true' || trialInfo['hasFreeTrial'] == true;

                this.setState({
                  isEligbleForFreeTrial: !hasUsedFreeTrial
                });

                // If user has already initiated their free trial
                if(hasUsedFreeTrial) {

                  const trialExpiryDate = new Date(trialInfo['TrialEndDate']);

                  // If there's no subscription in storage, write the trial data to storage (the trial may be expired but this will allow the app to show appropriate details about the expiry date)
                  if(!this.state.latestSubscription) {

                    await DataController.overwriteSubscription({
                      isDirectlyFromServer: false,
                      platform: 'unknown-hasFreeTrailData', // the API call doesn't return any info on the platform so we cannot tell which platform is was created on
                      startDate: trialExpiryDate,
                      expiryDate: trialExpiryDate,
                      transactionId: '0',
                      productId: trialInfo['Product'],
                      isTrial: true
                    })

                    await this.validateUserAccess(); // checks to see if the user is now allowed to access the app

                    // Otherwise, check to see if the trial expiry date is greater than the one in storage - this should never really be true as it'd mean user started a trial after purchasing a subscription
                    // but we may as well check anyway in case somehow this happens
                  } else {

                    const subscriptionInStorage = await DataController.getSubscription();
                    const subscriptionInStorageExpiryDate = new Date(subscriptionInStorage['expiryDate']);
                    const trialExpiryDate = new Date(trialInfo['TrialEndDate']);

                    if(isAfter(trialExpiryDate, subscriptionInStorageExpiryDate)) {

                      await DataController.overwriteSubscription({
                        isDirectlyFromServer: false,
                        platform: 'unknown-hasFreeTrailData', // the API call doesn't return any info on the platform so we cannot tell which platform is was created on
                        startDate: trialExpiryDate,
                        expiryDate: trialExpiryDate,
                        transactionId: '0',
                        productId: trialInfo['Product'],
                        isTrial: true
                      });

                      await this.validateUserAccess(); // checks to see if the user is now allowed to access the app

                    }

                  }

                }

                // console.log('isEligbleForFreeTrial', this.state.isEligbleForFreeTrial);

            });

          } else {
          console.log('Response not ok when checking trial info: ', response.statusText);
          }

      }).catch((error: any) => {

          console.log('Fetch error when checking trial info - ', error.message);

      });

    }

  }
  
  // Runs once when the app is first opened
  async componentDidMount() {

    // await DataController.clearStorage();

    /* The first thing the app does is check whether or not the subscription data in local storage is still valid. 
     * This allows the app to instantly give the user access without having to wait for calls to the server to return,
     * or for the IAP listeners to fire which may take extra time.
     */
    await this.validateUserAccess();

    // Load the content from the JSON so it's ready for when the user subscribes
    this.loadData();

    /* The next step involves registering and setting up the IAPs. The method calls below will instantiate listeners
     * which will give the app the ability to receive data from the app/play store. The data received in these methods
     * will allow the app to determine whether or not the user should have access to the rest of the app. As the app
     * uses listeners to receive data, it's difficult to determine when exactly the data will be received.
    */

    // Get the user's device info
    await Device.getInfo().then(async (info: any) => {

      // Make sure we're on a device first
      if (info.platform !== 'web') {

        // Registering IAP will make the app aware of what/where the subscriptions belonging to this app are
        this.registerIAP(info.platform);

        // Setting up IAP will instantiate the listeners which will make the app able to detect which subscriptions
        // belong to the account owner
        this.setupIAP();

      } else {
        info.appVersion = 'Web';
        info.appBuild = 'Web';
      }
    });


    await this.validateSubscriptionFromServer();
    await this.setTrialEligibility();

    this.updateDashboardModalStates();

  }

  async validateSubscriptionFromServer() {

    try{

      const isLoggedIn = await AuthController.isLoggedIn();

      // Check user is logged in before sending requests to server
      if (isLoggedIn) {

        // Get the user details from storage
        const user = await AuthController.getUser();
        const userToken = user['token'];

        // Get hymns subscriptions from server
        const hymnsServerSubscriptions = await AuthController.getSubscriptionsFromHymns(user.email, user.token);

        // If hymns server has subscriptions
        if (hymnsServerSubscriptions.length > 0) {

          // Get the latest ending hymn subscription
          const hymnsMostRecentSub = await AuthController.getLatestEndingHymnsSubscription(hymnsServerSubscriptions);
    
          // Get necessary subscription details
          let hymnsPlatform = 'hymns';
          let hymnsTransactionId = '0';
          let hymnsProductId = 'com.hymnsam.hymnsancientmodern';
          let hymnsIsTrial = false;
          try {
            const hymnsAdditionalAppData = JSON.parse(hymnsMostRecentSub['AdditionalAppData']);

            hymnsPlatform = hymnsAdditionalAppData['platform'];
            hymnsTransactionId = hymnsAdditionalAppData['transactionID'];
            hymnsProductId = hymnsAdditionalAppData['bid'];
            hymnsIsTrial = hymnsAdditionalAppData['isTrial'];

          } catch(error) {

          }
    
          // Get dates
          const expiryDateConverted = new Date(hymnsMostRecentSub['ExpiryDate']);
          const todaysDate = new Date();
    
          // If the user already hasn't already got access to the app
          if (!this.state.hasValidSubscription) {
    
            // Check if subscription is still valid
            const mostRecentSubIsValid = !isBefore(expiryDateConverted,todaysDate);
            if (mostRecentSubIsValid){
    
              // Give user access to the app
              this.setState({
                hasValidSubscription: true
              });
    
            } else {
            }

          } 
    
          // Check to see if the subscription in hymns server has a later expiry date than the one in storage
          const hymnsServerSubscriptionIsLaterThanStorage = await DataController.isDateGreaterThanExpiryDateInStorage(expiryDateConverted);
          const hymnsServerSubscriptionIsLessThanStorage = await DataController.isDateLessThanExpiryDateInStorage(expiryDateConverted);
          
          if (hymnsServerSubscriptionIsLaterThanStorage){
    
            // Compile the data into a format which is readable by Storage
            const newSubscriptionData = {
                isDirectlyFromServer: true,
                platform: hymnsPlatform,
                startDate: new Date(hymnsMostRecentSub['StartDate']),
                expiryDate: expiryDateConverted,
                transactionId: hymnsTransactionId,
                productId: hymnsProductId,
                isTrial: hymnsIsTrial
            }
    
            // Overwrite subscription in storage
            await DataController.overwriteSubscription(newSubscriptionData);
    
            // Check subscription is valid
            await this.validateUserAccess();

            // If hymns do exist in server, but the latest one isn't greater than the one in storage
          } else if(hymnsServerSubscriptionIsLessThanStorage) {

            await AuthController.writeSubscriptionFromStorageToServer(userToken, hymnsServerSubscriptions);

          } else {
            // The subscription in hymns server is more than likely the same subscription we have in storage, so no need to update either
          }

          // If there are no subscriptions in hymn server
        } else {

          await AuthController.writeSubscriptionFromStorageToServer(userToken, hymnsServerSubscriptions);

        }
      }
    } catch(error) {
      console.log('Error when validating subscription from hymns server:', error.message);
    }
  }

  async updateDashboardModalStates() {

    // if user is on desktop, and is not logged in, then set showSubscriptionModalByDefault to true
    // if user is on desktop, and is logged in, 

    // Checks to see if a subscription is in storage, if there's a subscription which has expired and was a trial, set noSubscriptionsAndTrialExpired to true
    // this will determine whether or not to show the 'Your trial expired' modal
    let hasTrialWhichExpired = false;
    if(this.state.latestSubscription) {
      hasTrialWhichExpired = (this.state.latestSubscription['isTrial'] == true || this.state.latestSubscription['isTrial'] == 'true')  && !this.state.hasValidSubscription
    }

    // Checks to see if there is a subscription in storage, if there's a subscription which has expired and was NOT a trial, set nonTrialSubscriptionExpired to true
    // this will determine whether or not to show the subscriptions modal upon loading
    let nonTrialSubscriptionExpired = false;
    if(this.state.latestSubscription) {
      nonTrialSubscriptionExpired = (this.state.latestSubscription['isTrial'] == false || this.state.latestSubscription['isTrial'] == 'false') && !this.state.hasValidSubscription;
    } else {
      nonTrialSubscriptionExpired = true;
    }

    this.setState({
      showTrialExpiredModal: hasTrialWhichExpired,
      showSubscriptionsModalByDefault: (nonTrialSubscriptionExpired && !this.state.isEligbleForFreeTrial) || (isPlatform('desktop') && !this.state.isLoggedIn ) //<- comment this out to prevent the modal automatically closing upon signing in/purchasing subscription
    });

  }

  async setLoginStatus(status: boolean) {

    if(status == false) {
      await AuthController.logout();
    }

    this.setState({
      isLoggedIn: status
    });

    // If the subscription in storage was directly from the server, then it would've been removed from storage
    // there need to validate user access again
    await this.validateUserAccess();

  }

  setValidSubscriptionStatus(status: boolean) {

    this.setState({
      hasValidSubscription: status
    })

  }

  registerIAP(device: string){
    
    try{

      if (device === 'android'){

        store.register({
          id: _1monthAndroid,
          alias: '1_month_android',
          type: store.PAID_SUBSCRIPTION
        });

        store.register({
          id: _12monthAndroid,
          alias: '12_month_android',
          type: store.PAID_SUBSCRIPTION
        });

        } else {

          store.register({
            id: _1monthIOS,
            alias: '1_month_ios',
            type: store.PAID_SUBSCRIPTION
          });

          store.register({
            id: _12monthIOS,
            alias: '12_month_ios',
            type: store.PAID_SUBSCRIPTION
          });
          
        }
        
      // store.autoFinishTransactions
      store.refresh();

    } catch(error) {
      console.log('Error:', error.message);
    }

  }

  setHasCheckServerAlready(value: boolean) {
    this.hasCheckedServerAlready = value;
  }

  async validateWithGoogle(product: IAPProduct) {

    const transaction: any = product.transaction;
    const receipt: any = JSON.parse(transaction.receipt);

    const bid = receipt.packageName
    const subId = receipt.productId
    const purchaseToken = receipt.purchaseToken

    // Prepare POST body to send to server to validate receipt
    const body: any = { 
      bid: bid, 
      subId: subId,
      purchaseToken: purchaseToken
    };

    // Convert body to application/x-www-form-urlencoded format
    var formBody: any = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // Send request to server to validate receipt and return subscription data from app store
    await fetch('https://hymns-api.hymnsam.co.uk/IAP/verifyWithGoogle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }).then(async (response: any) => {

      // Check response returned ok
      if (response.ok) {

        // Unpack response from server
        await response.json().then(async (jsonResponse: any) => {

          // Extract expiry date milliseconds from googleResponse and convert it back to a date
          const googleResponse = jsonResponse['googleResponse'];
          const payload = googleResponse['payload'];
          const googleExpiryDateMS = payload['expiryTimeMillis'];
          const googleExpiryDateConverted = new Date(parseInt(googleExpiryDateMS));

          const todaysDate = new Date();

          // Check subscription is still valid
          // if(!isBefore(googleExpiryDateConverted,todaysDate)) {

            // Check whether or not we need to overwrite the subscription in local storage
            const expiryDateFromGoogleGreaterThanStorage = await DataController.isDateGreaterThanExpiryDateInStorage(googleExpiryDateConverted);
            if(expiryDateFromGoogleGreaterThanStorage) {

              // Overwrite subscription in storage
              const newSubscriptionData = {
                isDirectlyFromServer: false,
                platform: 'android' as const,
                startDate: new Date(parseInt(payload['startTimeMillis'])),
                expiryDate: googleExpiryDateConverted,
                transactionId: payload['orderId'],
                productId: subId,
                isTrial: false // WARNING: This will have to be changed however it seems as though there's no info in the payload to determine whether product is trial or not
              }

              await DataController.overwriteSubscription(newSubscriptionData);

            }

            // ******************** HYMNS SERVER **********************

            // Updates server with subscription from local storage if it's newer 
            await this.validateSubscriptionFromServer();

            // Give user permission to use app if subscription is still valid (it should be as we have just checked this)
            await this.validateUserAccess();
            this.updateDashboardModalStates();

          // } else {
          // }

        });

      } else {
      }

    }).catch((error: any) => {

      // The error from the server may be due to the user not having any history of subscriptions for the given bid
      console.log('VALIDATOR ERROR: Error from server - ', error.message);

    });

  }

  async validateWithApple(product: IAPProduct) {

    // Retrieve receipt from API
    const transaction: any = product.transaction;
    const receipt: any = transaction.appStoreReceipt;

    // Prepare POST body to send to server to validate receipt
    const body: any = { 
      receipt: receipt, 
      bid: 'com.hymnsam.hymnsancientmodern'
    };

    // Convert body to application/x-www-form-urlencoded format
    var formBody: any = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // Send request to server to validate receipt and return subscription data from app store

    if(!this.hasCheckedServerAlready) { 

      console.log('Server NOT checked, verifying receipt...');

      this.hasCheckedServerAlready = true;

      await fetch('https://hymns-api.hymnsam.co.uk/IAP/verifyWithApple', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      }).then(async (response: any) => {

        // Check response returned ok
        if (response.ok) {

          // Unpack response from server
          await response.json().then(async (jsonResponse: any) => {

            // Extract expiry date milliseconds from appleResponse and convert it back to a date
            const appleResponse = jsonResponse['appleResponse'];
            const appleExpiryDateMS = appleResponse['expires_date_ms'];
            const appleExpiryDateConverted = new Date(parseInt(appleExpiryDateMS));

            const todaysDate = new Date();

            // Check subscription is still valid
            // if(!isBefore(appleExpiryDateConverted,todaysDate)) {

              // Check whether or not we need to overwrite the subscription in local storage
              const expiryDateFromAppleGreaterThanStorage = await DataController.isDateGreaterThanExpiryDateInStorage(appleExpiryDateConverted);
              if(expiryDateFromAppleGreaterThanStorage) {

                // Overwrite subscription in storage
                const newSubscriptionData = {
                  isDirectlyFromServer: false,
                  platform: 'ios' as const,
                  startDate: new Date(parseInt(appleResponse['purchase_date_ms'])),
                  expiryDate: appleExpiryDateConverted,
                  transactionId: appleResponse['transaction_id'],
                  productId: appleResponse['product_id'],
                  isTrial: appleResponse['is_trial_period']
                }

                await DataController.overwriteSubscription(newSubscriptionData);
                console.log('Storage overwritten with subscription from Apple');

              }

              // Updates server with subscription from local storage if it's newer 
              await this.validateSubscriptionFromServer();

              // Give user permission to use app if subscription is still valid (it should be as we have just checked this)
              await this.validateUserAccess();
              this.updateDashboardModalStates();

            // } else {
            //   console.log('Storage NOT overwritten, current subscription is greater than the one from Apple');
            // }

          });

        } else {
          console.log('Response from server failed: ', response.statusText);
          this.hasCheckedServerAlready = false;
        }

      }).catch((error: any) => {

        console.log('VALIDATOR ERROR: Error from server - ', error.message);
        this.hasCheckedServerAlready = false;

      });

    } else {
      console.log('Server already checked, skipping verification of receipt');
      product.finish();
    }

  }

  setupIAP() {

    store.validator = async (product: any, callback: any) => {
      callback(true, {});
    }

    // ------------------------ Android ---------------------------

    store.when(_1monthAndroid).registered((product: IAPProduct) => {

    });

    store.when(_12monthAndroid).registered((product: IAPProduct) => {
      
    });

    // updated
    store.when(_1monthAndroid).updated(async (product: IAPProduct) => {
      this.setState({
        oneMonthAndroidProduct: product
      })
    });


    store.when(_12monthAndroid).updated((product: IAPProduct) => {
      this.setState({
        twelveMonthAndroidProduct: product
      })
    });

    //approved
    store.when(_1monthAndroid).approved(async (product: IAPProduct) => {
      product.verify();
    });

    store.when(_12monthAndroid).approved((product: IAPProduct) => {
      product.verify();
    });

    store.when(_1monthAndroid).verified(async (product: IAPProduct) => {

      await this.validateWithGoogle(product);
      product.finish();

    });
        

    store.when(_12monthAndroid).verified(async (product: IAPProduct) => {

      await this.validateWithGoogle(product);
      product.finish();

    })

    // ------------------------ iOS ---------------------------

    store.when(_1monthIOS).registered((product: IAPProduct) => {
    });

    store.when(_12monthIOS).registered((product: IAPProduct) => {
    });

    store.when(_1monthIOS).updated(async (product: IAPProduct) => {
      this.setState({
        oneMonthIOSProduct: product
      })
    });

    store.when(_12monthIOS).updated((product: IAPProduct) => {
      this.setState({
        twelveMonthIOSProduct: product
      })
    });

    store.when(_1monthIOS).approved(async (product: IAPProduct) => {
      product.verify();

    });

    store.when(_12monthIOS).approved(async (product: IAPProduct) => {
      product.verify();

    });

    store.when(_1monthIOS).verified(async (product: IAPProduct) => {

      try {
        await this.validateWithApple(product);
      } catch(error) {
        console.log('Error:', error.message)
      }

      product.finish();
      
    });

    store.when(_12monthIOS).verified(async (product: IAPProduct) => {

      try {
        await this.validateWithApple(product);
      } catch(error) {
        console.log('Error:', error.message)
      }

      product.finish();

    });

    //errors
    store.error((error: any) => {
      console.log(`Store error => ${JSON.stringify(error)}`);
    })
  }

  updateActiveTab(activeTab: any){

    switch(activeTab) {

      case 'home':
        this.homeIsActive = true; this.browseIsActive = false; this.myListsIsActive = false;
        break;

      case 'browse':
        this.homeIsActive = false; this.browseIsActive = true; this.myListsIsActive = false;
        break;

      case 'mylists':
        this.homeIsActive = false; this.browseIsActive = false; this.myListsIsActive = true;
        break;

    }

    this.setState({
      'activeTab': activeTab
    })

  }

  setTabs(activeTab: string, hide: boolean) {

    // console.log(`Setting active tab to ${activeTab}, and hide to ${hide}`);

    this.setState({
      hideTabs: hide,
      activeTab: activeTab
    });

  }

  async sendSupportEmail(type: 'Subscription Support' | 'Feedback') {

    let toAddress = 'apps@hymnsam.co.uk';
    let ccAddress = 'hymnsApp@aimermedia.com';
    let subject = 'Support';
    let emailBody = '';

    if (type === 'Subscription Support') {
      toAddress = toAddress;
      subject = 'Support';
    } else if (type === 'Feedback') {
      toAddress = 'apps@hymnsam.co.uk';
      ccAddress = 'hymnsApp@aimermedia.com'
      subject = 'Feedback';
    }

    try {

        let deviceInfo = await Device.getInfo();

        if ((isPlatform('ios') || (isPlatform('android') && await EmailComposer.hasAccount())) && !isPlatform('mobileweb')) {

          emailBody += '<div style=\"padding-bottom:20px\">Type your query here</div>';
          emailBody += '<hr>';
          emailBody += '<div style=\"color:darkslategrey\">Device and subscription info below</div>';

          const isLoggedIn = await AuthController.isLoggedIn();
          if(isLoggedIn){
            const user = await AuthController.getUser();
            emailBody += '<div style=\"color:darkslategrey\">User: ' + user.email + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Token: ' + user.token + '</div>';
          } else {
            emailBody += '<div style=\"color:darkslategrey\">' + 'User is not logged into a hymns account' + '</div>';
          }

          if (deviceInfo != null) {
            emailBody += '<div style=\"color:darkslategrey\">App: ' + deviceInfo.appName + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">App Version: ' + deviceInfo.appVersion + '(' + deviceInfo.appBuild + ')' + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Device Manufacturer: ' + deviceInfo.manufacturer + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Device Model: ' + deviceInfo.model + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Platform: ' + deviceInfo.platform + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">OS Version: ' + deviceInfo.osVersion + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Device UUID: ' + deviceInfo.uuid + '</div>';
          }

          emailBody += '<br/>';

          let currentSubscription = await DataController.getSubscription();

          let subscriptionProductID = '';
          let subscriptionExpiryDate= '';
      
          if (currentSubscription) {
            subscriptionProductID = currentSubscription.productId;
            subscriptionExpiryDate = subscriptionExpiryDate = format(new Date(currentSubscription.expiryDate), 'dd-MM-yyyy');
          }

          emailBody += '<div style=\"color:darkslategrey\">Subscription Info</div>';

          if (currentSubscription != null) {
            emailBody += '<div style=\"color:darkslategrey\">Product: ' + subscriptionProductID + '</div>';
            emailBody += '<div style=\"color:darkslategrey\">Expiry Date: ' + subscriptionExpiryDate + '</div>';
          } else {
            emailBody += '<div style=\"color:darkslategrey\">No subscription</div>';
          }

          if (isPlatform('ios')) {
            if (this.state.oneMonthIOSProduct && this.state.oneMonthIOSProduct.transaction) {
              if (this.state.oneMonthIOSProduct.transaction.type === 'ios-appstore') {
                if (this.state.oneMonthIOSProduct.transaction.appStoreReceipt) {
                  emailBody += '<br/><br/>';
                  emailBody += JSON.stringify(this.state.oneMonthIOSProduct.transaction.appStoreReceipt);
                }
              }
            }
          }

          let email = {
            to: toAddress,
            cc: ccAddress,
            subject: subject,
            body: emailBody,
            isHtml: true
          }

            EmailComposer.open(email);

        } else {

          emailBody += 'Type your query here\n\n';
          emailBody += 'Device and subscription info below\n';

          const isLoggedIn = await AuthController.isLoggedIn();
          if(isLoggedIn){
            const user = await AuthController.getUser();
            emailBody += 'User: ' + user.email + '\n';
            emailBody += 'Token: ' + user.token + '\n';
          } else {
            emailBody += 'User is not logged into a hymns account\n';
          }
          if (deviceInfo != null) {
            emailBody += 'App: Hymns' + '\n';
            emailBody += 'Device Manufacturer: ' + deviceInfo.manufacturer + '\n';
            emailBody += 'Device Model: ' + deviceInfo.model + '\n';
            emailBody += 'Platform: ' + deviceInfo.platform + '\n';
            emailBody += 'OS Version: ' + deviceInfo.osVersion + '\n';
            emailBody += 'Device UUID: ' + deviceInfo.uuid + '\n';
          }
          
          emailBody += '\n';

          let currentSubscription = await DataController.getSubscription();
          let subscriptionProductID = '';
          let subscriptionExpiryDate = '';
      
          if (currentSubscription) {
            subscriptionProductID = currentSubscription.productId;
            subscriptionExpiryDate = format(new Date(currentSubscription.expiryDate), 'dd-MM-yyyy');
          }

          emailBody += 'Subscription Info\n';

          if (currentSubscription != null) {
            emailBody += 'Product ID: ' + subscriptionProductID + '\n';
            emailBody += 'Expiry Date: ' + subscriptionExpiryDate + '\n';
          } else {
            emailBody += 'No subscription\n';
          }

          emailBody = emailBody.replace(/\n/g, '%0D%0A')

          // creating link and clicking it - as window.open on mailto link was causing crash
          var link=document.createElement("a");
          link.href='mailto:' + toAddress + '?cc=' + ccAddress + '&subject=' + subject + '&body=' + emailBody;
          link.click();
        }
      
      } catch (error) {
        console.log('Error:', error);
        // create basic formatted email without subscription info
        window.open('mailto:' + toAddress + '?subject=' + subject + '&body=' + emailBody);
      }
  }

  closeSubscriptionModal() {

    this.setState({
      showSubscriptionsModalByDefault: false
    });

    this.updateDashboardModalStates();

  }

  async openSubscriptionModal(signIn: any, options: any, info: any) {
    this.setState({
      showSubscriptionsModalByDefault: true,
      showTrialExpiredModal: false,
      subscriptionsSignInDropdownIsOpen: signIn,
      subscriptionsOptionsDropdownIsOpen: options,
      subscriptionsInfoDropdownIsOpen: info
    })
  }

  async startTrial() {

    await Device.getInfo().then(async (info: any) => {

      try {

        const platform = info.platform == 'web' ? 'hymns' : info.platform;
        await DataController.startTrial(platform);
        await this.validateUserAccess();

      } catch (error) {
        console.log('Error starting trial', error);
      }
  
    });


    this.updateDashboardModalStates();

  }

  render() {

    
    // this.setState({ hymnNumberList: this.airTableData.tables != null ? 
    //                   this.airTableData.tables['Hymns'] != null ? 
    //                   this.airTableData.tables['Hymns'].map((hymn: any) => {
    //                     return {
    //                       text: hymn['Name/First Line'], 
    //                       number: hymn['Number'], 
    //                       compareField: `${hymn['Number']} ${hymn['Name/First Line']} ${hymn['Bible Ref'] != null ? hymn['Bible Ref'].map((bibleRefRecordID: any) => {

    //                         const bibleRefRecord = this.dataQuerying.findLinkedRecordFields(airTableData, 'Bible Ref', bibleRefRecordID)
    //                         return bibleRefRecord['BibleRef'];

    //                       }).join(' ') : ''}`,
    //                       bibleRefs: hymn['Bible Ref'] != null ? hymn['Bible Ref'].map((bibleRefRecordID: any) => {

    //                         const bibleRefRecord = this.dataQuerying.findLinkedRecordFields(airTableData, 'Bible Ref', bibleRefRecordID)
    //                         return bibleRefRecord

    //                       }) : null
    //                     }
    //                   }) : [] : []});

    const hymnFirstLineList =   this.state.hymnNumberList.slice().sort((a: any, b: any) => {
                                  const aWithoutCommas = a['text'].replace(/‘|'/g, '');
                                  const bWithoutCommas = b['text'].replace(/‘|'/g, '');
                                  return aWithoutCommas.localeCompare(bWithoutCommas);
                                });

    const bibleBookList = this.airTableData.tables != null ? 
                          this.airTableData.tables['bibleBookNameList'] != null ? 
                          this.airTableData.tables['bibleBookNameList'].map((bookName: any) => {
                            return {
                              text: bookName,
                              compareField: `${bookName}`,
                              subtitle: this.dataQuerying.getRelatedHymns(this.airTableData, 'bible', bookName).length + ' hymns'
                              
                            }
                          }) : [] : [];

    const categoriesList = this.airTableData.tables != null ? 
                           this.airTableData.tables['categoriesList'] != null ? 
                           this.airTableData.tables['categoriesList'].map((category: any) => {
                            return {
                              text: category,
                              subtitle: this.dataQuerying.howManyTimesValueOccursInTable(this.airTableData, 'Hymns', 'Categories', category) + ' hymns',
                              compareField: category
                            }
                           }).sort((a: any, b: any) => {
                            const aWithoutThe = a['text'].replace(/The |the /g, '');
                            const bWithoutThe = b['text'].replace(/The |the /g, '');
                            return aWithoutThe.localeCompare(bWithoutThe);
                          }) : [] : [];

    const thematicList =  this.airTableData.tables != null ? 
                          this.airTableData.tables['Thematic'] != null ? 
                          this.airTableData.tables['Thematic'].map((thematic: any) => {
                            return {
                              text: thematic['Name'],
                              compareField: thematic['Name'],
                              subtitle: thematic['Hymns'].length + ' hymns'
                            }
                          }) : [] : [];

    const hosList = this.airTableData.tables != null ? 
                    this.airTableData.tables['HOS'] != null ? 
                    this.airTableData.tables['HOS'].sort((a: any, b: any) => {
                      return a['sortOrder'] > b['sortOrder'] ? 1 : -1;
                    }).map((hos: any) => {
                      const displayText = hos['Display Text'].trim()
                      return {
                        text: displayText,
                        compareField: displayText
                      }
                    })
                    
                  : [] : [];

    const authorList = this.airTableData.tables != null ? 
                       this.airTableData.tables['People'] != null ? 
                       this.airTableData.tables['People'].filter((person: any) => {
                        return person['Hymns'] != null ? true : false                   
                       }).map((author: any) => {

                          const hasSurname = author['Surname'] != null;
                          const hasForenames = author['Forenames'] != null;

                          let displayName = ''
                          switch(true) {

                            case hasSurname && hasForenames:
                              displayName = `${author['Surname']}, ${author['Forenames']}`;
                              break;
                            case hasSurname && !hasForenames:
                              displayName = author['Surname'];
                              break;
                            case !hasSurname && hasForenames:
                              displayName = author['Forenames'];
                              break;
                            case !hasSurname && !hasForenames:
                              displayName = `No name`
                              break;
                          }

                          return {
                            text: displayName,
                            subtitle: author['Dates'],
                            compareField: displayName,
                          }

                       }).sort((a: any, b: any) => {
                          return a['text'].localeCompare(b['text']);
                       }) : [] : [];

    const composerList =  this.airTableData.tables != null ? 
                          this.airTableData.tables['People'] != null ? 
                          this.airTableData.tables['People'].filter((person: any) => {

                            // person['Tunes'] is the field which shows all the Tunes which a person composes
                            // if it's not null then we know the person is a composer, if not then it won't return the record
                            return person['Tunes'] != null ? true : false                   
                          }).map((composer: any) => {

                            const hasSurname = composer['Surname'] != null;
                            const hasForenames = composer['Forenames'] != null;

                            let displayName = ''
                            switch(true) {

                              case hasSurname && hasForenames:
                                displayName = `${composer['Surname']}, ${composer['Forenames']}`;
                                break;
                              case hasSurname && !hasForenames:
                                displayName = composer['Surname'];
                                break;
                              case !hasSurname && hasForenames:
                                displayName = composer['Forenames'];
                                break;
                              case !hasSurname && !hasForenames:
                                displayName = `No name`
                                break;
                            }

                            return {
                              text: displayName,
                              subtitle: composer['Dates'],
                              compareField: displayName
                            }

                             // Removes any duplicates
                          }).filter((thing: any, index: any, self: any) =>
                          index === self.findIndex((t: any) => (
                            t.text === thing.text
                            // Sort alphabetically
                          ))).sort((a: any, b: any) => {
                            return a['text'].localeCompare(b['text']);
                          }) : [] : [];

    const tuneList =  this.airTableData.tables != null ? 
                      this.airTableData.tables['Tunes'] != null ? 
                      this.airTableData.tables['Tunes'].map((tune: any) => {

                        return {
                          text: tune['name'],
                          compareField: tune['name'],
                          hymns: tune['Number (from Notes)']
                        }
                        // Removes any duplicates
                      }).filter((thing: any, index: any, self: any) =>
                      index === self.findIndex((t: any) => (
                        t.text === thing.text
                      ))
                      ) : [] : [];

    const bookmarksList: any = [];

    const products = {
      oneMonthAndroidProduct: this.state.oneMonthAndroidProduct,
      twelveMonthAndroidProduct: this.state.twelveMonthAndroidProduct,
      oneMonthIOSProduct: this.state.oneMonthIOSProduct,
      twelveMonthIOSProduct: this.state.twelveMonthIOSProduct
    }

    return (<IonApp>   
      {/* Subscription modals */}
      <IonModal 
        isOpen={this.state.showSubscriptionsModalByDefault}
      >
                    
        <div className="modal-custom-css" style={{overflowY: 'scroll'}}>

          <Subscriptionsv2
            setLoginStatus = {this.setLoginStatus.bind(this)}
            setTabs = {this.setTabs.bind(this)}
            setValidSubscriptionStatus = {this.setValidSubscriptionStatus.bind(this)}
            validSubscriptionStatus = {this.state.hasValidSubscription}
            latestSubscription = {this.state.latestSubscription}
            validateUserAccess = {this.validateUserAccess.bind(this)}
            validateSubscriptionFromServer = {this.validateSubscriptionFromServer.bind(this)}
            products = {products}
            sendSupportEmail = {this.sendSupportEmail.bind(this)}
            setHasCheckedServerAlready = {this.setHasCheckServerAlready.bind(this)}
            closeSubscriptionModal = {this.closeSubscriptionModal.bind(this)}
            signInDropdownIsOpen = {this.state.subscriptionsSignInDropdownIsOpen}
            subscriptionOptionsIsOpen = {this.state.subscriptionsOptionsDropdownIsOpen}
            subscriptionInfoIsOpen = {this.state.subscriptionsInfoDropdownIsOpen}
            isEligbleForFreeTrial={this.state.isEligbleForFreeTrial}
            setTrialEligibility={this.setTrialEligibility.bind(this)}
          />             

        </div>
          
      </IonModal>

       {/* Only show when trial has not been initiated */}
       <IonModal 
        isOpen={this.state.isEligbleForFreeTrial && !this.state.hasValidSubscription} 
        showBackdrop={this.state.isEligbleForFreeTrial && !this.state.hasValidSubscription} 
        backdropDismiss={false} 
        className="onBoardingModal" 
        mode='ios'
      >
        <div className="modal-custom-css" style={{overflowY: 'scroll'}}>
          <div className="contentContainer" style={{backgroundColor: 'white', height: '100%', alignItems: 'center'}}>
            <div className="content">
              <div className="onBoardingHeader">
                Welcome to the Hymns Ancient & Modern app
              </div>

              <div className="onBoardingBookCover" style={{alignSelf: 'center'}}>
                <img src={bookCover} style={{width: '108px', height: '157px'}} />
              </div>

              <div className="onBoardingText">
                The world's most famous hymn book, now in app form. Your 30 days free access to the app will begin from {format(new Date(), 'd MMM yyyy')} and end on {format(new Date().setDate(new Date().getDate() + 30), 'd MMM yyyy')}. To continue using the app after your free access ends, purchase a subscription or sign into your Hymns Ancient & Modern account.
              </div>

              <IonButton className="subscriptionBlueBtn" style={{height: '44px'}} expand="block" onClick={async() => {
                  this.startTrial();
              }}> 
                <div className="subscriptionBtnText">
                  Begin 30 days free access
                </div>
              </IonButton>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '14px', marginBottom: '14px'}}>

                <div className="seperator" style={{width: '100%'}}/>
                <div className="onBoardingBtnSeperatorText">or</div>
                <div className="seperator" style={{width: '100%'}}/>

              </div>

              <IonButton className="subscriptionClearBtn" fill='outline' style={{height: '44px'}} expand="block" onClick={async() => {
                this.openSubscriptionModal(true, true, true);
              }}> 
                <div className="subscriptionClearBtnText">
                  Sign in and Subscription options
                </div>
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>

      <IonModal isOpen={this.state.showTrialExpiredModal} showBackdrop={this.state.showTrialExpiredModal} backdropDismiss={false} className="trialExpiredModal" mode='ios'>

        <div className="contentContainer" style={{height: '100%', alignItems: 'center'}}>
          <div className="content">
            <div className="onBoardingHeader">
              Your 30 days free access has now ended
            </div>

            <div className="onBoardingText">
              Your 30 days of free access to the app finished on {this.state.latestSubscription ? format(new Date(this.state.latestSubscription['expiryDate']), 'd MMM yyyy') : 'date not found'}. But don’t worry! You can still access the app by either signing into a Hymns Ancient and Modern account or purchasing a subscription option.
            </div>

            {!this.state.isLoggedIn ? 
              <div>
                <IonButton className="subscriptionBlueBtn" style={{height: '44px'}} expand="block" onClick={async() => {
                  this.openSubscriptionModal(true, false, false);
                }}> 
                  <div className="subscriptionBtnText">
                    Sign into hymnsam.co.uk account
                  </div>
                </IonButton>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '14px', marginBottom: '14px'}}>

                  <div className="seperator" style={{width: '100%'}}/>
                  <div className="onBoardingBtnSeperatorText">or</div>
                  <div className="seperator" style={{width: '100%'}}/>

                </div>
              </div>

            : ''}

            <IonButton className="subscriptionBlueBtn" style={{height: '44px'}} expand="block" onClick={async() => {
                this.openSubscriptionModal(false, true, true);
            }}> 
                <div className="subscriptionBtnText">
                  Explore Subscription options
                </div>
            </IonButton>

          </div>
        </div>

      </IonModal>

      <IonReactRouter>
        <IonTabs ref={this.tabs} onIonTabsDidChange={e => this.updateActiveTab(e.detail.tab)}>
          <IonRouterOutlet animated={true} mode='ios'>
              <Route 
                exact 
                path="/home" 
                render={(props) => {
                  return <Dashboard 
                            setTabs={this.setTabs.bind(this)} 
                            setLoginStatus={this.setLoginStatus.bind(this)}
                            setValidSubscriptionStatus={this.setValidSubscriptionStatus.bind(this)}
                            validateSubscriptionFromServer={this.validateSubscriptionFromServer.bind(this)}
                            validateUserAccess={this.validateUserAccess.bind(this)}
                            sendSupportEmail={this.sendSupportEmail.bind(this)}
                            hasValidSubscription={this.state.hasValidSubscription}
                            latestSubscription={this.state.latestSubscription}
                            products={products}
                            setHasCheckedServerAlready={this.setHasCheckServerAlready.bind(this)}
                            showTrialExpiredModal={this.state.showTrialExpiredModal}
                            showSubscriptionsModalByDefault={this.state.showSubscriptionsModalByDefault}
                            updateDashboardModalStates={this.updateDashboardModalStates.bind(this)}
                            openSubscriptionModal={this.openSubscriptionModal.bind(this)}
                            airTableData={this.airTableData}
                  />
              }}
              />

              <Route exact path="/browse" render={(props) => {
                return <Browse airTableData={this.airTableData} setTabs={this.setTabs.bind(this)}/>;
                }
              }/>

              <Route exact path="/hymn-number" render={(props) => {
                return (
                  <HymnNumberList 
                    airTableData={this.airTableData}
                    hymnNumberList={this.state.hymnNumberList} 
                    bibleBookList={bibleBookList}
                    setTabs={this.setTabs.bind(this)}
                  />
                )
                }}>
              </Route>

              <Route exact path="/first-line" render={(props) => {
                return <HymnNumberList airTableData={this.airTableData} hymnNumberList={hymnFirstLineList} bibleBookList={bibleBookList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              <Route exact path="/biblical-index" render={(props) => {
                return (
                  <HymnNumberList 
                    airTableData={this.airTableData}
                    hymnNumberList={this.state.hymnNumberList} 
                    bibleBookList={bibleBookList}
                    setTabs={this.setTabs.bind(this)}
                  />
                )
                }}>
              </Route>

              {/* <Route exact path="/biblical-index" render={(props) => {
                return <BiblicalIndexList airTableData={airTableData} bibleBookList={bibleBookList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route> */}

              <Route exact path="/categories" render={(props) => {
                return <CategoriesList airTableData={this.airTableData} categoryList={categoriesList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {categoriesList.map((category: any) => {
               
                return (
                  <Route exact path={`/categories/${category['text']}/hymns`} render={(props) => {
                    return (
                      <HymnNumberList 
                        airTableData={this.airTableData}
                        hymnNumberList={this.state.hymnNumberList} 
                        bibleBookList={bibleBookList}
                        setTabs={this.setTabs.bind(this)}
                      />
                    )
                  }} />
                )
                
              })}

              <Route exact path="/sundays-and-major-feasts" render={(props) => {
                return <CalendarOfSundaysList airTableData={this.airTableData} hosList={hosList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {hosList.map((hos: any) => {
               
               return (
                 <Route exact path={`/sundays-and-major-feasts/${hos['text']}/hymns`} render={(props) => {
                   return (
                     <HymnNumberList 
                       airTableData={this.airTableData}
                       hymnNumberList={this.state.hymnNumberList} 
                       bibleBookList={bibleBookList}
                       setTabs={this.setTabs.bind(this)}
                     />
                   )
                 }} />
               )
               
             })}

              <Route exact path="/thematic-index" render={(props) => {
                return <ThematicIndexList airTableData={this.airTableData} thematicList={thematicList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {thematicList.map((thematic: any) => {
               
               return (
                 <Route exact path={`/thematic-index/${thematic['text']}/hymns`} render={(props) => {
                   return (
                     <HymnNumberList 
                       airTableData={this.airTableData}
                       hymnNumberList={this.state.hymnNumberList} 
                       bibleBookList={bibleBookList}
                       setTabs={this.setTabs.bind(this)}
                     />
                   )
                 }} />
               )
               
             })}

              <Route exact path="/author" render={(props) => {
                return <AuthorList airTableData={this.airTableData} authorList={authorList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {authorList.map((author: any) => {
               return (
                 <Route exact path={`/author/${author['text']}/hymns`} render={(props) => {
                   return (
                     <HymnNumberList 
                       airTableData={this.airTableData}
                       hymnNumberList={this.state.hymnNumberList} 
                       bibleBookList={bibleBookList}
                       setTabs={this.setTabs.bind(this)}
                     />
                   )
                 }} />
               )
               
             })}

              <Route exact path="/composer" render={(props) => {
                return <ComposerList airTableData={this.airTableData} composerList={composerList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {composerList.map((composer: any) => {
               return (
                 <Route exact path={`/composer/${composer['text']}/hymns`} render={(props) => {
                   return (
                     <HymnNumberList 
                       airTableData={this.airTableData}
                       hymnNumberList={this.state.hymnNumberList} 
                       bibleBookList={bibleBookList}
                       setTabs={this.setTabs.bind(this)}
                     />
                   )
                 }} />
               )
               
             })}

              <Route exact path="/tune" render={(props) => {
                return <TuneList airTableData={this.airTableData} tuneList={tuneList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {tuneList.map((tune: any) => {
               return (
                 <Route exact path={`/tune/${tune['text']}/hymns`} render={(props) => {
                   return (
                     <HymnNumberList 
                       airTableData={this.airTableData}
                       hymnNumberList={this.state.hymnNumberList} 
                       bibleBookList={bibleBookList}
                       setTabs={this.setTabs.bind(this)}
                     />
                   )
                 }} />
               )
               
             })}

              <Route exact path="/my-bookmarks" render={(props) => {
                return <BookmarksList airTableData={this.airTableData} bookmarksList={bookmarksList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              {/* Path regex prevents anything other than the numbers 1 to 847 being entered after '/hymn/', user will be redirected otherwise */}
              <Route exact path="/hymn/:id([1-9]|[1-9][0-9]|[1-7][0-9]{2}|8[0-3][0-9]|84[0-7])?" render={(props) => {
                return <Hymn airTableData={this.airTableData} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              <Route exact path="/contact" render={() => {
                return <Contact
                          products={products} 
                        />
              }}>
                
              </Route>

              <Route exact path="/mylists" render={(props) => {
                return <MyLists setTabs={this.setTabs.bind(this)} />;
              }}>
              </Route>

              <Route exact path="/create-or-modify-list" render={(props) => {
                return <CreateOrModifyList hymnNumberList={this.state.hymnNumberList} setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              <Route exact path="/about-the-app" render={(props) => {
                return <AboutTheApp setTabs={this.setTabs.bind(this)} />
              }}>
              </Route>

              <Route exact path="/add-hymn" render={(props) => {
                return <AddHymns hymnNumberList={this.state.hymnNumberList} setTabs={this.setTabs.bind(this)} />  
              }}>
              </Route>

              <Route exact path="/">
                <Redirect to="/home" />
              </Route>

              {/* <Route render={() => <Redirect to="/" />} /> */}

          </IonRouterOutlet>

          <IonTabBar slot="bottom" hidden={this.state.hideTabs}>
            <IonTabButton mode='ios' tab="home" href="/home">
              {this.state.activeTab === 'home' ? 
              <img src={homeTabIconActive} style={{borderTop: this.state.activeTab === 'home' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={homeTabIconInactive} height='32' /> }  
              <div className={this.state.activeTab === 'home' ? "tabTextActive" : "tabTextInactive"}>Home</div>
            </IonTabButton>
            <IonTabButton mode='ios' tab="browse" href="/browse">
              {this.state.activeTab === 'browse' ? 
              <img src={browseTabIconActive} style={{borderTop: this.state.activeTab === 'browse' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={browseTabIconInactive} height='32' /> }              
              <div className={this.state.activeTab === 'browse' ? "tabTextActive" : "tabTextInactive"}>Browse</div>
            </IonTabButton>
            <IonTabButton mode='ios' tab="mylists" href="/mylists">
              {this.state.activeTab === 'mylists' ? 
              <img src={myListsTabIconActive} style={{borderTop: this.state.activeTab === 'mylists' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={myListsTabIconInactive} height='32' /> }  
              <div className={this.state.activeTab === 'mylists' ? "tabTextActive" : "tabTextInactive"}>My Lists</div>
            </IonTabButton>
            {/* <IonButton mode='ios' routerLink="/home">
              {this.state.activeTab === 'home' ? 
              <img src={homeTabIconActive} style={{borderTop: this.state.activeTab === 'home' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={homeTabIconInactive} height='32' /> }  
              <div className={this.state.activeTab === 'home' ? "tabTextActive" : "tabTextInactive"}>Home</div>
            </IonButton>
            <IonButton mode='ios' routerLink="/browse">
              {this.state.activeTab === 'browse' ? 
              <img src={browseTabIconActive} style={{borderTop: this.state.activeTab === 'browse' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={browseTabIconInactive} height='32' /> }              
              <div className={this.state.activeTab === 'browse' ? "tabTextActive" : "tabTextInactive"}>Browse</div>
            </IonButton>
            <IonButton mode='ios' routerLink="/mylists">
              {this.state.activeTab === 'mylists' ? 
              <img src={myListsTabIconActive} style={{borderTop: this.state.activeTab === 'mylists' ? '3px solid #102cb7' : '0px'}} height='32' /> 
              : <img src={myListsTabIconInactive} height='32' /> }  
              <div className={this.state.activeTab === 'mylists' ? "tabTextActive" : "tabTextInactive"}>My Lists</div>
            </IonButton>*/}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>)
  }
};

export default withIonLifeCycle(App);
