import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonSearchbar, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import { chevronBack, chevronForwardOutline, searchOutline } from "ionicons/icons";
import { Component } from "react"
import { withRouter } from "react-router";
import List from "../../../components/List";
import DataQuerying from '../../../data/DataQuerying';

import './lists.css'
import searchIcon from '../../../theme/assets/svgs/button-36-pt-search.svg'
import searchIconWhite from '../../../theme/assets/Button_Search_White@3x.png'
import leftChevronIcon from '../../../theme/assets/svgs/Button_Disclosure_Back.svg'
import rightChevronIcon from '../../../theme/assets/svgs/Button_Disclosure_Right.svg'
import Listv2 from "../../../components/Listv2";

type Props =  { airTableData: any, thematicList: any, history: any, location: any, setTabs: any}
type State = { searchText: any, showSearch: any}
  
class ThematicIndexList extends Component <Props, State> {

    dataQuerying: DataQuerying;
    items: any = [];

    constructor(props: Props) {
        super(props);

        this.dataQuerying = new DataQuerying();
        this.state = {
            searchText: '',
            showSearch: true
        }
    
    }
    
    ionViewWillEnter() {

        this.props.setTabs('browse', false);

    }

    setSearchText(newValue: string) {

        this.setState({
            searchText: newValue
        });

    }

    goBack() {
        this.props.history.goBack();
    }

    onReturned(searchText: any) {

        this.setState({ searchText: searchText })

    }

    getSelectedItem(selectedItem: any) {

        const relatedHymns = this.dataQuerying.getRelatedHymns(this.props.airTableData, 'thematic', selectedItem['text']);
        this.props.history.push(`/thematic-index/${selectedItem['text']}/hymns`,{hymns: relatedHymns, title: selectedItem['text']})

    }

    render() {

        const title = 'Thematic';
        const placeholder = 'Search themes';
        const compareField = 'compareField';
        const displayTextField = 'text';
        const subtitle = 'subtitle';

        this.items = this.props.thematicList != null ? 
                      this.state.searchText != '' ?
                      this.props.thematicList.filter((listItem:any) => {

                          //****** Filter by search term ******/
                          // Convert the list item to lower case along with the search term to avoid missing any matching items
                          // because of incorrect casing (e.g. searching for 'christ' wouldn't return hymns with 'Christ' in the name otherwise)
                          // also convert to string as the default type for things like hymn number is number, otherwise 'includes()' won't work

                          // ** NEED TO ADD ** use ternary operator to check for null

                          return listItem[compareField].toString().toLowerCase().includes(this.state.searchText.toLowerCase())

                      })
                      : this.props.thematicList
        : [];

        return (
            
        <IonPage>

            <IonHeader>
                <IonToolbar>

                    <IonButtons slot="start">
                        <IonButton onClick={() => this.props.history.goBack()}>
                            <IonIcon style={{height: '17px'}} icon={leftChevronIcon} />
                            <div className='backButtonTxt'>Back</div>
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={() => this.setState({showSearch: !this.state.showSearch})}>
                            <img src={searchIconWhite} height='44' width='44' />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{title}</IonTitle>

                </IonToolbar>
            </IonHeader>

            <IonContent scrollY={false} style={{'--background': 'var(--content-background)'}} fullscreen>

                <div className="allContentContainer" id="allContentContainer" style={{ height: 300, overflow: "auto" }}>

                    {this.state.showSearch ?
                        <div>
                            <div className="contentContainer">
                                <div className="content" style={{backgroundColor: 'var(--content-background)'}}>
                                    <IonSearchbar searchIcon={searchIcon} value={this.state.searchText} placeholder={placeholder} onIonChange={e => this.setSearchText(e.detail.value!)}></IonSearchbar>
                                </div>
                            </div>

                            <div className="seperator" style={{backgroundColor: 'var(--footer-border)', marginTop: '16px'}} />
                        </div>
                    : ''}

                    <div className="contentContainer flexChildFullHeight">  
                        <div className='content flexChildFullHeight' style={{backgroundColor: 'transparent'}}> 
                            <Listv2
                                list={this.items != null ? this.items : []}
                                displayNumberField = {undefined}
                                displayTextField = {displayTextField}
                                getSelectedItem = {this.getSelectedItem.bind(this)}
                                detailIcon = {rightChevronIcon}
                                boldFont = {true}
                                removeTopAndBottomPaddingFromMainText = {true}
                                subtitle={subtitle}
                            />
                        </div>
                    </div>
                </div>

            </IonContent>

        </IonPage>
        )

    }

}

export default withRouter(withIonLifeCycle(ThematicIndexList));