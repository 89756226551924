import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonTitle, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { addOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { Component } from 'react';
import './mylists.css';

import addIcon from '../../theme/assets/svgs/Button_Add_Sepia.svg'

import upChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Up.svg'
import downChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Down.svg'

import DataController  from '../../controllers/DataController';
import { withRouter } from 'react-router';

type Props = {
  setTabs: any,
  history: any
}

type State = {
  myLists: {
    name: string,
    date: Date | null,
    creationDate: Date,
    isOpen: boolean,
    addedHymns: any
  }[] | any
}

class MyLists extends Component<Props, State> {

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(props: Props){
    super(props);

    this.props.setTabs('mylists', false);

    this.state = {
      myLists: []
    }

  }

  ionViewWillEnter() {
    
    this.props.setTabs('mylists', false);
    this.loadMyLists();
    
  }

  loadMyLists() {

    DataController.getAllMyLists(false).then((myLists: any) => {

      this.setState({myLists: myLists});

    })

  }

  navigateToListHymn(listIndex: any, hymnIndexInList: any) {

    const list = this.state.myLists[listIndex];
    const hymns = list['hymnsAdded'];
    const hymn = hymns[hymnIndexInList];

    this.props.history.push(`hymn/${hymn['hymnNo']}`, {   
      title:  list['name'], 
      hymnList: hymns.map((currentHymn: any) => currentHymn['hymnNo']), selectedHymn: hymn['hymnNo']
    })   

  }

  render() {

    return (
      <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Lists</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{'--background': 'var(--content-background)'}} fullscreen>
        <div className="allContentContainer">
          <div className="contentContainer">
            <div className="content content-border">
              <div className="myListContainer">
                <IonButton
                    expand="block" 
                    className='createListBtn' 
                    color='tertiary'
                    onClick={() => this.props.history.push('create-or-modify-list')}
                  >
                    <div slot="start" className="createListBtnTxt">
                      <IonIcon style={{height: '15px'}} icon={addIcon}></IonIcon> Create new list
                    </div>
                  </IonButton>

                <div className="seperator" />

                {this.state.myLists.map((currentList: any, listIndex: any) => {

                  return <div key={'mylists' + listIndex}>
                  
                    <div className="listsContent">

                      <div 
                        className="listHeader clickable"
                        onClick={() => {currentList['isOpen'] = !currentList['isOpen']; this.forceUpdate();}}
                      >
                        {currentList['name']}
                      </div>

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <IonButton 
                          color="secondary" 
                          className="smallBtn" 
                          onClick={() => this.props.history.push('create-or-modify-list', {creationDate: currentList['creationDate']})}
                        >
                          <div className="smallBtnText">
                            Edit
                          </div>
                        </IonButton>
                        <div 
                          className="listChevron clickable" 
                          style={{paddingLeft: '12px'}} 
                          onClick={() => {currentList['isOpen'] = !currentList['isOpen']; this.forceUpdate();}}
                        >
                          <IonIcon icon={currentList['isOpen'] ? upChevronIcon : downChevronIcon} />
                        </div>
                      </div>
                    </div>

                    {currentList['date'] != '' ?
                      <div className="listDate">
                        {/* The Date object gets lost when stored as a JSON object, so the currentList date has to be converted back before using get methods*/}
                        {`${new Date(currentList['date']).getDate()} ${this.months[new Date(currentList['date']).getMonth()]} ${new Date(currentList['date']).getFullYear()}`}
                      </div>
                    : ''}

                    {currentList['isOpen'] ?
                        <IonList style={{marginTop: '8px'}}>
                          {currentList['hymnsAdded'].map((currentHymn: any, hymnIndexInList: any) => {       
                            return <IonItem key={listIndex + currentHymn['hymnNo']} button onClick={() => this.navigateToListHymn(listIndex, hymnIndexInList)} detail={true}>
                              <IonNote 
                                slot='start'
                                style={{
                                  marginTop: 'auto',
                                  marginBottom: 'auto'
                                }}
                              >
                                <div className='number-box' style={{display: 'block'}}>
                                    <div className="number">
                                      {currentHymn['hymnNo']}
                                    </div>
                                </div>
                              </IonNote>
                              <IonLabel>
                                {currentHymn['firstLine']}
                              </IonLabel>
                            </IonItem>
                          })}

                        </IonList>

                      : <IonList style={{marginTop: '8px'}}>
                          <IonItem key={'myListsClosed' + listIndex}>
                            { currentList['hymnsAdded'] != null ?
                                currentList['hymnsAdded'].map((currentHymn: any, hymnIndexInList: any) => {  
                                  return  <div key={'myListsClosed' + currentHymn['hymnNo']} className='number-box-minimized clickable' style={{display: 'block'}} onClick={() => this.navigateToListHymn(listIndex, hymnIndexInList)}>
                                            <div className="number">
                                              {currentHymn['hymnNo']}
                                            </div>
                                          </div>
                                })
                            : ''}
                          </IonItem>
                        </IonList>
                      }

                      {listIndex !== this.state.myLists.length - 1 ?
                        <div className="seperator" style={{width: 'inherit'}} />
                      : ''
                      }
                  </div>

                })}

              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
    )

  }

}

export default withRouter(withIonLifeCycle(MyLists));
