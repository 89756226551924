import { IonBackButton, IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonModal, IonNote, IonPage, IonPopover, IonReorder, IonReorderGroup, IonSearchbar, IonToast, IonToolbar, isPlatform, withIonLifeCycle } from "@ionic/react";
import { Component, createRef } from "react";
import {addOutline, chevronBack, chevronBackOutline, chevronForwardOutline, menuOutline, moveOutline, share} from "ionicons/icons"

import './create-or-modify-list.css';

import shareiOSButton from '../../theme/assets/Button_Share_iOS@3x.png';
import deleteButton from '../../theme/assets/Button_Delete@3x.png';

import List from "../../components/List";
import { withRouter } from "react-router";
import DataController from "../../controllers/DataController";
import Listv2 from "../../components/Listv2";

import searchIcon from '../../theme/assets/svgs/button-36-pt-search.svg'
import leftChevronIcon from '../../theme/assets/svgs/Button_Disclosure_Back.svg'
import addIconSepia from '../../theme/assets/svgs/Button_Add_Sepia.svg'
import addIconBlue from '../../theme/assets/svgs/Button_Add_Blue.svg'
import buttonMenu from '../../theme/assets/svgs/Button_Menu.svg'

import { Share } from '@capacitor/core';
import { SocialSharing } from '@ionic-native/social-sharing';
import { format, parseISO } from "date-fns";

import '../../theme/carbon.css'

// @ts-ignore
import { DatePicker, DatePickerInput } from '@carbon/react';

type Props = {
    setTabs: any,
    hymnNumberList: any,
    history: any
}

type State = {
    showToast: boolean,
    showWarning: boolean,
    showModalAddHymns: boolean,
    filteredList: any,
    searchText: any,
    currentList: any,
    reorder: boolean
}

class createOrModifyList extends Component<Props,State> {

    warningMessage = '';
    todaysDate: any = '';
    // itemSlider: any = createRef();

    ionItemSlidingRefs: any = [];  
    setRef = (ref: any) => {
        this.ionItemSlidingRefs.push(ref);
    };

    items: any = [];

    calendarRef: any = createRef();
    
    constructor(props: Props) {
        super(props);

        this.state = {
            showToast: false,
            showWarning: false,
            showModalAddHymns: false,
            filteredList: [],
            searchText: '',
            currentList: { name: '', date: '', hymnsAdded: []},
            reorder: false
        }

        this.formatDate();

    }

    async ionViewWillEnter(){

        const creationDate = this.props.history.location.state != null ? 
                                this.props.history.location.state['creationDate'] != null ?
                                    this.props.history.location.state['creationDate']  
                                :   false
                            : false;

        if(creationDate) {

            DataController.getListByCreationDate(creationDate).then((activeList: any) => {

                DataController.setActiveList(activeList);

                this.props.hymnNumberList.forEach((hymn: any) => {
                    hymn['subtitle'] = '';
                });
                this.setState({
                    currentList: activeList, 
                    filteredList: this.props.hymnNumberList.sort((a: any, b: any) => {
                        return a.number - b.number;
                    })
                });

            });

        } else {

            let activeList = await DataController.createNewActiveList();

            // When user clicks on the add to list from the hymn page, and creates a new list with the selected hymn
            const preselectedHymns = this.props.history.location.state != null ? this.props.history.location.state['preselectedHymns'] : [];
            for(let i = 0; i < preselectedHymns.length; i++) {

                const preselectedHymn = preselectedHymns[i];
                activeList = await DataController.pushHymnToActiveList(preselectedHymn['hymnNo'], preselectedHymn['firstLine']);
                
            }

            this.setState({
                currentList: activeList, 
                filteredList: this.props.hymnNumberList.sort((a: any, b: any) => {
                    return a.number - b.number;
                })
            })

        }

    }

    formatDate() {

        this.todaysDate = new Date();

        const year = this.todaysDate.getFullYear();
        let month = (this.todaysDate.getMonth() + 1).toString();
        let day = this.todaysDate.getDate().toString();

        if (month.length == 1) {
            month = '0' + month;
        }

        if (day.length == 1) {
            day = '0' + day;
        }

        this.todaysDate = `${year}-${month}-${day}`

    }

    updateName(newName: string) {

        DataController.updateAttributeInActiveList('name', newName).then(() => {

            DataController.getActiveList().then((updatedActiveList) => {

                this.setState({currentList: updatedActiveList})
    
            })

        });


    }

    updateDate(newDate: any) {

        DataController.updateAttributeInActiveList('date', newDate).then(() => {

            DataController.getActiveList().then((updatedActiveList) => {

                this.setState({currentList: updatedActiveList});
    
            })

        })

    }

    async removeHymn(hymnNo: any, index: any) {

        // Close the 'Remove' button which appears when swiping to remove hymns
        if(this.ionItemSlidingRefs[index] != null) {
            await this.ionItemSlidingRefs[index].closeOpened();
        }

        // Give the UI half a second to reset the ionItemSliding item before removing to make transition smoother 
        setTimeout(() => {
            DataController.removeHymnFromActiveList(hymnNo).then((updatedActiveList: any) => {
                this.setState({currentList: updatedActiveList});
            });    
        }, 500);

    }

    listValidated() {

        let isValidated = true;

        switch(true) {

            case this.state.currentList['name'] == '':
                this.warningMessage = 'Please enter a title'
                this.setState({showWarning: true});
                isValidated = false;
                break;

            case this.state.currentList['hymnsAdded'].length == 0:
                this.warningMessage = 'Please add at least one hymn'
                this.setState({showWarning: true});
                isValidated = false;
                break;
        
        }

        return isValidated;

    }

    async deleteActiveList() {

        await DataController.deleteActiveList().then(() => {
            this.props.history.goBack();
        });

    }

    doReorder(event: any) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        DataController.moveHymnInActiveList(event.detail.from, event.detail.to).then((updatedActiveList) => {

            // Finish the reorder and position the item in the DOM based on
            // where the gesture ended. This method can also be called directly
            // by the reorder group
            event.detail.complete(this.state.currentList['hymnsAdded']);

            this.setState({currentList: updatedActiveList});

        })
    
    }

    async getSelectedItem(item: any) {
        const updatedActiveList = await DataController.pushHymnToActiveList(item['number'], item['text']);

        if (updatedActiveList) {

            this.setState({currentList: updatedActiveList, showModalAddHymns: false})

        } else {
            this.warningMessage = `Hymn number ${item['number']} already exists in this list`
            this.setState({
                showWarning: true
            })
        }
    }

    setSearchText(newValue: string) {

        this.setState({
            searchText: newValue
        });

    }

    async initiateShare() {

        try {

            // Warning: Don't try to add identation to the string otherwise it'll mess up with output when the user clicks share
            if(isPlatform('ios') || isPlatform('android')){
                SocialSharing.share(`I have a created a list of hymns which I think you may be interested in, they are: \n\n${this.state.currentList['hymnsAdded'].map((hymn: any) => {
                    return `${hymn['hymnNo']}: ${hymn['firstLine']}`
                }).join('\n')}\n\nYou can view these hymns at:\n`, 'Hymns Ancient & Modern', undefined, 'https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern')
            } else {
                await Share.share({
                    title: 'Hymns Ancient & Modern',
                    text: `I have a created a list of hymns which I think you may be interested in, they are: \n\n${this.state.currentList['hymnsAdded'].map((hymn: any) => {
                        return `${hymn['hymnNo']}: ${hymn['firstLine']}`
                    }).join('\n')}\n\nYou can view these hymns at:`,
                    url: 'https://canterburypress.hymnsam.co.uk/apps/hymns-ancient-modern',
                    dialogTitle: 'Share',
                });
            }
        } catch(error) {
            console.log('Error', error);
        }

    }

    render() {

        const compareField = 'compareField'
        const displayNumberField = 'number'
        const displayTextField = 'text'
        const placeholder = 'Please enter hymn number or first line';

        this.items = this.state.filteredList != null ? 
                      this.state.searchText != '' ?
                      this.state.filteredList.filter((listItem:any) => {

                          //****** Filter by search term ******/
                          // Convert the list item to lower case along with the search term to avoid missing any matching items
                          // because of incorrect casing (e.g. searching for 'christ' wouldn't return hymns with 'Christ' in the name otherwise)
                          // also convert to string as the default type for things like hymn number is number, otherwise 'includes()' won't work

                          // ** NEED TO ADD ** use ternary operator to check for null

                          return listItem[compareField].toString().toLowerCase().includes(this.state.searchText.toLowerCase())

                      })
                      : this.state.filteredList
        : [];

        return (
            <IonPage>

            <IonHeader>
                <IonToolbar>

                    <IonButtons slot="start">
                        <IonButton onClick={() => this.props.history.goBack()}>
                            <IonIcon style={{height: '17px'}} icon={leftChevronIcon} />
                            <div className='backButtonTxt'>Back</div>
                        </IonButton>
                    </IonButtons>
                    
                    <IonButtons slot="end">
                        <img className='clickable' onClick={() => this.initiateShare()} src={shareiOSButton} height='44' width='44' />
                        <img 
                            className='toolBarIcon clickable' 
                            onClick={() => this.deleteActiveList()} 
                            src={deleteButton} 
                            height='44' 
                            width='44' 
                        >
                        </img>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>

            {/* Code below is for when the model box menu is open */}
            <IonModal className='full-screen-modal' mode="ios" isOpen={this.state.showModalAddHymns}>
                <div className="modal-custom-css">

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                            <IonButton onClick={() => {this.setState({showModalAddHymns: false})}}>
                                Close
                            </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    {/* <IonContent scrollY={false} style={{'--background': 'var(--content-background)'}}> */}
                        <div className="allContentContainer flexChildFullHeight">

                            <div className="contentContainer">
                                <div className="content" style={{backgroundColor: 'var(--content-background)'}}>
                                    <IonSearchbar searchIcon={searchIcon} value={this.state.searchText} placeholder={placeholder} onIonChange={e => this.setSearchText(e.detail.value!)}></IonSearchbar>
                                </div>
                            </div>

                            <div className="contentContainer flexChildFullHeight">
                                <div className="content flexChildFullHeight" style={{backgroundColor: 'transparent'}}>
                                    <Listv2
                                        list={this.items != null ?
                                                this.items.filter((hymn: any) => {

                                                    const hymnIsInCurrentList = this.state.currentList['hymnsAdded'].find((hymnInList: any) => {
                                                        return hymnInList['hymnNo'] == hymn['number']
                                                    }) == null ? true : false

                                                    return hymnIsInCurrentList
                                                }) : []
                                        }
                                        displayNumberField = {displayNumberField}
                                        displayTextField = {displayTextField}
                                        getSelectedItem = {this.getSelectedItem.bind(this)}
                                        detailIcon = {addIconBlue}
                                        detailColor= 'var(--cta-1-50)'
                                        boldFont = {false}
                                        removeTopAndBottomPaddingFromMainText = {true}
                                    />
                                </div>
                            </div>
                        </div>
                    {/* </IonContent> */}
                </div>
            </IonModal>

            <IonContent style={{'--background': 'var(--content-background)'}}>

                <div className="allContentContainer">
                    
                    {/* Title */}
                    <div className="contentContainer">
                        <div className="content content-border">

                            <div className="create-new-list-container">
                                <div className="create-new-list-header-container">
                                    <div className="create-new-list-header" >Title</div>
                                </div>
                                <div className="create-new-list-seperator" style={{width: '55px'}} />
                            
                                <div className="create-new-list-input-container">
                                    <IonInput 
                                        className="create-new-list-input" 
                                        placeholder="Give this list a title" 
                                        onIonChange={(e) => this.updateName(e.detail.value!)} 
                                        value={this.state.currentList['name']} 
                                    />
                                </div>

                            </div>

                        </div>
                    </div>

                    {/* Date */}
                    <div className="contentContainer">
                        <div className="content content-border">

                            <div className="create-new-list-container">
                                <div className="create-new-list-header-container">
                                    <div className="create-new-list-header">Date <span className="create-new-list-sub-header">(optional)</span></div>
                                </div>
                                <div className="create-new-list-seperator" style={{width: '55px'}} />
                            
                                <div className="create-new-list-input-container">
                                    <DatePicker 
                                        ref={this.calendarRef}
                                        dateFormat="d/m/Y" 
                                        datePickerType="single"
                                        minDate={format(parseISO(this.todaysDate), 'dd-MM-yyyy')}
                                        maxDate={format(new Date().setFullYear(new Date().getFullYear() + 2), 'dd-MM-yyyy')}
                                        value={this.state.currentList['date'] != '' ? format(parseISO(this.state.currentList['date']), 'dd-MM-yyyy') : format(new Date(), 'dd-MM-yyyy')}
                                        onChange={(e: any) => {
                                            this.updateDate(e[0]);
                                        }}
                                    >
                                        <div className="create-new-list-input" id="open-date-input">
                                            {this.state.currentList['date'] != null && this.state.currentList['date'] != '' ? format(new Date(this.state.currentList['date']), 'dd-MM-yyyy') : 'Tap to add date'}
                                        </div>
                                    </DatePicker>
                                    
                                </div>

                            </div>

                        </div>
                    </div>

                    {/* Add Hymns */}
                    <div className="contentContainer">
                        <div className="content content-border">

                            <div className="create-new-list-container">
                                <div className="create-new-list-header-container">
                                    <div className="create-new-list-header">Hymns</div>
                                    {this.state.reorder == false ?
                                        <div 
                                            style={{opacity: this.state.currentList['hymnsAdded'].length <= 1 ? '0.5' : '1'}} 
                                            className="smallBtnText clickable" 
                                            onClick={() => {
                                                if(this.state.currentList['hymnsAdded'].length > 1) {
                                                    this.setState({reorder: !this.state.reorder})
                                                }
                                            }}
                                        >
                                            Reorder
                                        </div>
                                    :   <div className="smallBtnText clickable" onClick={() => this.setState({reorder: !this.state.reorder})}>
                                            Done
                                        </div>
                                    }
                                </div>
                                <div className="create-new-list-seperator" style={{width: '80px'}} />

                                <IonReorderGroup disabled={!this.state.reorder} onIonItemReorder={this.doReorder.bind(this)}>
                                    
                                    {this.state.currentList['hymnsAdded']?.map((currentHymn: any, index: any) => {

                                        return  <IonReorder key={'ionitemreorder' + index}>
                                                    <IonItemSliding key={'ionitemsliding' + index} ref={this.setRef}>  
                                                        <IonItem 
                                                            key={'ionitem' + index} 
                                                            detail={this.state.reorder} 
                                                            detailIcon={menuOutline}
                                                        >
                                                            <IonNote 
                                                                slot='start'
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto'
                                                                }}
                                                            >
                                                                <div className='number-box' style={{display: 'block'}}>
                                                                    <div className="number">
                                                                        {currentHymn['hymnNo']}
                                                                    </div>
                                                                </div>
                                                            </IonNote>
                                                            <IonLabel>
                                                                {currentHymn['firstLine']}
                                                            </IonLabel>

                                                        </IonItem>
                                                        <IonItemOptions side="end">
                                                            <IonItemOption color='danger' onClick={()=> this.removeHymn(currentHymn['hymnNo'], index)}>Remove</IonItemOption>
                                                        </IonItemOptions>
                                                </IonItemSliding>
                                            </IonReorder>

                                    })}

                                </IonReorderGroup>

                                {/* <div 
                                    className="create-new-list-add-hymn-btn clickable" 
                                    onClick={() => {this.setState({showModalAddHymns: true})}}
                                >
                                    <div className="create-new-list-add-hymn-btnTxt">
                                        <IonIcon icon={addOutline}></IonIcon> Add hymn
                                    </div>
                                </div> */}

                                <IonButton 
                                    expand="block" 
                                    className='createListBtn' 
                                    color='tertiary'
                                    onClick={() => this.setState({showModalAddHymns: true})}
                                >
                                    <div slot="start" className="createListBtnTxt">
                                        <IonIcon style={{height: '15px'}} icon={addIconSepia}></IonIcon> Add hymn
                                    </div>
                                </IonButton>

                            </div>

                        </div>
                    </div>
                </div>

                <IonToast
                    color="dark"
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({showToast: false})}
                    message="Page under construction"
                    duration={2000}
                />

                <IonToast
                    color="danger   "
                    isOpen={this.state.showWarning}
                    onDidDismiss={() => this.setState({showWarning: false})}
                    message={this.warningMessage}
                    duration={2000}
                />

            </IonContent>

        </IonPage>
        )


    }


}

export default withRouter(withIonLifeCycle(createOrModifyList));