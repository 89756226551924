import { IonItem, IonLabel, IonList, IonNote, IonText, withIonLifeCycle } from "@ionic/react";
import { Component, createRef } from "react";

import React from "react";
import { render } from "react-dom";

import './List.css'

import InfiniteScroll from 'react-infinite-scroll-component';

type Props = { 
    list: [],
    displayNumberField: string | undefined | null
    displayTextField: string,
    getSelectedItem: any,
    detailIcon: any,
    subtitle: any,
    boldFont: boolean,
    tuneHymns: any // receives array of numbers
}

type State = {
    dynamicList: any,
    currentLastItem: number,
    hasMore: boolean
}

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

class List extends Component <Props, State> {

    scrollableContent: any = createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            dynamicList: this.props.list.slice(0,20),
            currentLastItem: 20,
            hasMore: true
        }

        console.log('dynamicList in constructor: ', this.state.dynamicList)

    }  

    // This method allows us to update the state whenever the props change 
    // i.e. whenever the List changes based on what the browse options clicks
    componentWillReceiveProps(nextProps: any) {

        // console.log('props received in List.tsx', nextProps);

        // If the whole list is greater than 20 items
        if(nextProps.list.length > 20){

            console.log('Setting hasMore to true...')

            this.setState({
                dynamicList: nextProps.list.slice(0,20),
                currentLastItem: 20,
                hasMore: true
            })
        
        // if we received a list with less than 20 items
        } else if(nextProps.list.length < 20 && nextProps.list.length > 0) {

            console.log('Setting hasMore to false...')
            
            this.setState( {
                dynamicList: nextProps.list,
                currentLastItem: nextProps.list.length,
                hasMore: false
            })

        } else {

        }

        // console.log('hasMore: ', this.state.hasMore)

    }

    ionViewWillEnter() {
        this.fetchMoreData();

        console.log('InfiniteScroll ref on load:', this.scrollableContent.current != null ? this.scrollableContent.current : 'Not loaded')
    }

    // Only gets called when state.hasMore is true
    fetchMoreData() {


        // If we've appended more than 847 items, set hasMore to false to prevent adding any more
        if (this.state.dynamicList.length > 847) {
            this.setState({ hasMore: false });
            return;
        }
        
        // append 25 more
        this.setState({
            currentLastItem: this.state.currentLastItem+20,
            dynamicList: this.state.dynamicList.concat(this.props.list.slice(this.state.currentLastItem, this.state.currentLastItem+20))
        });

        // console.log('hasMore: ', this.state.hasMore)

    }
    
    render(){

        return(
            <InfiniteScroll
                ref={this.scrollableContent}
                scrollableTarget='allContentContainer'
                dataLength={this.state.dynamicList.length}
                next={this.fetchMoreData.bind(this)}
                hasMore={this.state.hasMore}
                loader={''} //what's displayed when the list is loading
                endMessage={''} // what's displayed when the end of the list is reached
            >
                {this.state.dynamicList.map((item: any, index: any) => {
                    return <IonItem 
                            button
                            key={index}
                            detail={true} 
                            detailIcon={this.props.detailIcon}
                            onClick={() => {this.props.getSelectedItem(item)}}
                            >
                                <IonNote 
                                    slot='start'
                                    style={{
                                        marginTop: 'auto',
                                        marginBottom: 'auto'
                                      }}
                                >
                                    <div className='number-box' style={{display: this.props.displayNumberField != null ? 'block' : 'none'}}>
                                        <div className="number">
                                            {item[this.props.displayNumberField != null ? this.props.displayNumberField : '']}
                                        </div>
                                    </div>
                                </IonNote>
                                <IonLabel className="ion-text-wrap">
                                    <div className={this.props.boldFont ? "listItemTextBold" : "listItemText"}>
                                        {item[this.props.displayTextField]}
                                    </div>
                                    {item['subtitle'] != null ?
                                    <div className='listSubtitle'>
                                        ({item['subtitle']})
                                    </div> : ''
                                    }
                                    {item[this.props.tuneHymns] != null ?
                                        // <div className='listSubtitle'>
                                            item[this.props.tuneHymns].map((hymnNo: any) => {

                                                return <div className='number-box-small'>
                                                            <div className="number-small">
                                                                {hymnNo}
                                                            </div>
                                                        </div>

                                            })
                                        // </div>
                                    :   ''
                                    }
                                </IonLabel>
            
                            </IonItem>
                })}
            </InfiniteScroll>
        
        )

    }

}


export default withIonLifeCycle(List);