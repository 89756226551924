import { IonButton, IonButtons, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import { Component } from "react";
import { withRouter } from "react-router";

type Props = {
    setTabs: any
}

type State = {

}

export class AboutTheApp<Props, State> extends Component {

    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    render() {

        return (
            <IonPage>

                <IonHeader>
                <IonToolbar>
                    <IonButtons onClick={() => {}} style={{paddingLeft: '12px'}} slot="end">
                    <IonButton>
                        Close
                    </IonButton>
                    </IonButtons>
                    <IonTitle>{`About the app`}</IonTitle>
                </IonToolbar>
                </IonHeader>

            </IonPage>

        )

    }


}

export default withRouter(withIonLifeCycle(AboutTheApp));